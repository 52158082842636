
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { FilteredClosedEDBMeetings,  ClosedEDBMeetings, OpenEDBMeetings, DatedEDBMeetings ,FilteredOpenEDBMeetings, FilteredDatedEDBMeetings } from "../../Framework/redux/EDBsSideEffects";
import { saveFilters, keepRawFilters, resetFilters } from "../../Framework/redux/EDBsActions";

import { ReactComponent as SVGClose } from '../../../common/svgs/ic-x-lg.svg'
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";

export default function EDBModalFilter({ edbStateRecieved, order, setOrder, edbType, setEdbType, prizeLevel, setPrizeLevel, toggleOrganizer, setTogglerOrganizer, date, setDate, setDataWasFiltered, dataWasFiltered, setClosedFilterWasUpdated, setClosedFilterOffsetState }) {
   const dispatch = useDispatch()


  const onToggle = () => {
    setTogglerOrganizer(!toggleOrganizer);
  };

  function defineSortBy(orderObject){
    if(orderObject.highLow && orderObject.type.sale){
        return "sales.desc"
    } else if(!orderObject.highLow && orderObject.type.sale){
        return "sales.asc"
    } else if(orderObject.highLow && orderObject.type.guest){
        return "assistants.desc"
    } else if(!orderObject.highLow && orderObject.type.guest){
        return "assistants.asc"
    } else if(orderObject.highLow && orderObject.type.level){
        return "hostLevel.desc"
    } else if(!orderObject.highLow && orderObject.type.level){
        return "hostLevel.asc"
    }
  }

  function defineEDBType(edbTypeObject){
    if(edbTypeObject.virtual){
        return "virtual"
    } else if(edbTypeObject.onSite){
        return "presencial"
    } else {
        return ""
    }
    
  }

  function definePrizeLevel(awardObject){
    if(awardObject.noAward){
        return "noPrize"
    } else if(awardObject.oneAward){
        return "firstPrize"
    } else if(awardObject.twoAward){
        return "secondPrize"
    } else {
        return ""
    }
    
  }

  function defineDate(dateObject){ 
    const dateOriginArray = dateObject.origin.split("-")
    const dateOriginFixedString = dateOriginArray.join("")
    const dateTargetArray = dateObject.target.split("-")
    const dateTargetFixedString = dateTargetArray.join("")
    return {dateStart: dateOriginFixedString, dateEnd: dateTargetFixedString }
  }

  function FilterRequestByState(edbState) {
        setClosedFilterWasUpdated(Math.random())
        setDataWasFiltered(true)
        setClosedFilterOffsetState(10)

        dispatch(keepRawFilters({sortBy: order, edbType: edbType, hostAward: prizeLevel, date: date}))
        dispatch(saveFilters({wasFiltered: true, sortBy: defineSortBy(order), edbType: defineEDBType(edbType), hostAward: definePrizeLevel(prizeLevel), date: defineDate(date)}))

        dispatch(FilteredClosedEDBMeetings({offset: 0, limit: 10, sortBy: defineSortBy(order), edbType: defineEDBType(edbType), hostAward: definePrizeLevel(prizeLevel), date: defineDate(date)}))
        dispatch(FilteredOpenEDBMeetings({offset: 0, limit: 10, sortBy: defineSortBy(order), edbType: defineEDBType(edbType), hostAward: definePrizeLevel(prizeLevel), date: defineDate(date)}))
        dispatch(FilteredDatedEDBMeetings({offset: 0, limit: 10, sortBy: defineSortBy(order), edbType: defineEDBType(edbType), hostAward: definePrizeLevel(prizeLevel), date: defineDate(date)}))    
  }

  function clearFilters(){
    setDataWasFiltered(false)
    setClosedFilterOffsetState(10)
    dispatch(resetFilters())
    dispatch(ClosedEDBMeetings(10))
    dispatch(OpenEDBMeetings(10))
    dispatch(DatedEDBMeetings(10))

    setOrder({
        highLow: true,
        type: { sale: true, guest: false, level: false },
    })
    setEdbType({ virtual: false, onSite: false })
    setPrizeLevel({
        noAward: false,
        oneAward: false,
        twoAward: false,
    })
    setDate({ origin: "", target: "" })

  }

  const onChange = (evt) => {
    evt.preventDefault();

    let value = evt.target.name;

    switch (value) {
      case "onSite":
        if (edbType.onSite === true) {
          setEdbType({ virtual: false, onSite: false });
        } else {
          setEdbType({ virtual: false, onSite: true });
        }
        return;
      case "virtual":
        if (edbType.virtual === true) {
          setEdbType({ virtual: false, onSite: false });
        } else {
          setEdbType({ virtual: true, onSite: false });
        }
        return;
      case "noAward":
        if (prizeLevel.noAward === true) {
          setPrizeLevel({ noAward: false, oneAward: false, twoAward: false });
        } else {
          setPrizeLevel({ noAward: true, oneAward: false, twoAward: false });
        }
        return;
      case "oneAward":
        if (prizeLevel.oneAward === true) {
          setPrizeLevel({ noAward: false, oneAward: false, twoAward: false });
        } else {
          setPrizeLevel({ noAward: false, oneAward: true, twoAward: false });
        }
        return;
      case "twoAward":
        if (prizeLevel.twoAward === true) {
          setPrizeLevel({ noAward: false, oneAward: false, twoAward: false });
        } else {
          setPrizeLevel({ noAward: false, oneAward: false, twoAward: true });
        }
        return;
    }
  };

  const changeOrigin = (evt) => {
    setDate({...date , origin: evt.target.value})
  }

  const changeTarget = (evt) => {
    setDate({...date , target: evt.target.value})
  }

  return (
    <div
      className="modal fade"
      id="modalEDBFilter"
      aria-hidden="true"
      aria-labelledby="modalEDBFilter"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "unset", width: "608px" }}
      >
        <div className="modal-content modal-radius" style={{height: "687px"}}>
          <div className="modal-body p-0" style={{width: "608px"}}>
            <div style={{height: "687px"}}>
              <div className="d-flex justify-content-between p-5 align-items-center" style={{height: "112px"}}>
                <div className="d-flex">
                  <h5 className="museo-sans-500 s20 m-0">
                    <FormattedMessage
                      id="edb.filter.header.title"
                      defaultMessage="Entendido"
                    />
                  </h5>
                </div>
                <div className="pointer " data-bs-dismiss="modal"aria-label="Close">
                    <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div className="d-flex flex-column align-items-center">
                <div
                  style={{ width: "528px", maxHeight: "639px" }}
                  className="mt-4"
                >
                  <div className=" py-1 s14 museo-sans-700 position-relative d-flex">
                    <p className="">
                      <FormattedMessage
                        id="edb.filter.dropdown.p"
                        defaultMessage="Ordenar por:"
                      />
                    </p>
                    {toggleOrganizer && (
                      <EDBModalDropMenu
                        setTogglerOrganizer={setTogglerOrganizer}
                        setOrder={setOrder}
                        order={order}
                      />
                    )}

                    <p onClick={onToggle} className=" pointer skyColor px-2">
                        <EDBModalDropMenuTitle order={order} />
                    </p>
                    <HelpIconNoTarget />
                  </div>
                  <hr />
                  <div className="s14">
                    <p className=" py-2 museo-sans-700">
                      <FormattedMessage
                        id="edb.filter.label.title.one"
                        defaultMessage="Entendido"
                      />
                    </p>
                    <div className=" d-flex gap-3">
                      {edbType.onSite ? (
                        <button
                          onClick={onChange}
                          name="onSite"
                          style={{ borderRadius: "1rem" }}
                          className=" skyBorder whiteColor skyBg  s14 museo-sans-700  edb-filter-button  btnCustom py-1 "
                        >
                          <FormattedMessage
                            id="edb.filter.label.button.one"
                            defaultMessage="Entendido"
                          />
                        </button>
                      ) : (
                        <button
                          onClick={onChange}
                          name="onSite"
                          style={{ borderRadius: "1rem" }}
                          className="buttonBehavior skyBorder skyColor s14 museo-sans-700  edb-filter-button bg-white btnCustom py-1 "
                        >
                          <FormattedMessage
                            id="edb.filter.label.button.one"
                            defaultMessage="Entendido"
                          />
                        </button>
                      )}

                      {edbType.virtual ? (
                        <button
                          onClick={onChange}
                          name="virtual"
                          style={{ borderRadius: "1rem" }}
                          className=" skyBorder whiteColor skyBg s14 museo-sans-700  edb-filter-button btnCustom py-1 "
                        >
                          {" "}
                          <FormattedMessage
                            id="edb.filter.label.button.two"
                            defaultMessage="Entendido"
                          />{" "}
                        </button>
                      ) : (
                        <button
                          onClick={onChange}
                          name="virtual"
                          style={{ borderRadius: "1rem" }}
                          className="buttonBehavior skyBorder skyColor s14 museo-sans-700  edb-filter-button bg-white btnCustom py-1 "
                        >
                          {" "}
                          <FormattedMessage
                            id="edb.filter.label.button.two"
                            defaultMessage="Entendido"
                          />{" "}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className=" py-3 s14">
                    <p className=" py-2 museo-sans-700">
                      <FormattedMessage
                        id="edb.filter.label.title.two"
                        defaultMessage="Entendido"
                      />
                    </p>
                    <div className=" py-1 d-flex gap-3">
                      {prizeLevel.noAward ? (
                        <button
                          onClick={onChange}
                          name="noAward"
                          style={{ borderRadius: "1rem" }}
                          className=" skyBorder whiteColor skyBg s14 museo-sans-700  edb-filter-button btnCustom py-1 "
                        >
                          <FormattedMessage
                            id="edb.filter.label.button.three"
                            defaultMessage="Entendido"
                          />
                        </button>
                      ) : (
                        <button
                          onClick={onChange}
                          name="noAward"
                          style={{ borderRadius: "1rem" }}
                          className="buttonBehavior skyBorder skyColor s14 museo-sans-700  edb-filter-button bg-white btnCustom py-1 "
                        >
                          <FormattedMessage
                            id="edb.filter.label.button.three"
                            defaultMessage="Entendido"
                          />
                        </button>
                      )}
                      {prizeLevel.oneAward ? (
                        <button
                          onClick={onChange}
                          name="oneAward"
                          style={{ borderRadius: "1rem" }}
                          className=" skyBorder whiteColor skyBg s14 museo-sans-700  edb-filter-button btnCustom py-1 "
                        >
                          <FormattedMessage
                            id="edb.filter.label.button.four"
                            defaultMessage="Entendido"
                          />
                        </button>
                      ) : (
                        <button
                          onClick={onChange}
                          name="oneAward"
                          style={{ borderRadius: "1rem" }}
                          className="buttonBehavior skyBorder skyColor s14 museo-sans-700  edb-filter-button bg-white btnCustom py-1 "
                        >
                          <FormattedMessage
                            id="edb.filter.label.button.four"
                            defaultMessage="Entendido"
                          />
                        </button>
                      )}
                    </div>
                    <div className=" py-1 d-flex gap-3">
                      {prizeLevel.twoAward ? (
                        <button
                          onClick={onChange}
                          name="twoAward"
                          style={{ borderRadius: "1rem" }}
                          className=" skyBorder whiteColor skyBg s14 museo-sans-700  edb-filter-button btnCustom py-1 "
                        >
                          <FormattedMessage
                            id="edb.filter.label.button.five"
                            defaultMessage="Entendido"
                          />
                        </button>
                      ) : (
                        <button
                          onClick={onChange}
                          name="twoAward"
                          style={{ borderRadius: "1rem" }}
                          className="buttonBehavior skyBorder skyColor s14 museo-sans-700  edb-filter-button bg-white btnCustom py-1 "
                        >
                          <FormattedMessage
                            id="edb.filter.label.button.five"
                            defaultMessage="Entendido"
                          />
                        </button>
                      )}
                      <button
                        style={{ borderRadius: "1rem" }}
                        className=" invisible skyBorder skyColor s14 museo-sans-700  edb-filter-button bg-white btnCustom py-1 "
                      >
                        {" "}
                        Virtual{" "}
                      </button>
                    </div>
                  </div>
                  <div className="s14">
                    <p className=" grayColor py-2 museo-sans-500 s12">
                      <FormattedMessage
                        id="edb.filter.label.title.three"
                        defaultMessage="Entendido"
                      />
                    </p>
                    <div className=" d-flex gap-3">
                      <div
                        style={{ width: "256px" }}
                        className=" position-relative px-0"
                      >
                        <p className=" museo-sans-700 s12">
                          <FormattedMessage
                            id="edb.filter.label.subtitle.three.one"
                            defaultMessage="Entendido"
                          />
                        </p>
                        <div className="no-calendar" style={{ width: "256px" }}>
                          <input
                            value={date.origin}
                            onChange={changeOrigin}
                            data-date-format="DD-MMMM-YYYY"
                            style={{ width: "256px" }}
                            className="  pointer mt-1 dm-sans-medium s14 skyBorder p-2"
                            type="date"
                          />
                        </div>
                      </div>
                      <div
                        style={{ width: "256px" }}
                        className=" position-relative px-0"
                      >
                        <p className=" museo-sans-700 s12">
                          <FormattedMessage
                            id="edb.filter.label.subtitle.three.two"
                            defaultMessage="Entendido"
                          />
                        </p>
                        <div
                          className=" no-calendar"
                          style={{ width: "256px" }}
                        >
                          <input
                            value={date.target}
                            onChange={changeTarget}
                            data-date-format="DDMMYYYY"
                            style={{ width: "256px" }}
                            className=" pointer mt-1 dm-sans-medium s14 skyBorder p-2"
                            type="date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column" style={{gap: "16px", marginTop: "32px"}}>
                    <button
                        onClick={() => FilterRequestByState(edbStateRecieved)}
                        type="button"
                        className=" btnLoginWidth museo-sans-700 s14 whiteColor skyBg skyBorder button-hover-light-text button-bootstrap-radius button-padding"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        <FormattedMessage
                            id="buttons.apply"
                            defaultMessage="Aplicar"
                        />
                    </button>
                    {
                        dataWasFiltered ?
                            <button
                                type="button"
                                className=" btnLoginWidth mb-4 museo-sans-700 s14 skyColor bronceBg skyBorder button-hover-light-text button-bootstrap-radius button-padding"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => clearFilters()}
                            >
                            <FormattedMessage
                                id="buttons.remove.filters"
                                defaultMessage="Limpiar filtros"
                            />
                            </button>
                        :
                            <button
                                type="button"
                                className=" btnLoginWidth mb-4 museo-sans-700 s14 brightGrayColor bronceBg brightGrayBorder  button-bootstrap-radius button-padding pointer-events-none"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                            <FormattedMessage
                                id="buttons.remove.filters"
                                defaultMessage="Limpiar filtros"
                            />
                            </button>
                    }
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function EDBModalDropMenuTitle({order}) {

    return (<>
        {order.highLow && order.type.sale ? 
        <>
        <FormattedMessage
          id="edb.filter.higher.to.lower"
          defaultMessage="Ordenar por:"
        />
        <FormattedMessage
          id="edb.filter.sales"
          defaultMessage="Ordenar por:"
        />
        </> 
        : null}
        {order.highLow && order.type.guest ? 
        <>
        <FormattedMessage
          id="edb.filter.higher.to.lower"
          defaultMessage="Ordenar por:"
        />
        <FormattedMessage
          id="edb.filter.guest"
          defaultMessage="Ordenar por:"
        />
        </> 
        : null}
        {order.highLow && order.type.level ? 
        <>
        <FormattedMessage
          id="edb.filter.higher.to.lower"
          defaultMessage="Ordenar por:"
        />
        <FormattedMessage
          id="edb.filter.host.level"
          defaultMessage="Ordenar por:"
        />
        </> 
        : null}
        {!order.highLow && order.type.sale ? 
        <>
        <FormattedMessage
          id="edb.filter.lower.to.higher"
          defaultMessage="Ordenar por:"
        />
        <FormattedMessage
          id="edb.filter.sales"
          defaultMessage="Ordenar por:"
        />
        </> 
        : null}
        {!order.highLow && order.type.guest ? 
        <>
        <FormattedMessage
          id="edb.filter.lower.to.higher"
          defaultMessage="Ordenar por:"
        />
        <FormattedMessage
          id="edb.filter.guest"
          defaultMessage="asistentes"
        />
        </> 
        : null}
        {!order.highLow && order.type.level ? 
        <>
        <FormattedMessage
          id="edb.filter.lower.to.higher"
          defaultMessage="Ordenar por:"
        />
        <FormattedMessage
          id="edb.filter.host.level"
          defaultMessage="nivel de anfitrion"
        />
        </> 
        : null}
    </>)
    
}

export function EDBModalDropMenu({ setTogglerOrganizer, setOrder, order }) {

  const onOrderSelect = (evt) => {
    evt.preventDefault();
    let value = evt.target.id;

    switch (value) {
      case "HLSale":
        setOrder({
          highLow: true,
          type: { sale: true, guest: false, level: false },
        });
        return;

      case "HLGuest":
        setOrder({
          highLow: true,
          type: { sale: false, guest: true, level: false },
        });
        return;

      case "HLHost":
        setOrder({
          highLow: true,
          type: { sale: false, guest: false, level: true },
        });
        return;

      case "LHSale":
        setOrder({
          highLow: false,
          type: { sale: true, guest: false, level: false },
        });
        return;

      case "LHGuest":
        setOrder({
          highLow: false,
          type: { sale: false, guest: true, level: false },
        });
        return;

      case "LHHost":
        setOrder({
          highLow: false,
          type: { sale: false, guest: false, level: true },
        });
        return;
    }

    setTogglerOrganizer(false);
  };

  return (
    <div
      style={{
        zIndex: "10",
        width: "240px",
        top: "30px",
        left: "90px",
        borderRadius: "1rem",
      }}
      className=" shadow position-absolute bg-white "
    >
      <div>
        <p className=" p-3 grayColor text-uppercase s12 museo-sans-500">
          <FormattedMessage
            id="edb.filter.sales"
            defaultMessage="Ordenar por:"
          />
        </p>

        {order.highLow && order.type.sale ? 
        <p
        id="HLSale"
        onClick={onOrderSelect}
        className="pointer p-3 museo-sans-700 s14"       >
        <FormattedMessage
          id="edb.filter.higher.to.lower"
          defaultMessage="Ordenar por:"
        />
        <FormattedMessage
          id="edb.filter.sales"
          defaultMessage="Ordenar por:"
        />
      </p> 
        : 
        <p
          id="HLSale"
          onClick={onOrderSelect}
          className="pointer p-3 museo-sans-500 s14"       >
          <FormattedMessage
            id="edb.filter.higher.to.lower"
            defaultMessage="Ordenar por:"
          />
          <FormattedMessage
            id="edb.filter.sales"
            defaultMessage="Ordenar por:"
          />
        </p>
        }
        {!order.highLow && order.type.sale ? 
        <p
        id="LHSale"
        onClick={onOrderSelect}
        className="pointer p-3 museo-sans-700 s14"        >
        <FormattedMessage
          id="edb.filter.lower.to.higher"
          defaultMessage="Ordenar por:"
        />
        <FormattedMessage
          id="edb.filter.sales"
          defaultMessage="Ordenar por:"
        />
      </p> 
        : 
        <p
          id="LHSale"
          onClick={onOrderSelect}
          className="pointer p-3 museo-sans-500 s14"        >
          <FormattedMessage
            id="edb.filter.lower.to.higher"
            defaultMessage="Ordenar por:"
          />
          <FormattedMessage
            id="edb.filter.sales"
            defaultMessage="Ordenar por:"
          />
        </p>
        }

      </div>
      <hr className="m-0"></hr>
      <div>
        <p className=" p-3 grayColor text-uppercase s12 museo-sans-500">
          <FormattedMessage
            id="edb.filter.guest"
            defaultMessage="Ordenar por:"
          />
        </p>

        {order.highLow && order.type.guest ? 
        <p
        id="HLGuest"
        onClick={onOrderSelect}
        className="pointer p-3 museo-sans-700 s14"        >
        <FormattedMessage
          id="edb.filter.higher.to.lower"
          defaultMessage="Ordenar por:"
        />
        <FormattedMessage
          id="edb.filter.guest"
          defaultMessage="Ordenar por:"
        />
      </p> 
        : 
        <p
          id="HLGuest"
          onClick={onOrderSelect}
          className="pointer p-3 museo-sans-500 s14"        >
          <FormattedMessage
            id="edb.filter.higher.to.lower"
            defaultMessage="Ordenar por:"
          />
          <FormattedMessage
            id="edb.filter.guest"
            defaultMessage="Ordenar por:"
          />
        </p> 
        }
        {!order.highLow && order.type.guest ? 
        <p
        id="LHGuest"
        onClick={onOrderSelect}
        className="pointer p-3 museo-sans-700 s14"
      >
        <FormattedMessage
          id="edb.filter.lower.to.higher"
          defaultMessage="Ordenar por:"
        />
        <FormattedMessage
          id="edb.filter.guest"
          defaultMessage="Ordenar por:"
        />
      </p> 
        : 
        <p
          id="LHGuest"
          onClick={onOrderSelect}
          className="pointer p-3 museo-sans-500 s14"
        >
          <FormattedMessage
            id="edb.filter.lower.to.higher"
            defaultMessage="Ordenar por:"
          />
          <FormattedMessage
            id="edb.filter.guest"
            defaultMessage="Ordenar por:"
          />
        </p> 
        }
        

      </div>
      <hr className="m-0"></hr>
      <div>
        <p className=" p-3 grayColor text-uppercase s12 museo-sans-500">
          <FormattedMessage
            id="edb.filter.host.level"
            defaultMessage="Ordenar por:"
          />
        </p>

        {order.highLow && order.type.level ? 
        <p
        id="HLHost"
        onClick={onOrderSelect}
        className=" pointer p-3 museo-sans-700 s14"        >
        <FormattedMessage
          id="edb.filter.higher.to.lower"
          defaultMessage="Ordenar por:"
        />
        <FormattedMessage
          id="edb.filter.host.level"
          defaultMessage="Ordenar por:"
        />
      </p> 
        : 
        <p
          id="HLHost"
          onClick={onOrderSelect}
          className=" pointer  p-3 museo-sans-500 s14"        >
          <FormattedMessage
            id="edb.filter.higher.to.lower"
            defaultMessage="Ordenar por:"
          />
          <FormattedMessage
            id="edb.filter.host.level"
            defaultMessage="Ordenar por:"
          />
        </p>
         }
        {!order.highLow && order.type.level ? <p
          id="LHHost"
          onClick={onOrderSelect}
          className=" pointer p-3 museo-sans-700 s14"
        >
          <FormattedMessage
            id="edb.filter.lower.to.higher"
            defaultMessage="Ordenar por:"
          />
          <FormattedMessage
            id="edb.filter.host.level"
            defaultMessage="Ordenar por:"
          />
        </p> : <p
          id="LHHost"
          onClick={onOrderSelect}
          className=" pointer p-3 museo-sans-500 s14"
        >
          <FormattedMessage
            id="edb.filter.lower.to.higher"
            defaultMessage="Ordenar por:"
          />
          <FormattedMessage
            id="edb.filter.host.level"
            defaultMessage="Ordenar por:"
          />
        </p>  }
      </div>
    </div>
  );
}
