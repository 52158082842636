import { useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import Vimeo from "@u-wave/react-vimeo";
import { ReactComponent as SVGx } from "../../../../common/svgs/ic-x-overlay.svg";
import { defineStepNumber } from "../../../Framework/redux/dashboardAction";

export default function DashboardFirstStepsVideo({activateFirstLogin}) {

  const profileData = useSelector((state) => state.userInfo);

  const sNumber = useSelector((state) => state.scoreData.stepNumber);
  const dispatch = useDispatch();

  const [toggler, setToggler] = useState(false);
  const [togglerFirstTime, setTogglerFirstTime] = useState(false);
  const [canSkip, setCanSkip] = useState(false);
  const biggerThan1921 = useMediaPredicate("(min-width: 1800px)");
  const country = localStorage.getItem("country");

  const onClose = () => {
    setToggler(false);
    let object = JSON.parse(localStorage.getItem("videoFour"));
    object.seen = object.seen + 1;
    if (object.seen == 3) {
      object.status = "false";
    }
    object = JSON.stringify(object);
    localStorage.setItem("videoFour", object);
    dispatch(defineStepNumber(100));
  };

  const onCloseAndSeal = () => {
    setToggler(false);
    let object = JSON.parse(localStorage.getItem("videoFour"));
    object.status = "false";
    object = JSON.stringify(object);
    localStorage.setItem("videoFour", object);
    dispatch(defineStepNumber(100));
  };

  const onCloseFirstVideo = () => {
    setTogglerFirstTime(false);
    setTimeout(() => {
      activateFirstLogin();
      dispatch(defineStepNumber(0));
      
    }, 1000);
  };

  const vimeoUrlFirstSteps = `https://player.vimeo.com/video/${
        country == "ar" || country == "uy" ? "1017123261" : "1017123344"
      }&controls=false&autoplay=true&playsinline=false&allowfullscreen=true&portrait=false`
  const vimeoUrlVar = `https://player.vimeo.com/video/${"932096952"}?h=${"d7fcd1ec1f"}&controls=false&autoplay=true&playsinline=false&allowfullscreen=true&portrait=false`
  const vimeoUrlStart = `https://player.vimeo.com/video/${"1013682291"}&controls=false&autoplay=true&playsinline=false&allowfullscreen=true&portrait=false`
    

  useEffect(() => {
    let object = JSON.parse(localStorage.getItem("videoFour"));
    setTimeout(() => {
      if (sNumber == -1) {
        setTogglerFirstTime(true);
      }
      if (sNumber == 30) {
        if (object.status == "true") {
          setToggler(true);
        }
        setTimeout(() => {
          setCanSkip(true);
        }, 3000);
      }
    }, 1000);
  }, [sNumber]);

  return (
    <>
    {
      togglerFirstTime && (
        <VimeoContainer biggerThan1921={biggerThan1921} onClose={onCloseFirstVideo} canSkip={true} vimeoUrlVar={vimeoUrlFirstSteps} />
      )
    }
      {(toggler && country == "uy" ) && (
        <VimeoContainer biggerThan1921={biggerThan1921} onClose={onClose} onCloseAndSeal={onCloseAndSeal} canSkip={canSkip} vimeoUrlVar={vimeoUrlVar} />
      )}
      {(toggler && (profileData?.userInfo?.level >= 2 && country != "uy") ) && (
        <VimeoContainer biggerThan1921={biggerThan1921} onClose={onClose} onCloseAndSeal={onCloseAndSeal} canSkip={canSkip} vimeoUrlVar={vimeoUrlStart} />
      )}
    </>
  );
}

export function VimeoContainer({ onClose, onCloseAndSeal, canSkip, vimeoUrlVar, biggerThan1921 }) {
  return(
    <div
          style={{ width: "100%", height: "100%", top: "0", left: "0" }}
          className=""
          id="vimeo--displayer"
        >
          <div
            style={{ zIndex: "998" }}
            className="bg-black d-block position-absolute opacity-75 w-100 h-100"
            onClick={onClose}
          ></div>
          {canSkip && (
            <>
              <VimeoRedCloseZone onClose={onClose} />
              <div
                onClick={onClose}
                style={
                  biggerThan1921
                    ? {
                        zIndex: "10000",
                        top: "42px",
                        right: "42px",
                        width: "42px",
                        height: "42px",
                      }
                    : {
                        zIndex: "10000",
                        top: "10px",
                        right: "10px",
                        width: "32px",
                        height: "32px",
                      }
                }
                className="position-absolute whiteColor pointer svg-force-size "
              >
                <SVGx />
              </div>
              <div
                onClick={onClose}
                style={
                  biggerThan1921
                    ? {
                        zIndex: "10000",
                        bottom: "42px",
                        right: "42px",
                      }
                    : {
                        zIndex: "10000",
                        bottom: "10px",
                        right: "10px",
                      }
                }
                className="position-absolute whiteColor pointer svg-force-size "
              ></div>
            </>
          )}

          <VimeoHolder
          biggerThan1921={biggerThan1921}
            onCloseAndSeal={onCloseAndSeal}
            canSkip={canSkip}
            vimeoUrlVar={vimeoUrlVar}
          />
        </div>
  )
}

export function VimeoHolder({ onCloseAndSeal, canSkip, vimeoUrlVar, biggerThan1921 }) {

  return (
    <div
      id="vimeo--holder"
      className=" shadow position-relative vh-100 d-flex justify-content-center mt-2"
      style={{ zIndex: "999", alignItems: "center" }}
    >
      <div
        style={
          biggerThan1921
            ? { width: "538px", height: "896px", top: -38 }
            : { width: "338px", height: "546px", top: -30 }
        }
        className=" position-relative d-flex justify-content-center"
      >
        <div
          style={
            biggerThan1921
              ? {
                  width: "428px",
                  height: "861px",
                  left: "15px",
                  justifyItems: "center",
                }
              : { width: "338px", height: "566px" }
          }
          className=" position-relative d-flex flex-column gap-2 justify-items-center "
        >
          {(canSkip && onCloseAndSeal) && (
            <div className="h-100 w-100 d-flex justify-content-center position-absolute align-items-end ">
              <p
                style={{
                  zIndex: "110000",
                  width: biggerThan1921 ? "100%" : "318px",
                  height: "41px",
                  bottom: biggerThan1921 ? -52 : -52,
                }}
                onClick={onCloseAndSeal}
                className=" rounded-2 position-relative text-white skyBg d-flex justify-content-center align-items-center museo-sans-700 pointer"
              >
                <FormattedMessage id="buttons.not.show.ever" />
              </p>
            </div>
          )}
          <div
            style={
              biggerThan1921
                ? { zIndex: "900", top: "15px" }
                : { zIndex: "900" }
            }
            className=" d-flex justify-content-center position-relative"
          >
            <Vimeo
              speed={true}
              showTitle={true}
              showPortrait={false}
              controls={true}
              autoplay={false}
              showByline={true}
              video={vimeoUrlVar}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function VimeoRedCloseZone({ onClose }) {
  return (
    <>
      <div
        onClick={onClose}
        style={{ zIndex: "1004", width: "26%" }}
        className=" position-absolute  h-100 "
      ></div>
      <div
        onClick={onClose}
        style={{ zIndex: "1004", width: "26%", right: "0px" }}
        className=" position-absolute  h-100 "
      ></div>
    </>
  );
}
