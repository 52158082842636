import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useMediaPredicate } from "react-media-hook";
import "./dashboardGraph.css";
import GraphData from "./GraphData";
import GraphListPeriods from "./GraphListPeriods";
import { ReactComponent as SVGChevArrowDown } from "../../../../common/svgs/ic-chevron-down-md.svg";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import ReformatAmountsForGraph from "../../../../common/Share/ReformatAmountsForGraph";

export default function DashboardGraphPrevPeriods({
  gap,
  loading,
  historyEarning,
}) {

  let wholeData = {};

  const profileData = useSelector((state) => state.userInfo);
  const earningData = useSelector((state) => state.earning.historyEarning);

  const biggerThan1000 = useMediaPredicate("(min-width: 1025px)");

  const [data, setData] = useState();
  const [plugins, setPlugins] = useState();

  const loadingData = {
    labels: gap
      ? [" "]
      : ["Venta personal", "Grupo personal", "Descendencia", "Bono"],
    datasets: [
      {
        label: gap ? "" : "# of Votes",
        data: [1200, 600, 400, 100],
        backgroundColor: ["rgba(221, 221, 221, 0.8)"],
        borderColor: [
          // We don't use them at all.
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        spacing: 15,
        outerWidth: 20,
        borderWidth: 0,
        borderRadius: 40,
        cutout: "80%",
      },
    ],
  };

  const loadingPlugins = [
    {
      beforeDraw: function (chart) {
        let width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        let fontSize = (height / 150).toFixed(2);
        ctx.font = gap
          ? fontSize + "em MuseoSans700"
          : fontSize + "em DMSansBold";
        ctx.textBaseline = "center";

        let text = "", // Edit this.
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.restore();
      },
    },
  ];

  const valuesArray = (string) => {
    const nonArray = [
      ReformatAmountsForGraph({
        amount: earningData?.byPersonalSales?.amount,
        profileData: profileData,
      }),
      ReformatAmountsForGraph({
        amount: earningData?.byPersonalGroup?.amount,
        profileData: profileData,
      }),
      ReformatAmountsForGraph({
        amount: earningData?.byDescendant?.amount,
        profileData: profileData,
      }),
      ReformatAmountsForGraph({
        amount: earningData?.bondExecutiveDirector?.amount,
        profileData: profileData,
      }),
      ReformatAmountsForGraph({
        amount: earningData?.justCoins,
        profileData: profileData,
      }),
    ];

    wholeData.array = nonArray;
    wholeData.colors = ["#8889DB", "#D18DEF", "#456ECE", "#CED2FF", "#F2D149"];
    wholeData.labels = [
      "Venta personal",
      "Grupo personal",
      "Descendencia",
      "Bono",
      "Just Coins",
    ];

    if (string == "array") {
      return wholeData.array;
    }
    if (string == "colors") {
      return wholeData.colors;
    }
    if (string == "labels") {
      return wholeData.labels;
    }

    return wholeData;
  };

  const onValueLowNewColor = () => {
    if (earningData?.potentialEarnings == 0) {
      return ["#DDD"]
    }
    return valuesArray("colors");
  };

  const defineStyles = () => {
    if ( (gap || earningData?.potentialEarnings <= 0) ) {
      return true;
    }
    const arrayValues = valuesArray("array");
    const positiveValues = arrayValues.filter((value) => value > 0);

    if (positiveValues.length === 1) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    setData({
      labels: valuesArray("labels"),
      datasets: [
        {
          label: "#",
          data: earningData?.potentialEarnings <= 0 ? [1] : valuesArray("array"),
          backgroundColor: onValueLowNewColor(),
          borderColor: [
            // We don't use them at all.
          ],
          spacing: defineStyles() ? 0 : 15,
          outerWidth: 20,
          borderWidth: 0,
          borderRadius: defineStyles() ? 0 : 40,
          cutout: "80%",
        },
      ],
    });

    setPlugins([
      {
        beforeDraw: function (chart) {
          let width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
          ctx.restore();
        },
      },
    ]);
  }, [earningData]);

  return (
    <>
      {loading ||
      loading == null ||
      (!historyEarning && !profileData && !earningData) ? (
          <div style={{ minHeight: "443px" }} className=" py-3 d-flex ">
              <div
                style={
                  biggerThan1000 ? { maxWidth: "364px" } : { maxWidth: "291px" }
                }
                id=""
                className=" d-flex flex-column align-items-center ps-4  container--dognut py-3 museo-sans-500"
              >
                <div
                  id="graph-media"
                  style={
                    biggerThan1000 ? { width: "304px" } : { width: "257px" }
                  }
                  className="position-relative"
                >
                  {biggerThan1000 ? (
                    <>
                      <div
                        style={{
                          width: "103px",
                          height: "24px",
                          top: "40%",
                          left: "100px",
                        }}
                        className=" brightGrayBg position-absolute rounded-3 "
                      ></div>
                      <div
                        style={
                          biggerThan1000
                            ? {
                                width: "141px",
                                height: "8px",
                                top: "55%",
                                left: "80px",
                                zIndex: 10,
                              }
                            : {
                                width: "130px",
                                height: "32px",
                                top: "41%",
                                left: "80px",
                              }
                        }
                        className=" brightGrayBg position-absolute rounded-3 "
                      ></div>
                      <div
                        style={
                          biggerThan1000
                            ? {
                                width: "141px",
                                height: "8px",
                                top: "60%",
                                left: "80px",
                                zIndex: 10,
                              }
                            : {
                                width: "130px",
                                height: "32px",
                                top: "41%",
                                left: "80px",
                              }
                        }
                        className=" brightGrayBg position-absolute rounded-3 "
                      ></div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          width: "103px",
                          height: "24px",
                          top: "40%",
                          left: "80px",
                        }}
                        className=" brightGrayBg position-absolute rounded-3 "
                      ></div>
                      <div
                        style={{
                          width: "112px",
                          height: "8px",
                          top: "55%",
                          left: "75px",
                        }}
                        className=" brightGrayBg position-absolute rounded-3 "
                      ></div>
                      <div
                        style={{
                          width: "112px",
                          height: "8px",
                          top: "60%",
                          left: "75px",
                        }}
                        className=" brightGrayBg position-absolute rounded-3 "
                      ></div>
                    </>
                  )}

                  <GraphData
                    biggerThan1000={biggerThan1000}
                    data={loadingData}
                    plugins={loadingPlugins}
                  />
                </div>
              </div>
            <div className=" ms-5 py-3 w-100">
              <SkeletonDashboardRowData />
            </div>
          </div>
      ) : (
        <div
          style={{ minHeight: "443px" }}
          className=" position-relative gap-4 d-flex "
        >
          {historyEarning && profileData && earningData && (
            <>
              <div
                style={
                  biggerThan1000
                    ? { paddingLeft: "45px" }
                    : { paddingLeft: "0px" }
                }
                id="dashboard--dognut-prev"
                className=" d-flex justify-content-end graph-limit-canvas-width container--dognut pe-3 pb-3 museo-sans-500"
              >
                <div
                  id="dashboard--dognut-prev-text"
                  className="position-absolute museo-sans-500 text-center s12 grayColor  "
                >
                  <p>
                    <FormattedMessage id="graph.prev.text" />{" "}
                    {
                      earningData?.roleDescription
                    }
                  </p>
                </div>
                {earningData?.potentialEarnings === 0 ? (
                  <div
                    style={{ width: "250px", top: "115px", right: "45px" }}
                    className=" text-center dm-sans-bold s34 position-absolute"
                  >
                    {formatAmount(
                      earningData?.potentialEarnings,
                      profileData.userInfo?.cultureInfo?.languageCode,
                      profileData.userInfo?.cultureInfo?.defaultCurrencyCode
                    )}
                  </div>
                ) : (
                  <div
                    style={{ width: "250px", top: "110px", right: "45px" }}
                    className=" text-center dm-sans-bold s34 position-absolute"
                  >
                    {formatAmount(
                      earningData?.potentialEarnings,
                      profileData.userInfo?.cultureInfo?.languageCode,
                      profileData.userInfo?.cultureInfo?.defaultCurrencyCode
                    )}
                  </div>
                )}
                <div
                  style={{ width: "296px", height: "296px" }}
                  className=" graph-limit-canvas-width"
                >
                  {data && <GraphData data={data} plugins={plugins} />}
                </div>
              </div>
              <div className=" invisible" style={{ minWidth: "334px" }}>
                .
              </div>
              <div
                style={
                  biggerThan1000
                    ? { paddingLeft: "64px" }
                    : { paddingLeft: "18px" }
                }
                className=" w-100"
              >
                <GraphListPeriods
                  earningData={earningData}
                  profileData={profileData}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export function SkeletonDashboardRowData(params) {
  return (
    
      <div className=" mt-4 pt-3 d-flex flex-column">
        <div className=" w-100 mb-3 ">
          <div style={{ height: "16px" }} className=" rounded-3 w-25">
            <div
              style={{ height: "16px" }}
              className="lightui1-shimmer rounded-3 w-50 brightGrayBg"
            ></div>
          </div>
        </div>
        <div className=" d-flex justify-content-between">
          <div className="d-flex gap-3  align-items-center ">
            <div
              style={{ width: "12px", height: "12px" }}
              className="lightui1-shimmer rounded-circle brightGrayBg  "
            ></div>
            <div
              style={{ width: "170px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
          </div>
          <div className="d-flex gap-3 align-items-center me-3">
            <div
              style={{ width: "55px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
            <div className=" invisible">
              <SVGChevArrowDown />
            </div>
          </div>
        </div>
        <hr></hr>
        <div className=" w-100 my-3 ">
          <div style={{ height: "16px" }} className=" rounded-3 w-25">
            <div
              style={{ height: "16px" }}
              className="lightui1-shimmer rounded-3 w-50 brightGrayBg"
            ></div>
          </div>
        </div>
        <div className=" d-flex justify-content-between">
          <div className="d-flex gap-3  align-items-center ">
            <div
              style={{ width: "12px", height: "12px" }}
              className="lightui1-shimmer rounded-circle brightGrayBg  "
            ></div>
            <div
              style={{ width: "170px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
          </div>
          <div className="d-flex gap-3 align-items-center me-3">
            <div
              style={{ width: "55px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
            <SVGChevArrowDown />
          </div>
        </div>
        <hr></hr>
        <div className=" d-flex justify-content-between">
          <div className="d-flex gap-3  align-items-center ">
            <div
              style={{ width: "12px", height: "12px" }}
              className="lightui1-shimmer rounded-circle brightGrayBg  "
            ></div>
            <div
              style={{ width: "170px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
          </div>
          <div className="d-flex gap-3 align-items-center me-3">
            <div
              style={{ width: "55px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
            <SVGChevArrowDown />
          </div>
        </div>
        <hr></hr>
        <div className=" d-flex justify-content-between">
          <div className="d-flex gap-3  align-items-center ">
            <div
              style={{ width: "12px", height: "12px" }}
              className="lightui1-shimmer rounded-circle brightGrayBg  "
            ></div>
            <div
              style={{ width: "170px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
          </div>
          <hr></hr>
          <div className="d-flex gap-3 align-items-center me-3">
            <div
              style={{ width: "55px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
            <SVGChevArrowDown />
          </div>
        </div>
        <hr></hr>
        <div className=" d-flex justify-content-between">
          <div className="d-flex gap-3  align-items-center ">
            <div
              style={{ width: "12px", height: "12px" }}
              className="lightui1-shimmer rounded-circle brightGrayBg  "
            ></div>
            <div
              style={{ width: "170px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
          </div>
          <hr></hr>
          <div className="d-flex gap-3 align-items-center me-3">
            <div
              style={{ width: "55px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
            <SVGChevArrowDown />
          </div>
        </div>
        <hr></hr>
        <div className=" d-flex justify-content-between">
          <div className="d-flex gap-3  align-items-center ">
            <div
              style={{ width: "12px", height: "12px" }}
              className=" invisible rounded-circle brightGrayBg  "
            ></div>
            <div
              style={{ width: "170px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
          </div>
          <hr></hr>
          <div className="d-flex gap-3 align-items-center me-3">
            <div
              style={{ width: "55px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
            <div className=" invisible">
              <SVGChevArrowDown />
            </div>
          </div>
        </div>
        <hr></hr>
        <div className=" d-flex justify-content-between">
          <div className="d-flex gap-3  align-items-center ">
            <div
              style={{ width: "12px", height: "12px" }}
              className="lightui1-shimmer invisible rounded-circle brightGrayBg  "
            ></div>
            <div
              style={{ width: "170px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
          </div>
          <hr></hr>
          <div className="d-flex gap-3 align-items-center me-3">
            <div
              style={{ width: "55px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
            <div className=" invisible">
              <SVGChevArrowDown />
            </div>
          </div>
        </div>
        <hr></hr>
        <div className=" d-flex justify-content-between">
          <div className="d-flex gap-3  align-items-center ">
            <div
              style={{ width: "12px", height: "12px" }}
              className="lightui1-shimmer invisible rounded-circle brightGrayBg  "
            ></div>
            <div
              style={{ width: "170px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
          </div>
          <hr></hr>
          <div className="d-flex gap-3 align-items-center me-3">
            <div
              style={{ width: "55px", height: "12px" }}
              className="lightui1-shimmer rounded-3 brightGrayBg  "
            ></div>
            <div className=" invisible">
              <SVGChevArrowDown />
            </div>
          </div>
        </div>
      </div>
    
  );
}
