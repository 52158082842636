import { ReactComponent as ArrowDown } from "../../../common/svgs/ic-chevron-down-md.svg";
import { FormattedMessage } from "react-intl";
import Radius from "../../../common/Share/Radius";
import { useDispatch } from "react-redux";
import {
  cleanFirstGenerationData,
  filteringData,
} from "../../../FirstSteps/Framework/redux/FirstStepsAllActions";
import {
  getDataFirstGeneration,
  getDataLiderAndDirector,
} from "../../../FirstSteps/Framework/redux/FirstStepsAllSideEffect";

export default function DashboardFirstStepsFilter({
  filter,
  setFilter,
  setSelectedButtonOren,
  selectedButtonOrden,
  setSelectedButton,
  selectedButton,
  profileData
}) {
  const fullUrl = window.location.href;
  const pathname = new URL(fullUrl).pathname;
  const parts = pathname.split("/");
  const name = parts[parts.length - 1];

  const dispatch = useDispatch();
  
  const changeToEntryDate = async () => {
    dispatch(filteringData(true))
    setSelectedButton(1);
    if (selectedButtonOrden == 0) {
      let ordernarPor = "entryDate";
      let orden = "desc";
      if (name == "firstGeneration") {
        let auxOffSet = 0;
        dispatch(cleanFirstGenerationData());
        await dispatch(
          getDataFirstGeneration({ ordernarPor, orden, auxOffSet })
        );
      } else {
        await dispatch(getDataLiderAndDirector({ ordernarPor, orden }));
      }
    } else {
      let ordernarPor = "entryDate";
      let orden = "asc";
      if (name == "firstGeneration") {
        let auxOffSet = 0;
        dispatch(cleanFirstGenerationData());
        await dispatch(
          getDataFirstGeneration({ ordernarPor, orden, auxOffSet })
        );
      } else {
        await dispatch(getDataLiderAndDirector({ ordernarPor, orden }));
      }
    }
    dispatch(filteringData(false))
  };

  const changeToStepsReached = async () => {
    dispatch(filteringData(true))
    setSelectedButton(0);
    if (selectedButtonOrden == 0) {
      let ordernarPor = "stepsReached";
      let orden = "desc";
      if (name == "firstGeneration") {
        let auxOffSet = 0;
        dispatch(cleanFirstGenerationData());
        await dispatch(
          getDataFirstGeneration({ ordernarPor, orden, auxOffSet })
        );
      } else {
        await dispatch(getDataLiderAndDirector({ ordernarPor, orden }));
      }
    } else {
      let ordernarPor = "stepsReached";
      let orden = "asc";
      if (name == "firstGeneration") {
        let auxOffSet = 0;
        dispatch(cleanFirstGenerationData());
        await dispatch(
          getDataFirstGeneration({ ordernarPor, orden, auxOffSet })
        );
      } else {
        await dispatch(getDataLiderAndDirector({ ordernarPor, orden }));
      }
    }
    dispatch(filteringData(false))
  };

  const changeToOrderAsc = async () => {
    dispatch(filteringData(true))
    setSelectedButtonOren(1);
    if (selectedButton == 1) {
      let ordernarPor = "entryDate";
      let orden = "asc";
      if (name == "firstGeneration") {
        let auxOffSet = 0;
        dispatch(cleanFirstGenerationData());
        await dispatch(
          getDataFirstGeneration({ ordernarPor, orden, auxOffSet })
        );
      } else {
        await dispatch(getDataLiderAndDirector({ ordernarPor, orden }));
      }
    } else {
      let ordernarPor = "stepsReached";
      let orden = "asc";
      if (name == "firstGeneration") {
        let auxOffSet = 0;
        dispatch(cleanFirstGenerationData());
        await dispatch(
          getDataFirstGeneration({ ordernarPor, orden, auxOffSet })
        );
      } else {
        await dispatch(getDataLiderAndDirector({ ordernarPor, orden }));
      }
    }
    dispatch(filteringData(false))
  };

  const changeToOrderDesc = async () => {
    dispatch(filteringData(true))
    setSelectedButtonOren(0);
    if (selectedButton == 1) {
      let ordernarPor = "entryDate";
      let orden = "desc";
      if (name == "firstGeneration") {
        let auxOffSet = 0;
        dispatch(cleanFirstGenerationData());
        await dispatch(
          getDataFirstGeneration({ ordernarPor, orden, auxOffSet })
        );
      } else {
        await dispatch(getDataLiderAndDirector({ ordernarPor, orden }));
      }
    } else {
      let ordernarPor = "stepsReached";
      let orden = "desc";
      if (name == "firstGeneration") {
        let auxOffSet = 0;
        dispatch(cleanFirstGenerationData());
        await dispatch(
          getDataFirstGeneration({ ordernarPor, orden, auxOffSet })
        );
      } else {
        await dispatch(getDataLiderAndDirector({ ordernarPor, orden }));
      }
    }
    dispatch(filteringData(false))
  };

  return (
      <div className="">
        <div
          className="position-relative w-100 d-flex justify-content-end  gap-2 flex-row "
          style={{ height: profileData?.level < 2 ? "47px" : "40px" }}
        >
          <button
            className="position-absolute skyColor skyBorder bg-transparent s14 museo-sans-700 d-flex flex-row justify-content-center align-items-center"
            style={{
              width: "184px",
              height: "40px",
              borderRadius: "8px",
              top: profileData?.level < 2 ? "-10px" : "-1px",
              zIndex: 2
            }}
            onClick={() => setFilter(!filter)}
          >
            <FormattedMessage id="qualified.groups.order.by" />{" "}
            <div style={{ paddingLeft: "8px" }}>
              <ArrowDown />
            </div>
          </button>

          {filter && (
            <div
              className="position-absolute bg-white tabs-shadow"
              style={{
                width: "184px",
                height: "252px",
                top: "60px",
                borderRadius: "8px",
                zIndex: 2,
              }}
            >
              <div className="" style={{ padding: "17px" }}>
                <p
                  className="museo-sans-700 s-14"
                  style={{ marginBottom: "14px !important" }}
                >
                  <FormattedMessage id="edb.filter.dropdown.p" />
                </p>
                <div
                  onClick={selectedButton == 0 ? null : changeToStepsReached}
                  className="d-flex hover-pointer"
                >
                  <Radius status={selectedButton === 0} />
                  <p className="ms-2 museo-sans-500 s14 blackColor user-select-none">
                    <FormattedMessage id="first.steps.reached.step" />
                  </p>
                </div>
                <div
                  onClick={selectedButton == 0 ? changeToEntryDate : null}
                  className="d-flex hover-pointer mt-3"
                >
                  <Radius status={selectedButton === 1} />
                  <p className="ms-2 museo-sans-500 s14 blackColor user-select-none">
                    <FormattedMessage id="first.steps.date.sign.up" />
                  </p>
                </div>
              </div>
              <hr style={{ margin: "0px 0px" }}></hr>
              <div className="" style={{ padding: "17px" }}>
                <p
                  className="museo-sans-700 s-14"
                  style={{ marginBottom: "14px !important" }}
                >
                  <FormattedMessage id="first.steps.order" />:
                </p>
                <div
                  className="d-flex hover-pointer"
                  onClick={changeToOrderDesc}
                >
                  <Radius status={selectedButtonOrden === 0} />
                  <p className="ms-2 museo-sans-500 s14 blackColor user-select-none">
                    <FormattedMessage
                      id="qualified.groups.desc"
                      defaultMessage="Sin ventas"
                    />
                  </p>
                </div>
                <div
                  className="d-flex hover-pointer mt-3"
                  onClick={changeToOrderAsc}
                >
                  <Radius status={selectedButtonOrden === 1} />
                  <p className="ms-2 museo-sans-500 s14 blackColor user-select-none">
                    <FormattedMessage
                      id="descendants.user.info.modal.ascendant.name"
                      defaultMessage="Todos/as"
                    />
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
  );
}
