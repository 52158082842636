import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGRedCross } from "../../../../../EDBs/Presentation/img/redcross.svg";

export default function ClientListFormTextArea({
  label,
  placeholder,
  name,
  register,
  isError,
  onChangeValidate
}) {
  
  return (
    <div className="d-flex w-100 flex-column gap-2">
      <div>
        <p className=" museo-sans-500 s14">{label}</p>
      </div>
      <div className=" position-relative w-100" style={{ height: "171px" }}>
        <div
        onClick={onChangeValidate}
          className={` ${
            isError ? "redBorder" : "input-border"
          } museo-sans-500 s14 d-flex justify-content-center align-items-center rounded-2 w-100 h-100 p-2 `}
        >
          <textarea
            style={{ resize: "none" }}
            placeholder={placeholder}
            className=" no-outline-input border-0 m-1 h-100 w-100"
            maxLength={1000}
            {...register(name)}
          />
        </div>
        {
          isError &&
        <p className=" position-relative s12 museo-sans-500 d-flex gap-1" style={{ color: "red", top: "6px" }}>
          <SVGRedCross />
          <FormattedMessage id="settings.error.format" />
        </p>

        }
        
      </div>
    </div>
  );
}
