import { ReactComponent as SVGCheckGreen } from "../../../Dashboard/Presentation/SVGs/checkGreen.svg";
import { ReactComponent as SVGCheckBlue } from "../../../Dashboard/Presentation/SVGs/checkBlue.svg";
import { ReactComponent as SVGCrossRed } from "../../../Dashboard/Presentation/SVGs/crossRed.svg";
import { ReactComponent as SVGGap } from "../../SVGs/warning.svg";

export default function OriginRoadHelpMessage({ CAOData }) {

  const objectSVGs = {
    completed: <SVGCheckGreen />,
    risk: <SVGCrossRed />,
    gap: <SVGGap />,
    warning: <SVGGap />,
    info: <SVGCheckBlue />,
  };

  const objectBGStyles = {
    completed: {
      backgroundColor: "#e7f5f0",
      color: "#199666",
    },
    risk: {
      color: "#62221F",
      background: "#FEF0F0",
    },
    gap: {
      color: "#4D4D4D",
      background: "#FEFBEC",
    },
    warning: {
      color: "#4D4D4D",
      background: "#FEFBEC",
    },
    normal: {

        color: "#171A22",
        background: "#ECF0FA",
      },
      info: {

        color: "#456ECE",
        background: "#ECF0FA",
      }
  };

  const SVGParams = {
    completed: {
      width: "18px",
      height: "18px",
    },
    info: {
      width: "18px",
      height: "18px",
    },
    risk: {
      width: "32px",
      height: "32px",
    },
    gap: {
      width: "32px",
      height: "32px",
    },
    warning: {
      width: "32px",
      height: "32px",
    },
  };

  const objectBorders = {
    completed: "2px green solid",
    info: "2px #456ECE solid",
  }

  return (
    <>
      {CAOData?.signs?.length > 0 ? (
        <div style={{gap: "20px"}} className="d-flex flex-column" >
          {CAOData?.signs.map((sign) => {
            return (
              <div
                style={objectBGStyles[sign?.status || "completed"]}
                className="p-3 gap-2 d-flex align-items-center justify-content-between h-100 position-relative rounded-8 "
              >
                <div className="d-flex align-items-center gap-2">
                  {objectSVGs[sign?.status] ? (
                    <div
                      style={{
                        border: objectBorders[sign?.status] ||  "",
                        width: "32px",
                        height: "32px",
                      }}
                      className=" position-relative d-flex justify-content-center align-items-center rounded-circle"
                    >
                      <div className=" posi"
                      style={{
                        width: "32px",
                        height: "32px",
                      }}>
                        <p className="invisible position-absolute" >
                          . 
                        </p>
                        </div>
                      <div style={{
                        width: SVGParams[sign?.status].width,
                        height: SVGParams[sign?.status].height,
                        top: 3,
                      }} className=" position-absolute svg-force-size">
                        {
                          objectSVGs[sign?.status]
                        }
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="d-flex flex-column">
                    <p className=" s16 museo-sans-700">{sign?.title}</p>
                    <p className=" s14">{sign?.message}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
