import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../services/NetSrv";

const fetchUserProfile  = createAsyncThunk(
    'fetchUserProfile',
    async (number, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            const response = await customNetServAxios().get(`/${location}/profile/${number}`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

function _base64ToArrayBuffer(base64) {
    let binary_string = atob(base64.split(",")[1]);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

const updateProfilePicture  = createAsyncThunk(
    'updateProfilePicture',
    async (imageAsDataURL, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/profile/${clientId}/image`)
            await customNetServAxios().put(response.uploadLocation, _base64ToArrayBuffer(imageAsDataURL), {
                headers: {},
                transformRequest: [
                  function (data, headers) {
                    // Do whatever you want to transform the data
                    delete headers["Accept"];
                    headers["Content-Type"] = "";
                    delete headers["Authorization"];
                    delete headers["put"];
                    
                    return data;
                  },
                ],
              })
        } catch(err) {
           return rejectWithValue(err.message);
        }
    }
)

const logOut  = createAsyncThunk(
    'logOut',
    async (number, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let urlencoded = new URLSearchParams();
            urlencoded.append("username", "139001");
            await customNetServAxios().post(`/${location}/security/auth/logout`, urlencoded);
            //localStorage.removeItem("country");
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
);


const fetchPerformance = createAsyncThunk(
    "fetchPerformance",
    async ({rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            return await customNetServAxios().get(`/${location}/performance/${clientId}/score`);
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }

);

export { fetchPerformance, updateProfilePicture, logOut, fetchUserProfile }
