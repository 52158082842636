import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SVGExternal } from "../../../common/svgs/ic-external-link-md.svg";

import FirstStepsPersonalStepsCard from "./FirstStepsPersonalStepsCard";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import imageKit from "../imgs/noKitImage.png";
import { FormattedMessage } from "react-intl";
import ShareErrorHandlerServices from "../../../common/Share/ShareErrorHandlerServices";
import {  getDataConsultant } from "../../Framework/redux/FirstStepsAllSideEffect.js"

export default function FirstStepsPersonalStepsContainer({ biggerThan1025 }) {
  const ofersData = useSelector((state) => state.firstStepsAllReducer);
  const firstStepsError = useSelector(
    (state) => state.firstStepsAllReducer.firstStepsError
  );
  const dispatch = useDispatch();

  const onLinkClick = () => {
    const click = document.getElementById("get-new-kit");

    click.click();
  };

  const fetchFirstStepsPersonal = async () => {
    await dispatch(getDataConsultant());
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return ''; // Maneja el caso en que no haya fecha
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0'); // Asegura 2 dígitos
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses empiezan en 0
    const year = String(date.getFullYear()).slice(2); // Solo últimos 2 dígitos del año
    return `${day}/${month}/${year}`;
};

  return (
    <>
      {ofersData?.firstSteps?.billedKit != null && (
        <div className="d-flex flex-column w-100">
          {ofersData?.firstSteps?.billedKit ? (
            <>
              {firstStepsError ? (
                <div>
                  <div className=" pb-3 mb-2 d-flex justify-content-between align-items-end">
                    <div className="d-flex align-items-center gap-2 museo-sans-500">
                      <div className="d-flex gap-2">
                        <h4 className="m-0 s20 pointer text-capitalize ">
                          <FormattedMessage id="first.steps.your.first.steps" />
                        </h4>
                        <div
                          data-bs-toggle="modal"
                          data-bs-target="#modalFirstTwo"
                          className=" pointer"
                        >
                          <HelpIconNoTarget />
                        </div>
                        <div className="museo-sans-700 s14 p-0 border-0 bg-transparent grayColor ">
                          <FormattedMessage id="first.steps.guidelines.program" />
                        </div>
                      </div>
                    </div>
                    <div className=" museo-sans-500 grayColor s16">
                      <p className=" invisible">
                        <>
                          <FormattedMessage id="header.week" />{" "}
                          {ofersData.firstSteps.currentWeek}{" "}
                          <FormattedMessage id="of" />{" "}
                          {ofersData.firstSteps.totalWeeks}
                        </>
                      </p>
                    </div>
                  </div>
                  <div className=" my-4">
                    <ShareErrorHandlerServices service={fetchFirstStepsPersonal} />
                  </div>
                </div>
              ) : (
                <>
                  <div className=" pb-3 mb-2 d-flex justify-content-between align-items-end">
                    <div className="d-flex align-items-center gap-2 museo-sans-500">
                      <div className="d-flex gap-2">
                        <h4 className="m-0 s20 pointer text-capitalize ">
                          <FormattedMessage id="first.steps.your.first.steps" />
                        </h4>
                        <div
                          data-bs-toggle="modal"
                          data-bs-target="#modalFirstTwo"
                          className=" pointer"
                        >
                          <HelpIconNoTarget />
                        </div>
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#modalFirstStepsGuidelinesB"
                          className="museo-sans-700 s14 p-0 border-0 bg-transparent"
                          style={{ color: "#5AAFF1" }}
                        >
                          <FormattedMessage id="first.steps.guidelines.program" />
                        </button>
                      </div>
                    </div>
                    <div className=" museo-sans-500 grayColor s16">
                      {
                        ofersData.firstSteps.currentWeek == ofersData.firstSteps.totalWeeks ?
                          <p>
                            <FormattedMessage id="week.end.of.week.date" /> {formatDate(ofersData.firstSteps.roadmapEndDate)}
                          </p>
                          :
                          <p>
                        <>
                          <FormattedMessage id="header.week" />{" "}
                          {ofersData.firstSteps.currentWeek}{" "}
                          <FormattedMessage id="of" />{" "}
                          {ofersData.firstSteps.totalWeeks}
                        </>
                      </p>
                      }
                      
                    </div>
                  </div>
                  <div className="d-flex gap-4">
                    {ofersData?.firstSteps?.steps && (
                      <>
                        <FirstStepsPersonalStepsCard
                          number={1}
                          data={ofersData?.firstSteps?.steps[0]}
                          metaData={ofersData?.firstSteps?._metadata?.checkInOrder}
                          redeemEndDate={ofersData?.firstSteps?.redeemEndDate}
                        />
                        <FirstStepsPersonalStepsCard
                          number={2}
                          data={ofersData?.firstSteps?.steps[1]}
                          metaData={ofersData?.firstSteps?._metadata?.checkInOrder}
                          redeemEndDate={ofersData?.firstSteps?.redeemEndDate}
                        />
                        {
                          ofersData.firstSteps?.steps[2]?.stepDetail &&
                            <FirstStepsPersonalStepsCard
                              number={3}
                              data={ofersData.firstSteps.steps[2]}
                              extraData={
                                ofersData.firstSteps.steps[2]?.stepDetail
                                  ?.stepDependency == 1
                                  ? ofersData.firstSteps.steps[0]
                                  : ofersData.firstSteps.steps[1]
                              }
                              metaData={ofersData.firstSteps._metadata.checkInOrder}
                              redeemEndDate={ofersData?.firstSteps?.redeemEndDate}
                            />
                          
                        }
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <a
                id="get-new-kit"
                className=" invisible"
                target="_blank"
                href={ofersData?.firstSteps?._metadata?.checkInOrder}
              >invisible</a>
              <div className=" pb-3 mb-2 d-flex justify-content-between align-items-end">
                <div className="d-flex align-items-center gap-2 museo-sans-500">
                  <div className="d-flex align-items-center gap-2">
                    <h4 className="m-0 s20 pointer ">
                      <FormattedMessage id="first.steps.your.first.steps" />
                    </h4>
                    <div
                      data-bs-toggle="modal"
                      data-bs-target="#modalFirstTwo"
                      className=" pointer"
                    >
                      <HelpIconNoTarget />
                    </div>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#modalFirstStepsGuidelines"
                      className="museo-sans-700 s14 p-0 border-0 bg-transparent"
                      style={{ color: "#5AAFF1" }}
                    >
                      <FormattedMessage id="first.steps.guidelines.program" />
                    </button>
                  </div>
                </div>
                <div className=" museo-sans-500 grayColor s16">
                  <p></p>
                </div>
              </div>
              <div
                style={{
                  border: "solid 1px #CED2FF66",
                  boxShadow: "10px 10px 25px #A4A4D733",
                }}
                className=" museo-sans-500 bg-white d-flex rounded-4 overflow-hidden"
              >
                {biggerThan1025 ? (
                  <>
                    <div>
                      <img className=" h-100" src={imageKit} />
                    </div>
                    <div className=" d-flex w-100 justify-content-between align-items-center p-3 mx-3 h-100">
                      <div
                        style={{ maxWidth: "466px" }}
                        className=" pe-3 d-flex flex-column gap-2"
                      >
                        <p className=" museo-sans-700 s16">
                          <FormattedMessage id="first.steps.no.kit.title" />
                        </p>
                        <p className=" s14 grayColor">
                          <FormattedMessage id="first.steps.no.kit.content" />
                        </p>
                      </div>
                      <div style={{ width: "328px" }}>
                        <div
                          onClick={onLinkClick}
                          style={{
                            minWidth: "328px",
                            height: "40px",
                            borderRadius: "8px",
                          }}
                          className=" w-100 position-relative pointer d-flex justify-content-center align-items-center py-2 d-flex skyBorder border-1 skyColor"
                        >
                          <p className="  s14 museo-sans-700 py-1 pe-2">
                            <FormattedMessage id="first.steps.no.kit.button" />
                          </p>
                          <div
                            style={{
                              width: "14px",
                              height: "14px",
                              top: "-4px",
                            }}
                            className=" position-relative svg-force-size"
                          >
                            <SVGExternal />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{ width: "570px" }}
                      className=" overflow-hidden position-relative"
                    >
                      <img
                        style={{ right: "-52px" }}
                        className=" h-100 position-absolute"
                        src={imageKit}
                      />
                    </div>
                    <div className=" d-flex justify-content-between align-items-center p-3 mx-1 h-100">
                      <div className=" pe-3 w-100 d-flex flex-column gap-2">
                        <p className=" museo-sans-700 s16">
                          <FormattedMessage id="first.steps.no.kit.title" />
                        </p>
                        <p className=" s14 grayColor">
                          <FormattedMessage id="first.steps.no.kit.content" />
                        </p>
                      </div>
                      <div
                        onClick={onLinkClick}
                        style={{
                          width: "328px",
                          height: "40px",
                          borderRadius: "8px",
                        }}
                        className=" pointer d-flex justify-content-center align-items-center px-3 py-2 d-flex skyBorder border-1 skyColor"
                      >
                        <p className="  s14 museo-sans-700 py-1 pe-2">
                          <FormattedMessage id="first.steps.no.kit.button" />
                        </p>
                        <div
                          style={{ width: "14px", height: "14px", top: "-4px" }}
                          className=" position-relative svg-force-size"
                        >
                          <SVGExternal />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
