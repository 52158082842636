import { createAction } from '@reduxjs/toolkit';

const changeClosedEDBsOffset = createAction('changeClosedEDBsOffset')

const saveFilters = createAction('saveFilters')
const keepRawFilters = createAction('keepRawFilters')
const resetFilters = createAction('resetFilters')
const resetOffersAndNotices = createAction('resetOffersAndnotices')
const resetFirstSteps = createAction('resetFirstSteps')
const resetOnReload = createAction('resetOnReload')

//FORM EDB Create
const resetSelects = createAction('resetSelects')
const clientDetailsForm = createAction('clientDetailsForm')
const clearClientDetailsForm = createAction('clearClientDetailsForm')

export  {changeClosedEDBsOffset, resetSelects, resetOnReload, saveFilters, keepRawFilters, resetFilters,resetFirstSteps, resetOffersAndNotices, clientDetailsForm, clearClientDetailsForm} 