import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProgressBarParams from "../../../../common/Share/ProgressBarParams";
import { ReactComponent as SVGChevArrowDown } from "../../../../common/svgs/ic-chevron-down-sm.svg";
import { ReactComponent as SVGChevArrowUp } from "../../../../common/svgs/ic-chevron-up-sm.svg";
import { ReactComponent as SVGArrow } from "../../../../common/svgs/ic-arrow-right-sm.svg";
import { ReactComponent as SVGMedal } from "../../../../common/svgs/ic-medal-dollar-lg.svg";
import { ReactComponent as SVGMedalRed } from "../../../../common/svgs/ic-medal-dollar-lg-red.svg";
import { ReactComponent as SVGTriangle } from "../../../../common/svgs/ic-triangle-warning-lg.svg";
import { formatAmount } from "../../../../common/Share/FormatCurrency";

export default function GraphList({
  data,
  profileData,
  earningData,
  gap,
  score,
  toggler,
}) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const earningDataPL = useSelector(
    (state) => state.earning.dataPotentialLeader
  );

  return (
    <div className=" pe-3 ">
      <h4 className=" s16 m-0 text-capitalize museo-sans-700">
        <FormattedMessage id="dashboard.graph.title" />
      </h4>
      <div className=" mt-4 me-3 ">
        {profileData.level > 1 || toggler ? (
          <>
            {profileData.level > "1" ? (
              <>
                <GraphListRow
                  data={earningData}
                  index={0}
                  gap={gap}
                  cultureInfo={cultureInfo}
                />
                <hr />
                <GraphListRow
                  data={earningData}
                  index={1}
                  gap={gap}
                  cultureInfo={cultureInfo}
                />
                <hr />
                <GraphListRow
                  data={earningData}
                  index={2}
                  gap={gap}
                  level={profileData.level}
                  cultureInfo={cultureInfo}
                />
                {profileData.level > "5" && (
                  <>
                    <hr />
                    <GraphListRow
                      data={earningData}
                      index={3}
                      gap={gap}
                      cultureInfo={cultureInfo}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {earningDataPL && (
                  <>
                    <GraphListRow
                      data={earningDataPL}
                      index={0}
                      gap={gap}
                      cultureInfo={cultureInfo}
                    />
                    <hr />
                    <GraphListRow
                      data={earningDataPL}
                      index={1}
                      gap={gap}
                      cultureInfo={cultureInfo}
                    />
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <GraphConsultantPersonalSale
              earningData={earningData}
              data={data}
              index={0}
              gap={gap}
              cultureInfo={cultureInfo}
            />
            <hr />
            <GraphConsultantGroup
              earningData={earningData}
              data={data}
              index={1}
              gap={gap}
              cultureInfo={cultureInfo}
            />
          </>
        )}
      </div>
    </div>
  );
}

export function GraphConsultantPersonalSale({
  earningData,
  data,
  index,
  gap,
  cultureInfo,
}) {
  const [toggle, setToggle] = useState(false);
  const biggerThan1000 = useMediaPredicate("(min-width: 1025px)");

  const switchColor = (index, amount) => {
    if (gap) {
      return "graphGrayBg";
    }
    if (amount < 0) {
      return "lightRedBg";
    }
    switch (index) {
      case 0:
        return "labelOneBg";
    }
  };

  const onClick = () => {
    setToggle(!toggle);
  };

  const GraphConcultantPersonalSaleHeader = (
    <div
      onClick={onClick}
      className=" pointer mb-3 d-flex justify-content-between "
    >
      <div className=" gap-3 align-items-center d-flex">
        <div
          style={{ width: "10px", height: "10px" }}
          className={`${switchColor(
            index,
            earningData?.byPersonalSales?.amount
          )} rounded-circle `}
        ></div>
        <p className=" s12 museo-sans-700 text-uppercase">
          <FormattedMessage id="nav.label.one.a" />
        </p>
      </div>
      <div className="d-flex gap-2 align-items-center ">
        <p className=" s12 museo-sans-500 px-3 ">
          {toggle
            ? `Ganancia sobre Venta Personal (${earningData?.byPersonalSales?.earningPercentage}%)`
            : null}
        </p>
        <p className=" pe-2 dm-sans-medium s14">
          {gap ? (
            <p className=" grayColor museo-sans-500 ">
              <FormattedMessage id="pending" />
            </p>
          ) : earningData?.byPersonalSales?.amount === 0 ? (
            "Sin ventas"
          ) : (
            <div
              className={`${
                earningData?.byPersonalSales?.amount < 0 ? "lightRedColor" : ""
              }`}
            >
              {formatAmount(
                earningData?.byPersonalSales?.amount,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
            </div>
          )}
        </p>
        {gap ? (
          <div className="control--svg-filler-gray  grayColor pointer">
            <SVGChevArrowDown />
          </div>
        ) : (
          <>
            {toggle && (
              <div className=" px-1 control--svg-filler-sky pointer">
                <SVGChevArrowUp />
              </div>
            )}
            {!toggle && (
              <div className=" px-1 control--svg-filler-sky pointer">
                <SVGChevArrowDown />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );

  return (
    <>
      {GraphConcultantPersonalSaleHeader}
      <div className=" rounded-2 d-flex flex-column justify-content-center gap-2 f9GrayBg px-3 py-3 w-100">
        <div className=" d-flex justify-content-between">
          <p className=" museo-sans-500 s12">
            <FormattedMessage id="dashboard.graph.dropmenu.sublevel.two" />
          </p>
          <p className=" museo-sans-500 text-capitalize s12">
            {earningData?.byPersonalSales?.personalSaleBond?.statistics
              ?.amount >=
            earningData?.byPersonalSales?.personalSaleBond?.statistics
              ?.totalAmount ? (
              <FormattedMessage id="first.steps.status.reached" />
            ) : (
              <FormattedMessage id="first.steps.status.not.reached" />
            )}
          </p>
        </div>
        <div className=" d-flex justify-content-between">
          <p className=" grayColor museo-sans-500 s12">
            <FormattedMessage id="dashboard.graph.dropmenu.sublevel.two.b" />
          </p>
          <p className=" dm-sans-medium s12 ">
            <div className=" d-flex gap-1 ">
              <p
                className={`${
                  earningData?.byPersonalSales?.personalSaleBond?.statistics
                    ?.amount < 0
                    ? "lightRedColor "
                    : ""
                }`}
              >
                {formatAmount(
                  earningData?.byPersonalSales?.personalSaleBond?.statistics
                    ?.amount,
                  cultureInfo?.languageCode,
                  cultureInfo?.defaultCurrencyCode
                )}
              </p>
              {"/"}
              <p className=" grayColor ">
                {formatAmount(
                  earningData?.byPersonalSales?.personalSaleBond?.statistics
                    ?.totalAmount,
                  cultureInfo?.languageCode,
                  cultureInfo?.defaultCurrencyCode
                )}
              </p>
            </div>
          </p>
        </div>
        {earningData?.byPersonalSales?.personalSaleBond?.statistics?.status ==
        "completed" ? (
          <ProgressBarParams
            status={earningData?.byPersonalSales?.status}
            darkGray={true}
            height={12}
            completed={100}
            incompleted={false}
          />
        ) : (
          <ProgressBarParams
            darkGray={true}
            height={12}
            status={
              earningData?.byPersonalSales?.personalSaleBond?.statistics?.status
            }
            completed={
              (earningData?.byPersonalSales?.personalSaleBond?.statistics
                ?.amount /
                earningData?.byPersonalSales?.personalSaleBond?.statistics
                  ?.totalAmount) *
              100
            }
            incompleted={true}
          />
        )}

        {toggle && (
          <div
            className={`rounded-2 p-3 gap-3  d-flex ${
              earningData?.byPersonalSales?.personalSaleBond?.statistics
                ?.status == "completed"
                ? "blueColor donutHeaderBg "
                : "lightRedColor lightRedBgOpacity20"
            }`}
          >
            <div className="">
              <SVGMedalRed />
            </div>
            <p className=" s14 museo-sans-500">
              <FormattedMessage id="dashboard.graph.consultant.pop.one" />
              {formatAmount(
                earningData?.byPersonalSales?.personalSaleBond?.statistics
                  ?.totalAmount,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}{" "}
              <FormattedMessage id="dashboard.graph.consultant.pop.two" />{" "}
              {earningData?.byPersonalSales?.personalSaleBond?.percentage}%{" "}
              <FormattedMessage id="dashboard.graph.consultant.pop.three" />{" "}
              {earningData?.byPersonalSales?.instantEarnings?.percentage +
                earningData?.byPersonalSales?.personalSaleBond?.percentage}
              %!
            </p>
          </div>
        )}
      </div>
      {toggle && (
        <div className=" rounded-2 d-flex flex-column justify-content-center gap-2 f9GrayBg py-3 w-100 museo-sans-500 s12 ">
          <div className=" graph-listing justify-content-between px-3 text-uppercase  align-items-center grayColor">
            <p>
              <FormattedMessage id="dashboard.graph.dropmenu.one" />
            </p>
            <p className="graph-listing-row">
              <FormattedMessage id="dashboard.graph.dropmenu.two" />
            </p>
            <p className="graph-listing-row">
              {biggerThan1000 ? (
                <FormattedMessage id="dashboard.graph.dropmenu.three" />
              ) : (
                <FormattedMessage id="dashboard.graph.dropmenu.three.short" />
              )}
            </p>
            <p className="graph-listing-row">
              <FormattedMessage id="dashboard.graph.dropmenu.four" />
            </p>
          </div>
          <hr className=" my-2 mx-0"></hr>
          <div className=" s12 graph-listing justify-content-between px-3 dm-sans-medium  align-items-center grayColor ">
            <p className=" museo-sans-500  blackColor">
              <FormattedMessage id="dashboard.graph.dropmenu.sublevel.one" />
            </p>
            <p className=" graph-listing-row  ">
              {formatAmount(
                earningData?.byPersonalSales?.totalSale,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
            </p>
            <p className=" graph-listing-row ">
              %{earningData?.byPersonalSales?.instantEarnings?.percentage}
            </p>
            <p
              className={` ${
                earningData?.byPersonalSales?.instantEarnings?.amount < 0
                  ? "lightRedColor"
                  : "blackColor"
              } graph-listing-row   `}
            >
              {formatAmount(
                earningData?.byPersonalSales?.instantEarnings?.amount,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
            </p>
          </div>
        </div>
      )}
      {earningData?.byPersonalSales?.personalSaleBond?.statistics?.amount <
        0 && (
        <div style={{background: "#FEF0F0", color: "#4D4D4D"}} className="p-3 rounded-3 s12 museo-sans-500 mt-2" >
          <FormattedMessage id="dashboard.graph.negative.value.help.message" />
        </div>
      )}
    </>
  );
}

export function GraphConsultantGroup({
  earningData,
  data,
  index,
  gap,
  cultureInfo,
}) {
  const biggerThan1000 = useMediaPredicate("(min-width: 1025px)");
  const [toggle, setToggle] = useState(false);
  const history = useNavigate();

  const onClickNavigate = () => {
    history("/dashboard/performance/personalIncorporated");
  };

  const switchColor = (index, amount) => {
    if (gap) {
      return "brightGrayBg";
    }
    if (amount < 0) {
      return "lightRedBg";
    }
    switch (index) {
      case 1:
        return "labelTwoBg";
    }
  };

  const onClick = () => {
    setToggle(!toggle);
  };

  const changeColor = () => {
    if (
      earningData?.byPersonalIncorporation?.statistics
        ?.incorporatedWithSales === 0
    ) {
      return "lightRedColor";
    } else {
      return "lightGreenColor";
    }
  };

  return (
    <>
      <div
        onClick={onClick}
        className=" pointer mb-3 rounded-2 d-flex justify-content-between "
      >
        <div className=" gap-3 align-items-center d-flex">
          <div
            style={{ width: "10px", height: "10px" }}
            className={`${switchColor(
              index,
              earningData?.byPersonalIncorporation?.amount
            )} rounded-circle `}
          ></div>
          <p className=" s12 museo-sans-700 text-uppercase">
            {biggerThan1000 ? (
              <FormattedMessage id="business.plan.earning.incorporated.personal" />
            ) : (
              <FormattedMessage id="business.plan.earning.incorporated.personal.short" />
            )}
          </p>
        </div>
        <div className="d-flex align-items-center ">
          {toggle && (
            <p className=" s12 museo-sans-500 px-4 ">
              {biggerThan1000 ? (
                <FormattedMessage id="dashboard.graph.sublabel.one" />
              ) : (
                <FormattedMessage id="dashboard.graph.sublabel.one.short" />
              )}{" "}
              {earningData?.byPersonalIncorporation?.percentage}%
            </p>
          )}
          {gap ? (
            <p className=" grayColor museo-sans-500 pe-2 s14">
              <FormattedMessage id="pending" />
            </p>
          ) : (
            <p className=" pe-2 dm-sans-medium s14">
              {earningData?.byPersonalIncorporation?.amount === 0
                ? "Sin ventas"
                : formatAmount(
                    earningData?.byPersonalIncorporation?.amount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
            </p>
          )}

          {gap ? (
            <div className="control--svg-filler-gray  grayColor pointer">
              <SVGChevArrowDown />
            </div>
          ) : (
            <>
              {toggle && (
                <div className=" px-1 control--svg-filler-sky pointer">
                  <SVGChevArrowUp />
                </div>
              )}
              {!toggle && (
                <div className=" px-1 control--svg-filler-sky pointer">
                  <SVGChevArrowDown />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className=" rounded-2 d-flex flex-column justify-content-center gap-2 f9GrayBg px-3 py-3 w-100">
        <div className=" d-flex justify-content-between">
          <div className=" d-flex dm-sans-bold s26">
            <p className={`${changeColor()}`}>
              {
                earningData?.byPersonalIncorporation?.statistics
                  ?.incorporatedWithSales
              }
            </p>
            <p>
              /
              {
                earningData?.byPersonalIncorporation?.statistics
                  ?.totalIncorporated
              }
            </p>
          </div>
        </div>
        <div className=" d-flex justify-content-between">
          <p className=" grayColor museo-sans-500 s12">
            <FormattedMessage id="dashboard.graph.consultant.second.menu" />
          </p>
          <div
            onClick={onClickNavigate}
            className=" pointer s14 museo-sans-700 d-flex gap-1 skyColor "
          >
            <p>
              <FormattedMessage id="calendar.event.card.view.details" />
            </p>
            <SVGArrow />
          </div>
        </div>
        <div className=" d-flex">
          <div>
            {earningData?.byPersonalIncorporation?.statistics
              ?.incorporatedWithSales > 0 ? (
              <>
                <div
                  style={{ width: "24px", height: "24px" }}
                  className=" lightGreenBg rounded-circle d-flex justify-content-center "
                >
                  <i className="bi bi-check-lg whiteColor"></i>
                </div>
                <p className=" text-center s14 dm-sans-medium greenColor">2%</p>
              </>
            ) : (
              <>
                <div
                  style={{ width: "24px", height: "24px" }}
                  className=" brightGrayBg rounded-circle d-flex justify-content-center "
                >
                  <i className="bi bi-lock-fill grayColor"></i>
                </div>
                <p className=" text-center s14 dm-sans-medium grayColor">2%</p>
              </>
            )}
          </div>
          {earningData?.byPersonalIncorporation?.statistics
            ?.incorporatedWithSales > 1 ? (
            <div className="w-100 mt-2 px-1 ">
              <ProgressBarParams completed={100} incompleted={false} />
            </div>
          ) : (
            <div className="w-100 mt-2 px-1 ">
              <ProgressBarParams completed={0} incompleted={false} />
            </div>
          )}
          <div>
            {earningData?.byPersonalIncorporation?.statistics
              ?.incorporatedWithSales > 1 ? (
              <>
                <div
                  style={{ width: "24px", height: "24px" }}
                  className=" lightGreenBg rounded-circle d-flex justify-content-center "
                >
                  <i className="bi bi-check-lg whiteColor"></i>
                </div>
                <p className=" text-center s14 dm-sans-medium greenColor">3%</p>
              </>
            ) : (
              <>
                <div
                  style={{ width: "24px", height: "24px" }}
                  className=" brightGrayBg rounded-circle d-flex justify-content-center "
                >
                  <i className="bi bi-lock-fill grayColor"></i>
                </div>
                <p className=" text-center s14 dm-sans-medium grayColor">3%</p>
              </>
            )}
          </div>
          {earningData?.byPersonalIncorporation?.statistics
            ?.incorporatedWithSales > 2 ? (
            <div className="w-100 mt-2 px-1 ">
              <ProgressBarParams completed={100} incompleted={false} />
            </div>
          ) : (
            <div className="w-100 mt-2 px-1 ">
              <ProgressBarParams completed={0} incompleted={false} />
            </div>
          )}
          <div>
            {earningData?.byPersonalIncorporation?.statistics
              ?.incorporatedWithSales > 2 ? (
              <>
                <div
                  style={{ width: "24px", height: "24px" }}
                  className=" lightGreenBg rounded-circle d-flex justify-content-center "
                >
                  <i className="bi bi-check-lg whiteColor"></i>
                </div>
                <p className=" text-center s14 dm-sans-medium greenColor">4%</p>
              </>
            ) : (
              <>
                <div
                  style={{ width: "24px", height: "24px" }}
                  className=" brightGrayBg rounded-circle d-flex justify-content-center "
                >
                  <i className="bi bi-lock-fill grayColor"></i>
                </div>
                <p className=" text-center s14 dm-sans-medium grayColor">4%</p>
              </>
            )}
          </div>
        </div>
        {toggle && (
          <>
            {earningData?.byPersonalIncorporation?.statistics
              ?.incorporatedWithSales === 0 ? (
              <div
                style={{ backgroundColor: "#FEFBEC" }}
                className=" museo-sans-500 s14 goldBoldColor rounded-2 p-3 gap-3 d-flex blueColor"
              >
                <SVGTriangle />
                <FormattedMessage id="dashboard.graph.consultant.second.pop.one" />
              </div>
            ) : (
              <div
                style={{ background: "#ECF0FA" }}
                className=" rounded-2 p-3 gap-3 d-flex blueColor"
              >
                <SVGMedal />
                {earningData?.byPersonalIncorporation?.statistics
                  ?.incorporatedWithSales === 2 &&
                earningData?.byPersonalIncorporation?.statistics
                  ?.totalIncorporated < 3 ? (
                  <p className=" s14 museo-sans-500">
                    <FormattedMessage id="dashboard.graph.consultant.second.pop.two" />
                  </p>
                ) : null}
                {earningData?.byPersonalIncorporation?.statistics
                  ?.incorporatedWithSales === 2 &&
                earningData?.byPersonalIncorporation?.statistics
                  ?.totalIncorporated > 2 ? (
                  <p className=" s14 museo-sans-500">
                    <FormattedMessage id="dashboard.graph.consultant.second.pop.three" />
                  </p>
                ) : null}
                {earningData?.byPersonalIncorporation?.statistics
                  ?.incorporatedWithSales > 0 &&
                earningData?.byPersonalIncorporation?.statistics
                  ?.incorporatedWithSales !== 2 &&
                earningData?.byPersonalIncorporation?.statistics
                  ?.totalIncorporated > 2 &&
                earningData?.byPersonalIncorporation?.statistics
                  ?.incorporatedWithSales !==
                  earningData?.byPersonalIncorporation?.statistics
                    ?.totalIncorporated ? (
                  <p className=" s14 museo-sans-500">
                    <FormattedMessage id="dashboard.graph.consultant.second.pop.three" />
                  </p>
                ) : null}
                {earningData?.byPersonalIncorporation?.statistics
                  ?.incorporatedWithSales > 2 &&
                earningData?.byPersonalIncorporation?.statistics
                  ?.incorporatedWithSales ===
                  earningData?.byPersonalIncorporation?.statistics
                    ?.totalIncorporated ? (
                  <p className=" s14 museo-sans-500">
                    <FormattedMessage id="dashboard.graph.consultant.second.pop.four" />
                  </p>
                ) : null}
              </div>
            )}
          </>
        )}
      </div>
      {toggle && (
        <div className=" rounded-2 d-flex flex-column justify-content-center gap-2 f9GrayBg py-3 w-100 museo-sans-500 s12 ">
          <div className=" graph-listing px-3 text-uppercase  justify-content-between grayColor">
            <p>
              <FormattedMessage id="dashboard.graph.dropmenu.one" />
            </p>
            <p className="graph-listing-row">
              <FormattedMessage id="dashboard.graph.dropmenu.two" />
            </p>
            <p className="graph-listing-row">
              <FormattedMessage id="dashboard.graph.dropmenu.three" />
            </p>
            <p className="graph-listing-row">
              <FormattedMessage id="dashboard.graph.dropmenu.four" />
            </p>
          </div>
          <hr className=" my-2 mx-0"></hr>
          <div className=" graph-listing px-3 dm-sans-medium  justify-content-between grayColor ">
            <p className=" museo-sans-500 blackColor">
              <FormattedMessage id="dashboard.graph.dropmenu.sublevel.four" />
            </p>
            <p className="graph-listing-row">
              {formatAmount(
                earningData?.byPersonalIncorporation?.totalSale,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
            </p>
            <p className="graph-listing-row">
              %{earningData?.byPersonalIncorporation?.percentage}
            </p>
            <p className="graph-listing-row blackColor">
              {formatAmount(
                earningData?.byPersonalIncorporation?.amount,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export function GraphListRow({ data, index, level, gap, cultureInfo }) {
  const biggerThan1000 = useMediaPredicate("(min-width: 1025px)");
  const [toggle, setToggle] = useState(false);

  const switchColor = (index) => {
    if (gap) {
      return "brightGrayBg";
    }
    switch (index) {
      case 0:
        return "labelOneBg";
      case 1:
        return "labelTwoBg";
      case 2:
        return "labelThreeBg";
      case 3:
        return "labelFourBg";
    }
  };

  const switchArray = (index) => {
    switch (index) {
      case 0:
        return data.byPersonalSales;
      case 1:
        return data.byPersonalGroup;
      case 2:
        return data.byDescendant;
      case 3:
        return data.bondExecutiveDirector;
    }
  };

  const switchTitle = (index, level) => {
    switch (index) {
      case 0:
        return "Venta Personal";
      case 1:
        return "Grupo Personal";
      case 2:
        return "Descendencia";
      case 3:
        return "BONO DIRECTORA";
    }
  };

  const switchDropDownMenu = (index) => {
    switch (index) {
      case 0:
        return (
          toggle && (
            <div className=" rounded-2 d-flex flex-column justify-content-center gap-2 f9GrayBg py-3 w-100 museo-sans-500 s12 ">
              <div className=" graph-listing px-3 text-uppercase  align-items-center grayColor">
                <p>
                  <FormattedMessage id="dashboard.graph.dropmenu.one" />
                </p>
                <p className="graph-listing-row">
                  <FormattedMessage id="dashboard.graph.dropmenu.two" />
                </p>
                <p className="graph-listing-row">
                  <FormattedMessage id="dashboard.graph.dropmenu.three" />
                </p>
                <p className="graph-listing-row">
                  <FormattedMessage id="dashboard.graph.dropmenu.four" />
                </p>
              </div>
              <hr className=" my-2 mx-0"></hr>
              <div className=" graph-listing px-3 dm-sans-medium  align-items-center grayColor ">
                <p className=" museo-sans-500  blackColor">
                  <FormattedMessage id="dashboard.graph.dropmenu.sublevel.one" />
                </p>
                <p className={`graph-listing-row  `}>
                  {formatAmount(
                    switchArray(index).totalSale,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
                <p className="graph-listing-row">
                  %{" "}
                  {switchArray(
                    index
                  ).instantEarnings.percentage.toLocaleString()}
                </p>
                <p className={` graph-listing-row ${switchArray(index).instantEarnings.amount < 0 ? "lightRedColor" : ""}    `}>
                  {formatAmount(
                    switchArray(index).instantEarnings.amount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
              </div>
              <hr className=" my-2 mx-0"></hr>
              <div className=" graph-listing px-3 dm-sans-medium  align-items-center grayColor ">
                <p className=" museo-sans-500  blackColor">
                  <FormattedMessage id="dashboard.graph.dropmenu.sublevel.two" />
                </p>
                <p className=" graph-listing-row ">
                  {formatAmount(
                    switchArray(index).totalSale,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
                <p className=" graph-listing-row ">
                  %{" "}
                  {switchArray(
                    index
                  ).personalSaleBond.percentage.toLocaleString()}
                </p>
                <p className={` graph-listing-row  `}>
                  {formatAmount(
                    switchArray(index).personalSaleBond.amount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
              </div>
              {switchArray(index)?.leaderGroupBond?.percentage && (
                <>
                  <hr className=" my-2 mx-0"></hr>
                  <div className=" graph-listing px-3 dm-sans-medium  align-items-center grayColor ">
                    <p className=" museo-sans-500  blackColor">
                      <FormattedMessage id="dashboard.graph.dropmenu.sublevel.three" />
                    </p>
                    <p className=" graph-listing-row ">
                      {formatAmount(
                        switchArray(index).totalSale,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                    <p className=" graph-listing-row ">
                      %{" "}
                      {switchArray(
                        index
                      )?.leaderGroupBond?.percentage?.toLocaleString()}
                    </p>
                    <p className={` graph-listing-row  `}>
                      {formatAmount(
                        switchArray(index)?.leaderGroupBond?.amount,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                  </div>
                </>
              )}
            </div>
          )
        );
      case 1:
        return (
          toggle && (
            <div className=" rounded-2 d-flex flex-column justify-content-center gap-2 f9GrayBg py-3 w-100 museo-sans-500 s12 ">
              <div className=" graph-listing px-3 text-uppercase  align-items-center grayColor">
                <p>
                  <FormattedMessage id="dashboard.graph.dropmenu.one" />
                </p>
                <p className="graph-listing-row">
                  <FormattedMessage id="dashboard.graph.dropmenu.two" />
                </p>
                <p className="graph-listing-row">
                  <FormattedMessage id="dashboard.graph.dropmenu.three" />
                </p>
                <p className="graph-listing-row">
                  <FormattedMessage id="dashboard.graph.dropmenu.four" />
                </p>
              </div>
              <hr className=" my-2 mx-0"></hr>
              <div className=" graph-listing px-3 dm-sans-medium  align-items-center grayColor ">
                <p className=" museo-sans-500  blackColor">
                  <FormattedMessage id="dashboard.graph.dropmenu.sublevel.four" />
                </p>
                <p className=" graph-listing-row ">
                  {formatAmount(
                    switchArray(index).personalIncorporated.totalSale,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
                <p className=" graph-listing-row ">
                  %{" "}
                  {switchArray(
                    index
                  ).personalIncorporated.percentage.toLocaleString()}
                </p>
                <p className=" graph-listing-row  blackColor">
                  {formatAmount(
                    switchArray(index).personalIncorporated.amount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
              </div>

              <hr className=" my-2 mx-0"></hr>
              <div className=" graph-listing px-3 dm-sans-medium  align-items-center grayColor ">
                <p className=" museo-sans-500  blackColor">
                  <FormattedMessage id="dashboard.graph.dropmenu.sublevel.five" />
                </p>
                <p className=" graph-listing-row ">
                  {formatAmount(
                    switchArray(index)?.restOfGroup?.totalSale,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
                <p className=" graph-listing-row ">
                  %{" "}
                  {switchArray(index)?.restOfGroup?.percentage?.toLocaleString()
                    ? switchArray(index)?.restOfGroup?.percentage
                    : 0}
                </p>
                <p className=" graph-listing-row  blackColor">
                  {formatAmount(
                    switchArray(index)?.restOfGroup?.amount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
              </div>
              {switchArray(index)?.restOfGroup?.percentage && <></>}
            </div>
          )
        );
      case 2:
        return (
          toggle && (
            <div className=" rounded-2 d-flex flex-column justify-content-center gap-2 f9GrayBg py-3 w-100 museo-sans-500 s12 ">
              <div className=" graph-listing px-3 text-uppercase  align-items-center grayColor">
                <p>
                  <FormattedMessage id="dashboard.graph.dropmenu.one" />
                </p>
                <p className="graph-listing-row">
                  <FormattedMessage id="dashboard.graph.dropmenu.two" />
                </p>
                <p className="graph-listing-row">
                  <FormattedMessage id="dashboard.graph.dropmenu.three" />
                </p>
                <p className="graph-listing-row">
                  <FormattedMessage id="dashboard.graph.dropmenu.four" />
                </p>
              </div>
              <hr className=" my-2 mx-0"></hr>
              <div className=" graph-listing px-3 dm-sans-medium  align-items-center grayColor ">
                <p className=" museo-sans-500  blackColor">
                  {biggerThan1000 ? (
                    <FormattedMessage id="dashboard.graph.dropmenu.sublevel.six" />
                  ) : (
                    <FormattedMessage id="dashboard.graph.dropmenu.sublevel.six.short" />
                  )}
                </p>
                <p className=" graph-listing-row ">
                  {formatAmount(
                    switchArray(index).firstGeneration.personalIncorporated
                      .totalSale,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
                <p className=" graph-listing-row ">
                  %{" "}
                  {switchArray(
                    index
                  ).firstGeneration.personalIncorporated.percentage.toLocaleString()}
                </p>
                <p className=" graph-listing-row blackColor">
                  {formatAmount(
                    switchArray(index).firstGeneration.personalIncorporated
                      .amount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
              </div>
              <hr className=" my-2 mx-0"></hr>
              <div className=" graph-listing px-3 dm-sans-medium  align-items-center grayColor ">
                <p className=" museo-sans-500  blackColor">
                  <FormattedMessage id="dashboard.graph.dropmenu.sublevel.seven" />
                </p>
                <p className=" graph-listing-row ">
                  {formatAmount(
                    switchArray(index).firstGeneration.restOfGroup.totalSale,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
                <p className=" graph-listing-row ">
                  %{" "}
                  {switchArray(
                    index
                  ).firstGeneration.restOfGroup.percentage.toLocaleString()}
                </p>
                <p className=" graph-listing-row  blackColor">
                  {formatAmount(
                    switchArray(index).firstGeneration.restOfGroup.amount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
              </div>
              {level > 3 && (
                <>
                  <hr className=" my-2 mx-0"></hr>
                  <div className=" graph-listing px-3 dm-sans-medium  align-items-center grayColor ">
                    <p className=" museo-sans-500  blackColor">
                      <FormattedMessage id="dashboard.graph.dropmenu.sublevel.eight" />
                    </p>
                    <p className=" graph-listing-row ">
                      {formatAmount(
                        switchArray(index).secondGeneration.totalSale,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                    <p className=" graph-listing-row ">
                      %{" "}
                      {switchArray(
                        index
                      ).secondGeneration.percentage.toLocaleString()}
                    </p>
                    <p className=" graph-listing-row blackColor">
                      {formatAmount(
                        switchArray(index).secondGeneration.amount,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                  </div>
                </>
              )}
              {level > 4 && (
                <>
                  <hr className=" my-2 mx-0"></hr>
                  <div className=" graph-listing px-3 dm-sans-medium  align-items-center grayColor ">
                    <p className=" museo-sans-500  blackColor">
                      {biggerThan1000 ? (
                        <FormattedMessage id="dashboard.graph.dropmenu.sublevel.nine" />
                      ) : (
                        <FormattedMessage id="dashboard.graph.dropmenu.sublevel.nine.short" />
                      )}
                    </p>
                    <p className=" graph-listing-row ">
                      {formatAmount(
                        switchArray(index).thirdGeneration.totalSale,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                    <p className=" graph-listing-row ">
                      %{" "}
                      {switchArray(
                        index
                      ).thirdGeneration.percentage.toLocaleString()}
                    </p>
                    <p className=" graph-listing-row blackColor">
                      {formatAmount(
                        switchArray(index).thirdGeneration.amount,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                  </div>
                </>
              )}
            </div>
          )
        );
      case 3:
        return (
          toggle && (
            <div
              style={{ background: "#F9F9FD" }}
              className=" rounded-2 position-relative p-3"
            >
              <div
                style={{ background: "#ECF0FA" }}
                className=" position-relative rounded-2 p-3 gap-3 d-flex blueColor"
              >
                <SVGMedal />
                <p className=" s14 museo-sans-500">
                  <FormattedMessage id="dashboard.graph.label.bonus" />
                </p>
              </div>
            </div>
          )
        );
    }
  };

  const swithcSubtitle = (index) => {
    switch (index) {
      case 0:
        return (
          <p className=" s12 museo-sans-500  ">
            {biggerThan1000 ? (
              <FormattedMessage id="dashboard.graph.sublabel.one" />
            ) : (
              <FormattedMessage id="dashboard.graph.sublabel.one.short" />
            )}{" "}
            {switchArray(index)?.earningPercentage}%
          </p>
        );
      case 1:
        return (
          <p className=" s12 museo-sans-500 ">
            {biggerThan1000 ? (
              <FormattedMessage id="dashboard.graph.sublabel.two" />
            ) : (
              <FormattedMessage id="dashboard.graph.sublabel.two.short" />
            )}
          </p>
        );
      case 2:
        return (
          <p className=" s12 museo-sans-500 ">
            {biggerThan1000 ? (
              <FormattedMessage id="dashboard.graph.sublabel.three" />
            ) : (
              <FormattedMessage id="dashboard.graph.sublabel.three.short" />
            )}
          </p>
        );
    }
  };

  const onClick = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <div
        onClick={onClick}
        style={{ height: "18px" }}
        className=" mb-3 d-flex align-items-center pointer justify-content-between "
      >
        <div
          style={{ minWidth: "fit-content" }}
          className=" gap-3 align-items-center d-flex"
        >
          <div
            style={{ width: "10px", height: "10px" }}
            className={`${switchColor(index)} rounded-circle `}
          ></div>
          <div className=" align-items-center gap-1 d-flex">
            {toggle ? (
              <p className=" s12 museo-sans-700 text-uppercase">
                {switchTitle(index, level)}
              </p>
            ) : (
              <p className=" s12 museo-sans-500 text-uppercase">
                {switchTitle(index, level)}
              </p>
            )}

            {index === 3 && (
              <>
                {switchArray(index)?.status !== "completed" && (
                  <p className=" s12 museo-sans-700  text-uppercase lightGreenColor ">
                    <FormattedMessage id="new" />
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="w-100 px-3 d-flex justify-content-end">
          {toggle && swithcSubtitle(index)}
        </div>
        <div
          style={{ minWidth: "fit-content" }}
          className="d-flex align-items-center "
        >
          {gap ? (
            <p className=" grayColor museo-sans-500 pe-2 s14">
              <FormattedMessage id="pending" />
            </p>
          ) : (
            <p className={` dm-sans-medium ${switchArray(index)?.amount < 0 ? "lightRedColor" : ""}  s14 `}>
              {switchArray(index)?.amount === 0
                ? "Sin ventas"
                : formatAmount(
                    switchArray(index)?.amount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
            </p>
          )}
          {gap ? (
            <div className="control--svg-filler-gray  grayColor pointer">
              <SVGChevArrowDown />
            </div>
          ) : (
            <>
              {toggle && (
                <div className=" px-1 pe-0 control--svg-filler-sky pointer">
                  <SVGChevArrowUp />
                </div>
              )}
              {!toggle && (
                <div className=" px-1 pe-0 control--svg-filler-sky pointer">
                  <SVGChevArrowDown />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {switchDropDownMenu(index)}
    </>
  );
}
