import { useParams } from "react-router-dom";
import ClientListFormOneColumnInput from "../elements/ClientListFormOneColumnInput"
import ClientListFormSelect from "../elements/ClientListFormSelect"


export default function Colombia({ watch,streetError, onChangeValidate, ...props }) {
  const params = useParams()
  const isUpdate = !!(params?.operation == 'update')


  return(
    <>
      <ClientListFormOneColumnInput
        placeholder={"Ej.: Calle y Número"}
        label={"Dirección (opcional)"}
        name="street"
        onChangeValidate={onChangeValidate}
        isError={streetError}
        {...props}
      />
      <div className=" px-3 pe-0">

      <ClientListFormSelect
        placeholder={"Seleccionar un Departamento"}
        label={"Departamento (opcional)"}
        isUpdate={isUpdate}
        father={true}
        name="department"
        {...props}
        watch={watch}
        level={1}
      />
      </div>
      <ClientListFormSelect
        placeholder={"Seleccionar una Ciudad"}
        label={"Ciudad (opcional)"}
        isUpdate={isUpdate}
        name="city"
        father={watch("department")}
        {...props}
        watch={watch}
        level={2}
        last
      />
      <div className=" px-3 pe-0">
        <ClientListFormOneColumnInput
          placeholder={"Barrio (opcional)"}
          label={"Barrio"}
          name="neighborhood"
          watch={watch}
          {...props}
        />

      </div>
    </>
  )
}