import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { formatAmount } from "../../../common/Share/FormatCurrency";

import { ReactComponent as SVGChevronRight } from "../../../common/svgs/ic-chevron-right-md.svg";
import { ReactComponent as SVGGrayCheck } from "../../SVGs/GapCheck.svg";
import { ReactComponent as SVGGreen } from "../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as SVGFailed } from "../../../FirstSteps/Presentation/SVGs/NoAlcanzado/ic-x-circle-sm.svg";
import { ReactComponent as SVGIncomplete } from "../../../FirstSteps/Presentation/SVGs/Pending/ic-item-pending-sm-filled-yellow.svg";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import OriginRoadModalOthers from "../components/OriginRoadModalOthers";
import { fetchCAO } from "../../Framework/redux/OriginRoadSideEffect";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";
import OriginRoadHelpMessage from "../components/OriginRoadHelpMessage";
import ShareErrorHandlerServices from "../../../common/Share/ShareErrorHandlerServices";

export default function OriginRoadDashboardShowcase({ S1 }) {
  const country = localStorage.getItem("country").toUpperCase();
  const dispatch = useDispatch();
  const gender = useSelector((state) => state.userInfo?.userInfo?.gender);
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const earningData = useSelector((state) => state.earning.data);
  const CAOData = useSelector((state) => state.CAOData.cao);

  const onStartUp = async () => {
    await dispatch(fetchCAO());
  };

  const onLinkClick = () => {
    const clickMe = document.getElementById("cao-link");
    clickMe.click();
  };

  const objectLink = {
    under: `https://appcontents-pub.s3.amazonaws.com/${country}/Bases_Viaje_Suiza_2025_Consultoras_${country}.pdf`,
    above: `https://appcontents-pub.s3.amazonaws.com/${country}/Bases_Viaje_Suiza_2025_Lideres_${country}.pdf`,
  };

  const objectLevelDescription = {
    0: <FormattedMessage id={`level.one.${gender == "F" ? "f" : "m"}`} />,
    1: <FormattedMessage id={`level.one.${gender == "F" ? "f" : "m"}`} />,
    2: <FormattedMessage id="level.two" />,
    3: <FormattedMessage id="level.three" />,
    4: <FormattedMessage id={`level.four.${gender == "F" ? "f" : "m"}`} />,
    5: <FormattedMessage id="level.five" />,
    6: <FormattedMessage id={`level.six.${gender == "F" ? "f" : "m"}`} />,
    7: <FormattedMessage id={`level.seven.${gender == "F" ? "f" : "m"}`} />,
    8: <FormattedMessage id={`level.eight.${gender == "F" ? "f" : "m"}`} />,
  };

  useEffect(() => {
    onStartUp();
  }, []);

  return (
    <>
      {(CAOData?.data?.caoStage != "OFF") && (
        <div className="w-100 museo-sans-500">
          <ShareModalStructure minHeight={"618px"} id={"otherGoals"}>
            <OriginRoadModalOthers
              roleDescription={objectLevelDescription[CAOData?.data?.caoLevel]}
              level={CAOData?.data?.caoLevel}
              data={CAOData?.data}
              cultureInfo={cultureInfo}
            />
          </ShareModalStructure>
          <div className="d-flex mb-3 align-items-center gap-3">
            <p className=" s20">
              <FormattedMessage id="origin.road.title" />
            </p>

            <p
              onClick={onLinkClick}
              className=" museo-sans-700 s14 pointer skyColor"
            >
              <FormattedMessage id="origin.road.link" />
            </p>
            <a
              target="_blank"
              id="cao-link"
              href={`${
                CAOData.data?.caoLevel < 2
                  ? objectLink["under"]
                  : objectLink["above"]
              }`}
              className=" invisible"
            ></a>
          </div>
          <div className=" s14 d-flex bg-white flex-column rounded-16 tabs-shadow p-4">
            {CAOData.loader ? (
              <SkeletonBody />
            ) : (
              <>
                {
                  CAOData?.error ?
                  <div className=" w-100 h-100 d-flex justify-content-center align-items-center" >
                      <div className="" >
                        <ShareErrorHandlerServices service={onStartUp} />
                      </div>
                    </div>
                    :
                    <OriginRoadBody
                      CAOData={CAOData}
                      cultureInfo={cultureInfo}
                      scoreData={earningData}
                      S1={S1}
                    />

                }
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function OriginRoadBody({ CAOData, cultureInfo, scoreData, S1 }) {

  

  const onControlStatus = (status) => {
    const arrayStatusSVGs = {
      REACHED: <SVGGreen />,
      AWARDED: <></>,
      ONGOING: <SVGIncomplete />,
      RISK_SALE: <SVGFailed />,
      RISK_GOALS: <SVGFailed />,
      REVERSING: <SVGFailed />,
      GAME_OVER: <SVGFailed />,
      GAP: <SVGGrayCheck />,
    };

    return arrayStatusSVGs[status];
  };

  const showComponentIf = {
    QUALIFICATION:
      CAOData?.data?.caoState !== "GAME_OVER" &&
      CAOData?.data?.caoState !== "AWARDED",
    MAINTENANCE:
       ((CAOData.data?.caoState != null || CAOData.data?.caoLevel < 2) && !CAOData.data?.processedGoals < 7) ,
    ENDING: false,
  };

  const showTitleIf = {
    QUALIFICATION:
      CAOData?.data?.caoState !== "GAME_OVER" &&
      CAOData?.data?.caoState !== "AWARDED",
    MAINTENANCE: false,
    ENDING: false,
  };

  const showGoalsReached = {
    QUALIFICATION: true,
    MAINTENANCE: CAOData?.data?.caoState != "GAME_OVER",
    ENDING: CAOData?.data?.goalStatus == "completed",
  };

  const titleId = {
    QUALIFICATION: "origin.road.actual.goal",
    MAINTENANCE:
      CAOData?.data?.caoLevel == 1
        ? "origin.road.maintenance.title.1"
        : "origin.road.maintenance.title.2",
    ENDING: "origin.road.actual.goal",
  };

  const objectColor = {
    completed: "greenColor",
    warning: "darkYellowColor",
    risk: "lightRedColor",
    normal: "",
    gap: "",
  };

  return (
    <>
      {showTitleIf[CAOData?.data?.caoStage] && (
        <div className="d-flex flex-column ">
          <>
            <div className="d-flex gap-2">
              <p className=" museo-sans-700 s16">
                <FormattedMessage id={titleId[CAOData?.data?.caoStage]} />: {CAOData?.data?.currentGoal}
              </p>
              <div style={{ width: "16px" }} className=" svg-force-size">
                {onControlStatus(CAOData.data?.caoState)}
              </div>
            </div>
            {showComponentIf[CAOData?.data?.caoStage] && (
              <div>
                <hr className="my-3" />
              </div>
            )}
          </>
        </div>
      )}
      <div>
        {showComponentIf[CAOData?.data?.caoStage] && (
          <OriginRoadProgress
            scoreData={scoreData?.byPersonalSales?.personalSaleBond.statistics}
            CAOData={CAOData}
            cultureInfo={cultureInfo}
            S1={S1}
            objectColor={objectColor}
            JSXSVG={
              (CAOData?.data?.caoLevel > 1 || CAOData?.data?.caoState == "GAME_OVER"  ) ? (
                <div
                  style={{ width: "16px", height: "16px", top: -2 }}
                  className=" position-relative svg-force-size"
                >
                  {onControlStatus(CAOData.data?.caoState)}
                </div>
              ) : null
            }
          />
        )}
        <OriginRoadHelpMessage CAOData={CAOData?.data} />
      </div>
      {
       (showGoalsReached[CAOData?.data?.caoStage] && (showComponentIf[CAOData?.data?.caoStage] || CAOData?.data?.signs?.length > 0 )) &&
          <div>
            <hr className="my-3" />
          </div>

      }
      {showGoalsReached[CAOData?.data?.caoStage] && (
        <div className=" w-100">
          <div
            data-bs-toggle="modal"
            data-bs-target="#otherGoals"
            className="donutHeaderBg pointer w-100 h-100 d-flex justify-content-between rounded-8 p-3 "
          >
            <p className="d-flex gap-1">
              <FormattedMessage id="origin.road.reached" />
              <div
                style={{ width: "16px", height: "16px", top: -2 }}
                className=" position-relative svg-force-size"
              >
                {CAOData.data?.goalStatus == "completed" && <SVGGreen />}
                {CAOData.data?.goalStatus != null &&
                  CAOData.data?.goalStatus != "completed" && <SVGFailed />}
              </div>
            </p>
            <div className="d-flex dm-sans-medium ">
              <div className="d-flex gap-1">
                <p>{CAOData.data?.processedGoals}</p>
                <p className=" grayColor">/{CAOData.data?.totalGoals}</p>
              </div>
              <div
                style={{ height: "16px", top: -2 }}
                className=" position-relative svg-force-size"
              >
                <SVGChevronRight />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export function OriginRoadProgress({
  CAOData,
  cultureInfo,
  S1,
  objectColor,
  scoreData,
  JSXSVG,
}) {
  const objectShowData = {
    QUALIFICATION: {
      titleId:
        CAOData.data?.caoLevel < 2
          ? "origin.road.task.one.b"
          : "origin.road.task.one.a",
      actualAmount: CAOData.data?.incrementalSale?.actualAmount,
      totalAmount: CAOData.data?.incrementalSale?.totalAmount,
      status: CAOData.data?.incrementalSale?.status,
      showData: true,
    },
    MAINTENANCE: {
      titleId:
        CAOData.data?.caoLevel < 2
          ? "origin.road.maintenance.title.1"
          : "origin.road.maintenance.title.2",
      actualAmount: scoreData?.amount,
      totalAmount: scoreData?.totalAmount,
      status: scoreData?.status,
      showData: (CAOData?.data?.caoState !== "GAME_OVER" && CAOData.data?.caoLevel < 2) ? true : false,
    }
  };

  const getData = objectShowData[CAOData?.data?.caoStage];

  return (
    <div
      style={{ marginBottom: "20px" }}
      className="w-100 d-flex justify-content-between gap-5"
    >
      <div className="w-100 gap-2">
        <div
          className={` d-flex justify-content-between ${
            S1 ? " " : "flex-column"
          } `}
        >
          <p className="d-flex gap-2">
            <FormattedMessage id={getData?.titleId} />
            {JSXSVG}
          </p>
          {getData?.showData && (
            <div
              className={` d-flex gap-1 dm-sans-medium ${
                S1 ? " " : " justify-content-end "
              } `}
            >
              <p>
                {formatAmount(
                  getData?.actualAmount ?? 0,
                  cultureInfo?.languageCode,
                  cultureInfo?.defaultCurrencyCode
                )}
              </p>
              <p className=" grayColor">
                /{" "}
                {formatAmount(
                  getData?.totalAmount ?? 0,
                  cultureInfo?.languageCode,
                  cultureInfo?.defaultCurrencyCode
                )}
              </p>
            </div>
          )}
        </div>
        {getData?.showData && (
          <div className="w-100">
            <ProgressBarParams
              height={12}
              status={getData?.status}
              gap={getData?.status === "gap"}
              incompleted={getData?.status !== "completed"}
              completed={(getData?.actualAmount / getData?.totalAmount) * 100}
            />
          </div>
        )}
      </div>
      {CAOData?.data?.caoStages == "QUALIFICATION" ? (
        <div style={{ width: "2px" }} className=" brightGrayBg  ">
          <p className=" invisible">.</p>
        </div>
      ) : (
        <div style={{ width: "2px" }} className="  ">
          <p className=" invisible">.</p>
        </div>
      )}
      {CAOData?.data?.caoStages == "QUALIFICATION" ? (
        <div className="w-100 gap-2">
          <div className="d-flex justify-content-between">
            <p>
              {CAOData.data?.caoLevel < 2 ? (
                <FormattedMessage id="origin.road.task.two.b" />
              ) : (
                <FormattedMessage id="origin.road.task.two.a" />
              )}
            </p>
            <div className="d-flex gap-1 dm-sans-medium ">
              <p
                className={` dm-sans-bold ${
                  objectColor[CAOData.data?.incrementalActives?.status]
                } `}
              >
                {CAOData.data?.incrementalActives?.actual}
              </p>
              <p className=" grayColor">
                / {CAOData.data?.incrementalActives?.need}
              </p>
            </div>
          </div>
          <div className="w-100 invisible">
            <ProgressBarParams
              height={12}
              status={"warning"}
              gap={true}
              incompleted={true}
              completed={(100000 / 200000) * 100}
            />
          </div>
        </div>
      ) : (
        <div className="w-100 invisible">
          <p>.</p>
        </div>
      )}
    </div>
  );
}

export function SkeletonBody() {
  return (
    <div>
      <div className="w-25 lightui1-shimmer rounded-16 ">
        <p className="invisible">Meta Actual:</p>
      </div>
      <hr className=" my-3" />
      <div className="gap-2">
        <div className=" d-flex justify-content-between gap-5 ">
          <div className=" d-flex flex-column gap-2 w-100">
            <div className="d-flex justify-content-between w-100">
              <div
                style={{ height: "14px" }}
                className="lightui1-shimmer rounded-16 w-50"
              >
                <p className="invisible">Meta Actual:</p>
              </div>
              <div className="w-50"></div>
              <div
                style={{ height: "14px" }}
                className="lightui1-shimmer rounded-16 w-50"
              >
                <p className="invisible">Meta Actual:</p>
              </div>
            </div>
            <div
              style={{ height: "14px" }}
              className="lightui1-shimmer rounded-16 w-100"
            >
              <p className="invisible">Meta Actual:</p>
            </div>
          </div>
          <div style={{ width: "2px" }} className=" brightGrayBg  ">
            <p className=" invisible">.</p>
          </div>
          <div className=" w-100">
            <div className="d-flex justify-content-between w-100">
              <div
                style={{ height: "14px" }}
                className="lightui1-shimmer rounded-16 w-50"
              >
                <p className="invisible">Meta Actual:</p>
              </div>
              <div className="w-50"></div>
              <div
                style={{ height: "14px" }}
                className="lightui1-shimmer rounded-16 w-50"
              >
                <p className="invisible">Meta Actual:</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className=" my-3" />
      <div className=" lightui1-shimmer w-100 rounded-16 ">
        <p className="invisible">Meta Actual:</p>
      </div>
    </div>
  );
}
