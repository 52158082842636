import { useParams } from "react-router-dom";
import ClientListFormOneColumnInput from "../elements/ClientListFormOneColumnInput"
import ClientListFormSelect from "../elements/ClientListFormSelect"

export default function Chile({ watch, streetError, onChangeValidate, ...props }) {
  const params = useParams()
  const isUpdate = !!(params?.operation == 'update')
  

  return(
    <>
      <ClientListFormOneColumnInput
        placeholder={"Ej.: Calle y Número"}
        label={"Domicilio (opcional)"}
        name="street"
        onChangeValidate={onChangeValidate}
        isError={streetError}
        {...props}
      />
      <div className=" px-3 pe-0">

        <ClientListFormSelect
          label={"Región (opcional)"}
          placeholder={"Seleccionar una Región"}
          name="region"
          father={true}
          isUpdate={isUpdate}
          watch={watch}
          {...props}
          level={1}
        />
      </div>
      <ClientListFormSelect
        label={"Provincia / Ciudad (opcional)"}
        placeholder={"Seleccionar una Provincia / Ciudad"}
        name="province"
        isUpdate={isUpdate}
        father={watch('region')}
        {...props}
        watch={watch}
        level={2}
      />
      <div className=" px-3 pe-0">
        <ClientListFormSelect
          label={"Comuna (opcional)"}
          placeholder={"Seleccionar una Comuna"}
          name="comune"
          isUpdate={isUpdate}
          father={watch('province')}
          {...props}
          watch={watch}
          level={3}
          last
        />
      </div>

    </>
  )
}