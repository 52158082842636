import ClientListFormOneColumnInput from "./elements/ClientListFormOneColumnInput";
import InputPhoneNumber from "./elements/ClientListInputPhoneNumber";
import { useFormContext } from "react-hook-form";
import SharePlaceholderPhone from "../../../../common/Share/SharePlaceholderPhone";

export default function ContactData({
  isValidateError,
  onChangeValidate,
  emailError,
  clientDetails,
}) {
  const { register, watch } = useFormContext();
  const country = localStorage.getItem("country");
  return (
    <div style={{ gap: "24px" }} className="d-flex flex-wrap my-4 pb-3">
      <InputPhoneNumber
        placeholderOne={"Ej.: 011"}
        placeholderTwo={SharePlaceholderPhone()}
        label={"Celular"}
        name1="cellArea"
        name2="cellNumber"
        register={register}
        onChangeValidate={onChangeValidate}
        isValidateError={isValidateError}
        valueFromPut={
          clientDetails?.phoneNumbers
            ? clientDetails?.phoneNumbers[0]?.number
            : ""
        }
      />
      {country !== "uy" || country !== "pa" ? (
        <>
          <div className=" px-3 pe-0">
            <InputPhoneNumber
              placeholderOne="Ej.: 011"
              placeholderTwo={SharePlaceholderPhone()}
              onChangeValidate={onChangeValidate}
              label={"Telefono alternativo (opcional)"}
              name1="areaOtherNumber"
              name2="otherNumber"
              register={register}
            />
          </div>
          <div className=" position-relative" > 
          <ClientListFormOneColumnInput
            placeholder={"Ej.: maria@gmail.com"}
            label={"Email (opcional)"}
            type="email"
            name="email"
            maxLength={60}
            isError={emailError}
            register={register}
            onChangeValidate={onChangeValidate}
            watch={watch}
          />

          </div>
        </>
      ) : (
        <div className=" px-3 pe-0">
          <ClientListFormOneColumnInput
            placeholder={"Ej.: maria@gmail.com"}
            label={"Email (opcional)"}
            type="email"
            name="email"
            maxLength={60}
            onChangeValidate={onChangeValidate}
            register={register}
            watch={watch}
          />
        </div>
      )}
    </div>
  );
}
