import { ReactComponent as SVGBloqued } from "../../../FirstSteps/Presentation/SVGs/Bloqueado/ic-item-desbloq-sm-filled-gray.svg";
import { ReactComponent as SVGReached } from "../../../FirstSteps/Presentation/SVGs/ReachedCompletedGreen/ic-check-circle-sm.svg";
import { ReactComponent as SVGFailed } from "../../../FirstSteps/Presentation/SVGs/NoAlcanzado/ic-x-circle-sm.svg";
import { ReactComponent as SVGCompleted } from "../../../FirstSteps/Presentation/SVGs/Completado/ic-item-gift-sm.svg";
import { ReactComponent as SVGpending } from "../../../FirstSteps/Presentation/SVGs/Pending/ic-item-pending-sm-filled-yellow.svg";
import { ReactComponent as SVGUnlocked } from "../../../FirstSteps/Presentation/SVGs/Bloqueado/ic-item-desbloq-sm-filled-yellow.svg";
import { ReactComponent as ArrowRight } from "../../../common/svgs/ic-chevron-right-md.svg";
import { useDispatch, useSelector } from "react-redux";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import moment from "moment-timezone";
import { fetchFirstStepsDetails } from "../../../FirstSteps/Framework/redux/FirstStepsAllActions";
import getTimeZoneByCountry from "../../../common/Share/formatDateMoment";
import { FormattedMessage } from "react-intl";

export default function DashboardFirstStepsRow({
  elm,
  biggerThan1000,
  short,
  incorp,
}) {
  const dispatch = useDispatch();

  const switchSvg = (status) => {
    switch (status) {
      case "FAILED":
        return <SVGFailed />;
      case "PENDING":
        return <SVGpending />;
      case "BLOCKED":
        return <SVGBloqued />;
      case "COMPLETED":
        return <SVGCompleted />;
      case "REACHED":
        return <SVGReached />;
      case "UNLOCKED":
        return <SVGUnlocked />;
    }
  };

  const checkDate = (limitDate) => {
    const countryCode = localStorage.getItem("country");
    const timeZone = getTimeZoneByCountry(countryCode);
    const limitDateMoment = moment.tz(limitDate, timeZone);
    const actualDateMoment = moment.tz(timeZone);
    const differenceInDays = limitDateMoment.diff(actualDateMoment, "days");
    return differenceInDays;
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return ''; // Maneja el caso en que no haya fecha
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0'); // Asegura 2 dígitos
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses empiezan en 0
    const year = String(date.getFullYear()).slice(2); // Solo últimos 2 dígitos del año
    return `${day}/${month}/${year}`;
};

  const redeemWeekMessage = (
    redeemEndDate1,
    redeemEndDate2,
    redeemEndDate3,
    data2,
    data3,
    paso1,
    paso2,
    paso3
  ) => {
    let logroNumber = 1;
    let limitRedeemDate = 1;
    if (paso1 == "REACHED") {
      limitRedeemDate = 10;
    } else {
      if (paso1 == "PENDING" || paso1 == "FAILED") {
        logroNumber = 1;
        limitRedeemDate = checkDate(redeemEndDate1);
      }
    }
    if (paso2 == "REACHED") {
      limitRedeemDate = 10;
    } else {
      if (paso2 == "PENDING" || paso2 == "FAILED") {
        logroNumber = 2;
        limitRedeemDate = checkDate(redeemEndDate2);
      }
    }
    if (paso3 == "REACHED") {
      limitRedeemDate = 10;
    } else {
      if (paso3 == "PENDING" || paso3 == "FAILED") {
        logroNumber = 3;
        limitRedeemDate = checkDate(redeemEndDate3);
      }
    }

    if (limitRedeemDate === 1) {
      return (
          <p className="museo-sans-500 s14 " style={{ color: "#F06E69" }}>
            {limitRedeemDate} días restantes para Logro {logroNumber}
          </p>
      );
    } else if (limitRedeemDate == 3 || limitRedeemDate == 2) {
      return (
          <p className="museo-sans-500 s14 " style={{ color: "#DCB632" }}>
            {limitRedeemDate} días restantes para Logro {logroNumber}
          </p>
      );
    } else {
      return (
        <>
        {
          data2 == data3 ?
          <p className="museo-sans-500 s14 grayColor" >
            <FormattedMessage id="week.end.of.week.date" /> {formatDate(elm?.roadmapEndDate)}
          </p>
          :
        <p className="museo-sans-500 s14 grayColor">
          <FormattedMessage id="header.week" /> {data2}{" "}
          <FormattedMessage id="of" /> {data3}
        </p>

        }
        </>
      );
    }
  };

  const fetchDetalisData = (data, incorp) => {
    let auxData = {
      data: { data: data, incorp: incorp },
      incorp: incorp,
    };
    dispatch(fetchFirstStepsDetails(auxData));
    const controller = document.getElementById(
      `row-modal-controller-${data?.clientId}`
    );
    controller.click();
  };

  const splitStringName = (string) => {
    if (!biggerThan1000) {
      let aux = string.slice(0, 26);
      aux = aux + "...";
      return aux;
    } else {
      return string;
    }
  };

  return (
      <div
        className="pointer w-100"
        onClick={() => fetchDetalisData(elm, incorp)}
      >
        <div
          id={`row-modal-controller-${elm?.clientId}`}
          data-bs-toggle="modal"
          data-bs-target="#modalFirstStepsLiderDirector"
          className={`w-100 pointer  ${
            short == true
              ? "table--header--1eraGenListShort"
              : "table--header--1eraGenList"
          } px-0 align-items-center w-100 hidden-y-scroll  } `}
          style={{
            paddingBottom: short ? "0px" : null,
            paddingTop: short ? "24px" : null,
          }}
        >
            <>
              <div
                style={{ marginLeft: short ? "15px" : null }}
                className="d-flex flex-row gap-1 align-items-center museo-sans-700 s14"
              >
                {elm?.isPersonalIncorporated ? (
                  <div
                    style={{
                      height: "38px",
                      width: "38px",
                      top: -0,
                      border: "solid 1.5px #456ECE",
                    }}
                    className=" position-relative d-flex justify-content-center align-items-center rounded-circle "
                  >
                    <ImageCircleHandler
                      size={"14px"}
                      width={"32px"}
                      height={"32px"}
                      url={elm?.profileResume?.image}
                      firstName={elm?.profileResume?.firstName}
                      lastName={elm?.profileResume?.lastName}
                      name={elm?.profileResume?.name}
                      top={2}
                    />
                  </div>
                ) : (
                    <ImageCircleHandler
                      size={"14px"}
                      width={"32px"}
                      height={"32px"}
                      url={elm?.profileResume?.image}
                      firstName={elm?.profileResume?.firstName}
                      lastName={elm?.profileResume?.lastName}
                      name={elm?.profileResume?.name}
                      top={2}
                    />
                )}

                <p>{splitStringName(elm?.profileResume?.name)}</p>
              </div>
              {redeemWeekMessage(
                elm?.steps[0]?.stepDetail?.limitDate,
                elm?.steps[1]?.stepDetail?.limitDate,
                elm?.steps[2]?.stepDetail?.limitDate,
                elm?.currentWeek,
                elm?.totalWeeks,
                elm?.steps[0]?.stepDetail?.state,
                elm?.steps[1]?.stepDetail?.state,
                elm?.steps[2]?.stepDetail?.state
              )}

              <div className="d-flex flex-row gap-1 align-items-center gap-2">
                {switchSvg(elm?.steps[0]?.stepDetail?.state)}
                {switchSvg(elm?.steps[1]?.stepDetail?.state)}
                {switchSvg(elm?.steps[2]?.stepDetail?.state)}
              </div>
              <span className="d-flex " style={{ justifyContent: "right" }}>
                <button style={{ border: "0px" }} className="bg-white">
                  <ArrowRight />
                </button>
              </span>
            </>
        </div>
      </div>
  );
}