import { ReactComponent as ArrowRight } from "../../../common/svgs/arrow-right.svg";
import { ReactComponent as SVGCards } from "../img/cards.svg";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import "../pages/clientList.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearAllCards } from "../../Framework/redux/ClientListAction";
import { useState } from "react";
import { useEffect } from "react";

export default function ClienteListTableBody({
  contactsResult,
  onCardClick,
  navigate,
  clientDetails,
  withLastStyle,
}) {
  return (
    <>
      {contactsResult.map((person, index) => {
        return (
          <ClientListTableRow
            clientDetails={clientDetails}
            navigate={navigate}
            onCardClick={onCardClick}
            data={person}
            isLast={index == contactsResult?.length - 1}
          />
        );
      })}
    </>
  );
}

export function ClientListTableRow({
  data: person,
  isLast,
  onCardClick,
  navigate,
  clientDetails,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);

  const controlOnClickAction = (person) => {
    dispatch(clearAllCards());
    let doSelectedCard =
      location.pathname.includes("dashboard/edbs/form/client/list") ||
      location.pathname.includes("/edbs/details") ||
      location.pathname.includes("/client/list");
    if (doSelectedCard) {
      onCardClick(person.contactId);
      setIsSelected(true);
    } else {
      navigate(
        `/dashboard/clientlist/details/${person.contactId}${
          person?.has_cards ? "/cards" : ""
        }`
      );
    }
  };

  useEffect(() => {
    setIsSelected(clientDetails?.contactId === person?.contactId);
  }, [clientDetails]);

  return (
    <div
      id={`row-${person?.contactId}`}
      style={{ paddingTop: "20px", paddingBottom: isLast ? "56px" : "20px" }}
      className={` position-relative w-100 d-flex align-items-center museo-sans-500 s14 justify-content-between pe-4 pointer ${
        isLast ? "" : "border-bottom-1"
      }`}
      onClick={() => controlOnClickAction(person)}
    >
      <div
        style={{ height: "48px", width: "101%", left: "-8px", zIndex: 0 }}
        className={` position-absolute  ${
          isSelected ? "client-selected-row " : ""
        } `}
      ></div>
      <div
        style={{ zIndex: 1 }}
        className=" position-relative d-flex align-items-center"
      >
        <ImageCircleHandler
          firstName={person?.firstName?.trim()}
          lastName={person?.lastName?.trim()}
          width={32}
          fontFamily={"museo-sans-500"}
          height={32}
          size={14}
          styles={{
            marginRight: 22,
            backgroundColor: "#456ECE",
            color: "white",
          }}
        />
        <p className=" museo-sans-700 s14">
          {`${person.firstName} ${person.lastName}`}
        </p>
      </div>
      <div
        style={{ zIndex: 1 }}
        className=" position-relative d-flex align-tems-center"
      >
        {person?.has_cards && (
          <div className="me-3">
            <SVGCards />
          </div>
        )}
        <ArrowRight width={18} height={18} />
      </div>
    </div>
  );
}
