import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { ReactComponent as WhatsappIcon } from "../../../common/svgs/ic-whatsapp-md.svg";
import { ReactComponent as EmailIcon } from "../../../common/svgs/ic-mail-md.svg";

import ShareComponentInput from "../../../common/Share/ShareComponentInput";
import ShareInputSelect from "../../../common/Share/ShareInputSelect";
import EDBsFormRouter from "../components/EDBsFormRouter";
import { body } from "../../Framework/EDBsFormBody";
import {
  fetchTopics,
  postNewEdb,
  putEdb,
} from "../../Framework/redux/EDBFormSideEffect";
import ClientListTerritoryPerCountry from "../../../ClientList/Presentation/components/ClientListTerritoryPerCountry";
import { getClientDetails } from "../../../ClientList/Framework/redux/ClientListSideEffect";
import {
  activateError,
  activatePhoneError,
  deactivateError,
} from "../../Framework/redux/EDBFormActions";

import { ReactComponent as SVGRedCross } from "../img/redcross.svg";
import { ReactComponent as RightArrow } from "../../../common/svgs/ic-arrow-right-sm.svg";
import { ModalClipboardContext } from "../../../common/routes/routes";
import { clearClientDetailsForm } from "../../Framework/redux/EDBsActions";
import SharePlaceholderPhone from "../../../common/Share/SharePlaceholderPhone";
import EDBsFormVirtual from "../components/EDBsFormVirtual";
import EDBsFormPresent from "../components/EDBsFormPresent";
import SharePhoneMaxLengthByCountry from "../../../common/Share/SharePhoneMaxLengthByCountry";
import { fetchEDBDetails } from "../../Framework/redux/EDBDetailsSideEffect";

export default function EDBsForm() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const auxiliarForm = { body };
  const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
  const country = localStorage.getItem("country");
  const [formBody, setFormBody] = useState(
    auxFormBody ? auxFormBody : auxiliarForm
  );
  const [formType, setFormType] = useState("virtual");
  const [formTopics, setFormTopics] = useState([]);
  const [togglerSelectForm, setTogglerSelectForm] = useState(false);
  const data = useSelector((state) => state.edbFormData.topics.value);
  const profileUser = useSelector((state) => state.userInfo);
  const clientDetails = useSelector(
    (state) => state.clientListData.clientDetails?.data
  );
  const errorToggler = useSelector(
    (state) => state.edbFormData.errorTogger.value
  );
  const errorPhoneToggler = useSelector(
    (state) => state.edbFormData.errorPhoneToggler.value
  );

  const numbers = {
    ar: "+549",
    cl: "+56",
    co: "+57",
    cr: "+506",
    mx: "+52",
    pa: "+507",
    pe: "+51",
    uy: "+598",
  };

  const isPut =
    location.pathname.includes("present/") ||
    location.pathname.includes("virtual/");

  const { setModalTexts } = useContext(ModalClipboardContext);

  const checkRouteManually = () => {
    const auxiliar = JSON.parse(localStorage.getItem("formBody"));
    if (!auxiliar) {
      localStorage.removeItem("formBodyType");
    }

    const existFormType = localStorage.getItem("formBodyType");

    if (!existFormType && location.pathname?.includes("virtual")) {
      localStorage.setItem("formBodyType", "virtual");
      setFormType("virtual");
    }
    if (!existFormType && location.pathname?.includes("present")) {
      localStorage.setItem("formBodyType", "present");
      setFormType("present");
    }
    if (existFormType) {
      setFormType(existFormType);
    }
  };

  const onStartUp = async () => {
    await dispatch(fetchTopics());
    dispatch(deactivateError());
    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));

    checkRouteManually();

    if (auxFormBody?.hostData?.hostId && !clientDetails) {
      await dispatch(getClientDetails(auxFormBody.hostData.hostId));
      return;
    }
    if (!clientDetails) {
      if (
        location.pathname.includes("/type/present/") ||
        location.pathname.includes("/type/virtual/")
      ) {
        history(-1);
      }
      return "";
    }
    let hostDataPhoneUpdate = {
      phone: "",
      areaCode: "",
      type: "",
    };
    if (
      Array.isArray(clientDetails?.phoneNumbers) &&
      clientDetails?.phoneNumbers.length > 0
    ) {
      hostDataPhoneUpdate = {
        phone: clientDetails?.phoneNumbers[0]?.number,
        area: "",
        type: clientDetails?.phoneNumbers[0]?.type || "",
      };
    }

    const updatedHostData = {
      hostId: clientDetails?.contactId,
      hostName:
        clientDetails?.firstName || "" + " " + clientDetails?.lastName || "",
      hostEmail: findEmail(clientDetails?.socialNetworks),
      phone: hostDataPhoneUpdate,
    };

    const updatedFormBody = {
      ...auxFormBody,
      hostData: updatedHostData,
      clientPhone: profileUser.userInfo?.phone || "",
      contactEdbPhone: {
        type: "",
        area: auxFormBody?.contactEdbPhone?.area || "",
        phone: auxFormBody?.contactEdbPhone?.phone || "",
      },
    };

    localStorage.setItem("formBody", JSON.stringify(updatedFormBody));
  };

  const onChangeByName = (value, name) => {
    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
    if (!value || !name) {
      return "";
    }
    let newFormBody = { ...auxFormBody };
    if (name === "time") {
      setFormBody({
        ...formBody,
        body: {
          ...formBody.body,
          time: value.value == "time" ? null : value.value,
        },
      });
    }
    if (name === "contactEdbArea") {
      let object = {
        phone: auxFormBody?.contactEdbPhone?.phone || "",
        area: value.value,
        type: " ",
      };
      newFormBody["contactEdbPhone"] = object;
    }
    if (name === "contactEdbPhone") {
      let object = {
        phone: value?.value,
        area: auxFormBody?.contactEdbPhone?.area || "",
        type: " ",
      };
      newFormBody["contactEdbPhone"] = object;
    }
    if (name !== "contactEdbPhone" && name !== "contactEdbArea") {
      newFormBody[name] = value?.value;
    }
    let { time } = newFormBody;
    if (time == "time") {
      time = null;
    }
    newFormBody = { ...newFormBody, time: time };
    setFormBody(newFormBody);
    localStorage.setItem("formBody", JSON.stringify(newFormBody));
    dispatch(deactivateError());
  };

  const onChangeByNameInput = (name, any, value) => {
    onChangeByName({ value: value }, name);
  };

  const toNewClient = () => {
    history("/dashboard/edbs/form/client/add");
  };

  const toListClients = () => {
    history("/dashboard/edbs/form/client/list");
  };

  const birthdayControl = (stringBirthday) => {
    if (!stringBirthday) {
      return "";
    }
    let splitValues = stringBirthday.split("-");
    let daySplitted = splitValues[0];
    let monthSplitted = splitValues[1];
    daySplitted = +daySplitted;
    monthSplitted = +monthSplitted;
    return (
      <>
        {" "}
        {monthSplitted} <FormattedMessage id="of" />{" "}
        <FormattedMessage id={`month.${daySplitted}`} />{" "}
      </>
    );
  };

  const onDateChange = (evt) => {
    const { name, value } = evt.target;
    const [year, month, day] = value.split("-");
    if (year?.length > 4) {
      let savedDate = auxFormBody?.date;
      evt.target.value = savedDate;
      return;
    } else {
      onChangeByName({ value: value }, name);
    }
  };

  const findEmail = (socialNetworks) => {
    const id = "email";
    if (!socialNetworks) {
      return "";
    }
    const email = socialNetworks.find((item) => item.id === id);
    return email?.value;
  };

  const onSubmitClick = async () => {
    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
    const edbType =
      localStorage.getItem("formBodyType") == "virtual"
        ? "virtual"
        : "presencial";
    const isPut =
      location.pathname.includes("present/") ||
      location.pathname.includes("virtual/");

    const updateLocalStorage = { ...auxFormBody, edbType: edbType };

    let result = await dispatch(
      isPut
        ? putEdb({ form: updateLocalStorage, id: params.edbId })
        : postNewEdb(updateLocalStorage)
    );
    if (result?.error?.message == "Rejected" || !result?.payload) {
      dispatch(activateError());
      if (result?.payload.includes("ingresado no es")) {
        dispatch(activatePhoneError());
      }
    }
    if (result?.payload?.code == 200) {
      const edbNewId = result?.payload?.id;
      const fetchData = isPut ? null : await dispatch(fetchEDBDetails(edbNewId));
      if ((!isPut && fetchData?.payload?.shareText )) {
        localStorage.setItem("edbNewId", edbNewId);
        localStorage.setItem("edbNewType", edbType);
        // await dispatch(fetchEDBDetails(idEdb));
      }
      const modalData = {
        title: isPut ? (
          <FormattedMessage id="edb.form.modal.pop.up.message.mod.edb.title" />
        ) : (
          <FormattedMessage id="edb.form.modal.pop.up.message.create.edb.title" />
        ),
        text: isPut ? (
          <FormattedMessage id="edb.form.modal.pop.up.message.mod.edb.p" />
        ) : (
          <FormattedMessage id="edb.form.modal.pop.up.message.create.edb.p" />
        ),
        status: "confirm",
        show: false,
        bottom: 0,
      };
      dispatch(clearClientDetailsForm());
      localStorage.removeItem("formBody");
      localStorage.removeItem("formBodyType");
      if (isPut) {
        const direction = localStorage.getItem("formBodyType");
        if (direction == "abierto") {
          history(`/dashboard/edbs/details/open/${params.edbId}`);
        } else {
          history(`/dashboard/edbs/details/dated/${params.edbId}`);
        }
      } else {
        history("/dashboard/edbs");
      }

      if ((!isPut && fetchData?.payload?.shareText)) {
        // const edbNewId = result?.payload?.id;
        // localStorage.setItem("edbNewId", edbNewId);
      } else {
        setModalTexts(modalData)
      }

    }
  };

  const removeCountryCode = (phoneNumber) => {
    if (!phoneNumber) {
      return "";
    }
    for (const code in numbers) {
      const countryCode = numbers[code];
      const countryCodeWithoutPlus = countryCode.slice(1);
      phoneNumber = phoneNumber.replace(/\D/g, "");
      if (phoneNumber.startsWith(countryCodeWithoutPlus)) {
        return phoneNumber.slice(countryCodeWithoutPlus.length);
      }
    }
    return phoneNumber.replace(/\D/g, "");
  };

  const changeRouteManually = (result) => {
    setFormType(result);
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow.toISOString().split("T")[0]; // Convertir a formato YYYY-MM-DD
  };

  const onHostViewProfile = (clientId) => {
    history(`/dashboard/clientlist/details/${clientId}`);
  };

  const onChangeSelectList = () => {
    setTogglerSelectForm(!togglerSelectForm);
  };

  const optionsHours = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const time = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
      optionsHours.push({
        label: time,
        name: time,
        value: time,
      });
    }
  }

  useEffect(() => {
    onStartUp();
  }, []);

  useEffect(() => {
    let aux = [];

    if (Array.isArray(data?.topics) && data?.topics?.length > 0) {
      data?.topics.forEach((element) => {
        aux.push({
          label: element.title,
          name: element.title,
          value: element.id,
        });
      });
    }

    setFormTopics(aux);
  }, [data]);

  useEffect(() => {
    dispatch(deactivateError());
  }, [location] )

  return (
    <div className=" museo-sans-500 s14 container--padding container--padding--card-holder bg-white">
      <div className=" ">
        <div className="w-100 mt-5">
          <div style={{ width: "766px" }} className="">
            <div>
              <p className=" museo-sans-500 s20">
                <FormattedMessage id="Detalles de evento" />
              </p>
              <hr className="" />
              <div className="mt-3 pt-3 w-100 d-flex my-3 gap-3">
                <div className="d-flex flex-column w-50 gap-2">
                  <p>
                    <FormattedMessage id="date" />
                  </p>
                  <div className="no-calendar no-calendar-s17 ">
                    <input
                      autoComplete="off"
                      name={"date"}
                      onClick={() => onChangeSelectList()} 
                      value={formBody?.date ?? auxFormBody?.date}
                      onChange={(evt) => onDateChange(evt)}
                      id={"date-end"}
                      data-date-format="DDMMYYYY"
                      className={` pointer w-100 dm-sans-medium s14 p-2 ${
                        !formBody?.date && !auxFormBody?.date && errorToggler
                          ? "input-error-border"
                          : "input-border"
                      } `}
                      type="date"
                      min={getTomorrowDate()}
                    />
                  </div>
                  <ErrorMessage
                    errorToggler={
                      !formBody?.date && !auxFormBody?.date && errorToggler
                    }
                  />
                </div>
                <div className="w-50 d-flex flex-column gap-2">
                  <p className=" s14">
                    <FormattedMessage id="edb.form.details.time.start" />
                  </p>
                  <ShareInputSelect
                    errorToggler={
                      !formBody?.time && !auxFormBody?.time && errorToggler
                    }
                    onChange={onChangeByName}
                    name={"time"}
                    value={formBody.time ?? auxFormBody?.time}
                    options={optionsHours}
                    width={"100%"}
                    onChangeSelectList={onChangeSelectList}
                  />
                  <ErrorMessage
                    errorToggler={
                      !formBody?.time && !auxFormBody?.time && errorToggler
                    }
                  />
                </div>
              </div>
              <div className="w-100 d-flex my-3 gap-3">
                <div className="w-50 d-flex flex-column gap-2 ">
                  <p className=" s14 museo-sans-500">
                    <FormattedMessage id="edb.form.details.subject.date" />
                  </p>
                  <ShareInputSelect
                    errorToggler={!auxFormBody?.topicId && errorToggler}
                    onChange={onChangeByName}
                    options={formTopics}
                    name="topicId"
                    value={
                      formTopics.find(
                        (topic) => topic.value === auxFormBody?.topicId
                      )?.label || null
                    }
                    defualtNotAnOption={true}
                    width={"100%"}
                    onChangeSelectList={onChangeSelectList}
                  />
                  <ErrorMessage
                    errorToggler={!auxFormBody?.topicId && errorToggler}
                  />
                </div>
                <div className="w-50"></div>
              </div>
            </div>
            <div>
              <p className=" museo-sans-500 s14">
                <FormattedMessage id="edb.form.type.title" />
              </p>
              <EDBsFormRouter
                type={formType}
                changeRoute={changeRouteManually}
                isPut={isPut}
              />
            </div>
            {formType == "virtual" ? <EDBsFormVirtual /> : <EDBsFormPresent togglerSelectForm={togglerSelectForm} />}
            <div className=" mt-4">
              <p className=" museo-sans-500 s20">
                <FormattedMessage id="edb.form.details.host" />
              </p>
              <hr />
              {clientDetails && (
                <div className="my-3">
                  <div className=" bg-white p-4 shadow rounded-8 ">
                    <div className="d-flex gap-2 " >
                    <p>
                      {clientDetails.firstName + " " + clientDetails.lastName}
                    </p>
                    <div onClick={() => onHostViewProfile(clientDetails?.contactId)} className="d-flex pointer gap-1 skyColor s14 museo-sans-700 " >
                      <FormattedMessage
                          id="descendants.user.info.modal.view.profile"
                          defaultMessage="Ver perfil"
                        />{" "}
                        <div style={{height: "16px", width: "16px"}} className=" svg-force-size " >
                        <RightArrow />
                          </div>

                      </div>

                      </div>
                    <div className="d-flex w-100 my-3 gap-3 museo-sans-500 ">
                      {clientDetails?.phoneNumbers?.length > 0 && (
                        <div className="w-100">
                          <p
                            style={{ letterSpacing: "0.72px" }}
                            className=" grayColor text-uppercase "
                          >
                            <FormattedMessage id="TELÉFONO" />
                          </p>
                          <div className="w-100 d-flex justify-content-between">
                            <div className="d-flex gap-1">
                              <p>{clientDetails?.phoneNumbers[0]?.number}</p>
                            </div>
                            <a
                              style={{ top: -4 }}
                              className="position-relative"
                              href={`https://wa.me/${clientDetails?.phoneNumbers[0]?.number}`}
                              target="_blank"
                            >
                              <WhatsappIcon />
                            </a>
                          </div>
                        </div>
                      )}
                      {findEmail(clientDetails.socialNetworks) && (
                        <div className="w-100">
                          <p
                            style={{ letterSpacing: "0.72px" }}
                            className=" grayColor text-uppercase "
                          >
                            <FormattedMessage id="settings.user.data.email" />
                          </p>
                          <div className="d-flex justify-content-between w-100">
                            <p>{findEmail(clientDetails.socialNetworks)}</p>
                            <a
                              style={{ top: -4 }}
                              className="position-relative"
                              href={`mailto:${findEmail(
                                clientDetails.socialNetworks
                              )}`}
                              target="_blank"
                            >
                              <EmailIcon />
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="d-flex w-100 my-3 gap-3 museo-sans-500 ">
                      {clientDetails?.birthday && (
                        <div className=" museo-sans-500 w-100">
                          <p
                            className=" text-uppercase grayColor s12"
                            style={{ letterSpacing: "0.72px" }}
                          >
                            <FormattedMessage id="birthday" />
                          </p>
                          <p className=" s16">
                            {birthdayControl(clientDetails?.birthday)}
                          </p>
                        </div>
                      )}
                      <div className="w-100">
                        {ClientListTerritoryPerCountry(
                          clientDetails?.addressInfo,
                          true
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              {!isPut && (
                <>
                  <div className="w-100 d-flex my-3 pt-3 gap-3">
                    <button
                      onClick={toListClients}
                      style={{ width: "288px", height: "41px" }}
                      className="d-flex skyColor bg-transparent skyBorder rounded-8 justify-content-center align-items-center s14 "
                    >
                      {clientDetails
                        ? "Cambiar Anfitrión/a"
                        : "Buscar Anfitrión/a"}
                    </button>
                  </div>
                  <div>
                    <p className=" grayColor">
                      <FormattedMessage id="edb.form.host.to.select" />
                    </p>
                    <p onClick={toNewClient} className=" museo-sans-700 skyColor pointer">
                      <FormattedMessage id="edb.form.host.create.contact" />
                    </p>
                    <div></div>
                  </div>
                </>
              )}
              {!clientDetails && errorToggler && (
                <div
                  className=" d-flex w-100 mt-4 pb-3 gap-3 position-relative align-items-center  "
                >
                  <ErrorMessage errorToggler={errorToggler} messageId={"edb.form.client.details.error.message"} />
                </div>
              )}
            </div>
            <div className="mt-4">
              <p className=" museo-sans-500 s20">
                <FormattedMessage id="edb.form.contact.title" />
              </p>
              <hr />
                <>
                  <p className=" grayColor">
                    <FormattedMessage id="edb.form.contact.subtext.one" />{" "}
                    {profileUser?.userInfo?.phone}.
                  </p>
                  <p className="grayColor">
                    <FormattedMessage id="edb.form.host.phone.updateable.text" />
                  </p>
                  <p
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    className="pointer museo-sans-700 skyColor"
                  >
                    <FormattedMessage id="edb.form.host.phone.updateable.button" />
                  </p>
                </>
              {country != "pe" && country != "uy" ? (
                <>
                  <div className="w-50 my-3 gap-2 d-flex">
                    <ShareComponentInput
                      name={"contactEdbPhone"}
                      maxLength={SharePhoneMaxLengthByCountry()}
                      label={
                        <FormattedMessage id="edb.form.contact.phone.optional" />
                      }
                      type={"m"}
                      value={removeCountryCode(
                        auxFormBody?.contactEdbPhone?.phone
                      )}
                      onChange={onChangeByNameInput}
                      placeholder={SharePlaceholderPhone()}
                      errorToggler={errorPhoneToggler}
                    />
                  </div>
                  <div className={`${errorPhoneToggler ? "mb-3" : ""}`}>
                    <ErrorMessage
                      messageId={"client.form.validation.phone"}
                      errorToggler={errorPhoneToggler}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="my-3"></div>
                </>
              )}
            </div>

            <button
              id="on-submit-confirm"
              onClick={() => onSubmitClick()}
              style={{ width: "288px", height: "41px" }}
              className="d-flex mb-5 skyBg text-white border-0 rounded-8 justify-content-center align-items-center s14 "
            >
              {isPut ? (
                <FormattedMessage id="edb.form.save.encounter" />
              ) : (
                <FormattedMessage id="edb.form.create.encounter" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ErrorMessage({ errorToggler, messageId }) {
  return (
    <div className="d-flex gap-2">
      {errorToggler && (
        <>
          <SVGRedCross />
          <p className=" redColor s12">
            <FormattedMessage id={messageId || "input.error.required"} />
          </p>
        </>
      )}
    </div>
  );
}
