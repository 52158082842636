import React from "react";
import ReturnToAndTitle from "../../../Share/ReturnToAndTitle";
import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../pages/Configuration.css";

import { ReactComponent as UploadIconSmallSVG } from "../../../svgs/upload-icon-sm.svg";
import { ReactComponent as CameraIconSmallSVG } from "../../../svgs/camera-icon-sm.svg";

import { updateProfilePicture } from "../../../redux/commonSideEffects";
import { fetchUserProfile } from "../../../../Login/Framework/redux/loginSideEffects";
import ContinueButton from "./ContinueButton";
import LoginCamera from "../../../../Login/Presentation/components/LoginCamera";
import LoginProfileModal from "../../../../Login/Presentation/components/LoginProfileModal";
import Loader from "../../../Share/Loader";

import introduction from "../../../img/ethicsCodeImages/imagenescde-01.png";

import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";

import { useMediaPredicate } from "react-media-hook";
import { FormattedMessage } from "react-intl";
import ReturnButton from "../../../Share/ReturnButton";
import { useState } from "react";

export default function UserDataChangeProfilePicture({ travelFunction }) {
  const dispatch = useDispatch();

  const biggerThan992 = useMediaPredicate("(min-width: 992px)");

  const profilePictureRef = useRef();
  const secondImageInputRef = useRef();

  const [showCamera, setShowCamera] = useState(false);
  const [capture, setCapture] = useState(null);
  const [uploadedImage, setUploadedImage] = useState({
    myFile: "",
  });
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [componentKey, setComponentKey] = useState(Math.random());

  const currentImage = useSelector((state) => state.userInfo.userInfo.image);

  const handleCapture = (screenshot) => {
    setCapture(screenshot);
    if (screenshot !== null) {
      selectUploadPhoto();
      setComponentKey(Math.random());
    }
  };

  function selectUploadPhoto() {
    setShowCamera(false);
    setIsButtonDisabled(true);
  }

  function selectCamera() {
    setShowCamera(true);
    setIsButtonDisabled(false);
  }

  async function handleUpload() {
    setUpdatingProfile(true);
    let username = localStorage.getItem("clientId");
    const PP = profilePictureRef.current;
    const base64Image = PP.getImageAsDataUrl();
    const resizedBase64 = await resizeBase64Image(base64Image, 600, 600);
    await dispatch(updateProfilePicture(resizedBase64));
    await dispatch(fetchUserProfile(username));
    setUpdatingProfile(false);
    travelFunction(1.1);
  }

  async function resizeBase64Image(base64Image, width, height) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = base64Image;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        const resizedBase64 = canvas.toDataURL("image/jpeg", 0.8);
        resolve(resizedBase64);
      };
    });
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    e.preventDefault()
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setUploadedImage({ ...uploadedImage, myFile: base64 })
    setCapture(null)
    setComponentKey(Math.random())
  };

  return (
    <div className="d-flex flex-column h-100">
      <ReturnButton travelFunction={travelFunction} travelFunctionValue={1.1} />
      <div>
        <p className="museo-sans-700 s20 mb-2">
          <FormattedMessage id="settings.user.data.profile.picture.title" />
        </p>
        <p className="museo-sans-500 s16 config-picture-change-text">
          <FormattedMessage id="settings.user.data.profile.picture.description" />
        </p>
      </div>
      <div
        className={`${biggerThan992 ? "w-50" : "w-100"
          } pe-3 pt-3 h-50 profile-picture-updater-padding-none profile-picture-updater-svg-fill upload-profile-picture-disable-trash-can upload-profile-picture-white-circle upload-profile-picture-grey-background upload-profile-picture-input-width upload-profile-picture-background-width`}

      >
        {!showCamera ? (
          <ProfilePicture
            frameSize={195}
            cropSize={195}
            key={componentKey}
            minImageSize={150}
            maxImageSize={1400}
            image={
              capture
                ? capture
                : uploadedImage.myFile
                  ? uploadedImage.myFile
                  : currentImage
            }
            ref={profilePictureRef}
            useHelper={true}
            debug={true}
            frameFormat={"circle"}
            messages={{
              DEFAULT: (
                <FormattedMessage
                  id="profile.editor.default"
                  defaultMessage="Click here to upload your image."
                />
              ),
              DRAGOVER: (
                <FormattedMessage
                  id="profile.editor.dragover"
                  defaultMessage="Drop the file."
                />
              ),
              INVALID_FILE_TYPE: (
                <FormattedMessage
                  id="profile.editor.type"
                  defaultMessage="Image type file allowed only."
                />
              ),
              INVALID_IMAGE_SIZE: (
                <FormattedMessage
                  id="profile.editor.image"
                  defaultMessage="The file size must be above 350px and below or equal to 600px."
                />
              ),
            }}

          />
        ) : (
          <LoginCamera
            capture={capture}
            handleCapture={handleCapture}
            handleShowCamera={setShowCamera}
            styles={{
              videoContainerWidth: "w-75",
              screenshotContainerWidth: "w-75",
              inConfig: true,
            }}
          />
        )}
      </div>
      <div
        className="d-flex justify-content-between mt-auto"
        style={{ marginBottom: "16px" }}
      >
        {isButtonDisabled ? (
          <button
            className="settings-picture-buttons-width align-self-center museo-sans-700 s14 skyColor skyBorder button-hover-light-text button-hover-svg-light-stroke button-bootstrap-radius button-padding bronceBg svg-margin-left"
            onClick={() => secondImageInputRef.current.click()}
          >
            <input
              className="d-none"
              ref={secondImageInputRef}
              type="file"
              label="Image"
              name="myFile"
              accept=".jpg"
              onChange={(e) => handleFileUpload(e)}
            />
            <FormattedMessage
              id="buttons.upload.photo"
              defaultMessage="Upload photo"
            />
            <UploadIconSmallSVG />
          </button>
        ) : (
          <button
            className="settings-picture-buttons-width align-self-center museo-sans-700 s14 skyColor skyBorder button-hover-light-text button-hover-svg-light-stroke button-bootstrap-radius button-padding bronceBg svg-margin-left"
            onClick={() => selectUploadPhoto()}
          >
            <FormattedMessage
              id="buttons.select.photo"
              defaultMessage="Select photo"
            />
            <UploadIconSmallSVG />
          </button>
        )}
        {isButtonDisabled ? (
          <button
            className="settings-picture-buttons-width align-self-center museo-sans-700 s14 skyColor skyBorder button-hover-light-text button-hover-svg-light-stroke button-bootstrap-radius button-padding bronceBg svg-margin-left"
            onClick={() => selectCamera()}
          >
            <FormattedMessage
              id="buttons.take.photo.again"
              defaultMessage="Take photo"
            />
            <CameraIconSmallSVG />
          </button>
        ) : (
          <button
            className="settings-picture-buttons-width align-self-center museo-sans-700 s14 button-bootstrap-radius button-padding bronceBg svg-margin-left button-disabled-border hover-pointer-none"
            disabled
          >
            <FormattedMessage
              id="buttons.take.photo.again"
              defaultMessage="Take photo"
            />
            <CameraIconSmallSVG />
          </button>
        )}
        {showCamera ? (
          <button
            className="settings-picture-buttons-width align-self-center museo-sans-700 s14 brightGrayBg whiteColor button-border-none button-bootstrap-radius button-padding hover-pointer-none"
            disabled
          >
            <Loader loading={updatingProfile} />
            <FormattedMessage
              id="buttons.save.changes"
              defaultMessage="Save changes"
            />
          </button>
        ) : (
          <button
            className="settings-picture-buttons-width align-self-center museo-sans-700 s14 skyBg whiteColor skyBorder button-hover-light button-border-none button-bootstrap-radius button-padding"
            onClick={() => handleUpload()}
          >
            <Loader loading={updatingProfile} />
            <FormattedMessage
              id="buttons.save.changes"
              defaultMessage="Save changes"
            />
          </button>
        )}
      </div>
    </div>
  );
}
