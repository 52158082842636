import { useMediaPredicate } from "react-media-hook";
import { FormattedMessage } from "react-intl";
import Webcam from "react-webcam";

export default function LoginCamera({capture, handleCapture, handleShowCamera, styles}) {
  const biggerThan1290 = useMediaPredicate("(min-width: 1370px)");

  const videoConstraints = {
    facingMode: "user",
  };


  return (
    <>
      {capture ? (
        <div className={`d-flex flex-column text-center w600 ${styles.screenshotContainerWidth ? styles.screenshotContainerWidth : "w-50"}`}>
          <img src={capture} alt="you"></img>
            <button
            className="btnBlueBorders museo-sans-700 s14 skyColor skyBorder py-1 w600 mt-3 button-hover-light-text bronceBg"
            onClick={() => {
            handleCapture(null);
            }}
            >
                <FormattedMessage id="buttons.capture.another"/>
            </button>
        </div>
      ) : (
        <div className={`d-flex flex-column ${styles.videoContainerWidth ? styles.videoContainerWidth : "w-50"} video-padding-bottom`}>
          {biggerThan1290 ? (
            <Webcam
              audio={false}
              height={styles.inConfig ? 150 : 320}
              forceScreenshotSourceSize={true}
              screenshotQuality={1}
              screenshotFormat="jpg"
              videoConstraints={videoConstraints}
              maxS
            >
              {({ getScreenshot }) => (
                    <button
                        className="btnBlueBorders museo-sans-700 s14 skyColor skyBorder py-1 w600 mt-2 button-hover-light-text bronceBg"
                        onClick={() => {
                        const imageSrc = getScreenshot();
                        handleCapture(imageSrc);
                        }}
                    >
                        <FormattedMessage id="buttons.capture" defaultMessage="Capture photo"/>
                    </button>
              )}
            </Webcam>
          ) : (
            <Webcam
              audio={false}
              height={styles.inConfig ? 150 : 200}
              forceScreenshotSourceSize={true}
              screenshotQuality={1}
              screenshotFormat="jpeg/png"
              videoConstraints={videoConstraints}
            >
              {({ getScreenshot }) => (
                <button
                  className="btnBlueBorders museo-sans-700 s14 skyColor skyBorder py-1 w600 mt-2 button-hover-light-text bronceBg"
                  onClick={() => {
                    const imageSrc = getScreenshot();
                    handleCapture(imageSrc);
                  }}
                >
                 <FormattedMessage id="buttons.capture" defaultMessage="Capture photo" />
                </button>
              )}
            </Webcam>
          )}
        </div>
      )}
    </>
  );
}
