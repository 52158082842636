import { useEffect, useState, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PersonalData,
  ContactData,
  addressCountrySelector,
  ClientListFormTextArea,
} from "../components/formsections";
import WarningModal from "../components/modals/WarningModal";
import ButtonSubmit from "../components/ButtonSubmit";

import {
  getClientDetails,
  getTerritoryByLevel,
  postClient,
  putClient,
} from "../../Framework/redux/ClientListSideEffect";
import { defaultState, bodyAdapter, schemas } from "../helpers";
import FormSkeleton from "../components/skeletons/FormDetailSkeleton";
import { openPostUpdate } from "../../Framework/redux/ClientListAction";
import { clientDetailsForm } from "../../../EDBs/Framework/redux/EDBsActions";
import { ModalClipboardContext } from "../../../common/routes/routes";
import SharePhoneLibrary from "../../../common/Share/SharePhoneLibrary";

export default function NewClientListForm() {
  const country = localStorage.getItem("country");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const methods = useForm({
    resolver: yupResolver(schemas[country]),
    defaultValues: {
      province: '',
      locality: '',
      postalCode: '',
      state: '',
      municipality: '',
      region: '',
      comune: '',
      department: '',
      city: '',
      signs: '',
      canton: '',
      district: '',
      township: '',
    },
  })
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors }
  } = methods
  const {
    data: clientDetails,
    loader,
    success,
  } = useSelector((state) => state.clientListData.clientDetails);
  const [isValidateError, setIsValidateError] = useState(false);
  const [formulationError, setFormulationError] = useState({
    firstName: false,
    lastName: false,
  });
  const { setModalTexts } = useContext(ModalClipboardContext);
  const [emailError, setEmailError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [streetError, setStreetError] = useState(false);
  const [signsError, setSignsError] = useState(false);
  const [referenceError, setReferenceError] = useState(false);
  const [internalNumberError, setInternalNumberError] = useState(false);
  const [externalNumberError, setExternalNumberError] = useState(false);

  const numbers = {
    ar: "+549",
    cl: "+56",
    co: "+57",
    cr: "+506",
    mx: "+52",
    pa: "+507",
    pe: "+51",
    uy: "+598",
  };

  const onSubmit = handleSubmit(async (data) => {
    const body = bodyAdapter(data, country);
    const inBeforePhoneNumberArray = body.phoneNumbers;
    const auxiliarPhoneNumberArray = [];
    if (Array.isArray(inBeforePhoneNumberArray) && inBeforePhoneNumberArray.length > 0) {

      inBeforePhoneNumberArray.forEach(element => {
        let auxiliarPhoneNumber = numbers[country];
        if (country == "uy") {
          if (element?.number?.substring(0, 2) == "09") {
            element.number = element.number.replace("09", "9");
          } else {
            return;
          }
        }
        auxiliarPhoneNumber = auxiliarPhoneNumber.concat(element?.number);
        element.number = auxiliarPhoneNumber;
        auxiliarPhoneNumberArray.push(element);
      });
    }
    body.phoneNumbers = auxiliarPhoneNumberArray;


    dispatch(clientDetailsForm(body));
    let result = ""
    if (params?.operation == "update") {
      result = await dispatch(
        putClient({ ...body, contactId: clientDetails.contactId })
      );
    } else {
      result = await dispatch(postClient(body));
    }

    if (result?.payload?.code == "200") {
      dispatch(openPostUpdate())
      if (location.pathname.includes("edbs")) {
        const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
        if (auxFormBody?.hostData) {
          const newHostId = result?.payload?.contactId;
          const updateHostData = { ...auxFormBody.hostData, hostId: newHostId };
          const updateFormData = { ...auxFormBody, hostData: updateHostData };

          localStorage.setItem("formBody", JSON.stringify(updateFormData));
        } else {
          const newHostId = result?.payload?.contactId;
          const updatedHostData = {
            hostId: newHostId,
            hostName: "",
            hostEmail: "",
            phone: "",
          };
          const updateFormData = { ...auxFormBody, hostData: updatedHostData };

          localStorage.setItem("formBody", JSON.stringify(updateFormData));
        }
        navigate(-1)
      } else {
        navigate(`/dashboard/clientlist`);
      }
      if (params?.operation == "update") {

        const modalData = {
          title: <FormattedMessage id="client.list.modal.put.title.one" />,
          text: <FormattedMessage id="client.list.modal.put.p.one" />,
          status: "confirm",
          show: false,
          bottom: 0,
        };
        setModalTexts(modalData);
      } else {
        const modalData = {
          title: <FormattedMessage id="client.list.modal.post.title.one" />,
          text: <FormattedMessage id="client.list.modal.post.p.one" />,
          status: "confirm",
          show: false,
          bottom: 0,
        };
        setModalTexts(modalData);
      }
      localStorage.removeItem("clientForm");

    }

  });

  const onFormulationErrorClear = () => {
    setFormulationError({
      firstName: false,
      lastName: false,
    });
  };

  const onChangeValidate = () => {
    setIsValidateError(false);
    setEmailError(false);
    setCommentError(false);
    setStreetError(false);
    setSignsError(false);
    setReferenceError(false);
    setInternalNumberError(false);
    setExternalNumberError(false);
    onFormulationErrorClear();
  };

  const onStartUpUpdate = async () => {
    await dispatch(getClientDetails(params?.contactId));
  };

  const onStartUp = async () => {
    await dispatch(getTerritoryByLevel());
  };

  const removeCombintionOfValues = (phone) => {
    let aux = phone;
    let firstValues = aux.substring(0, 4);
    let theRest = aux.substring(4, aux.length);
    let replaceThisValue = numbers[country].replace("+", "");
    let noCountry = firstValues.replace(replaceThisValue, "");
    let result = noCountry.concat(theRest);
    return result;
  };

  const isValidPhone = async (value) => {
    console.log("Value: ", value)
    if (!value) {
      setIsValidateError({ isError: true, message: "input.error.required" });
      return;
    };
    let isPhoneFailedValidation = await SharePhoneLibrary(value)
    if (isPhoneFailedValidation) {
      setIsValidateError({ isError: isPhoneFailedValidation, message: "settings.error.format" });
    };

  };

  function allocate(clientDetails) {
    console.log(clientDetails)
    defaultState.personalDataStateKeys.forEach((key) => {
      setValue(key, clientDetails[key]);
    });
    if (country == "ar")
      setValue(
        "identification",
        clientDetails.identification
          ? clientDetails.identification[0]?.value
          : null
      );
    if (clientDetails.socialNetworks && clientDetails.socialNetworks.length) {
      const indexEmail = clientDetails.socialNetworks
        .map((item) => item.id)
        .indexOf("email");
      const email = clientDetails.socialNetworks[indexEmail].value;
      setValue("email", email);
    }
    if (clientDetails.phoneNumbers?.length) {
      clientDetails.phoneNumbers.map((item) => {
        if (item.phoneOrderId == 1) {
          setValue("cellNumber", removeCombintionOfValues(item.number));
        } else {
          setValue("otherNumber", removeCombintionOfValues(item.number));
        }
      });
    }
    if (clientDetails.addressInfo?.length) {
      defaultState.addressStateKeys.forEach((key) => {
        setValue(key, clientDetails.addressInfo[0][key]);
      });
    }
    if (clientDetails.comments?.length) {
      setValue("comments", clientDetails.comments[0].value);
    }
  };

  useEffect(() => {
    onStartUp();
    if (params?.operation == "update") {
      onStartUpUpdate();
    }
  }, []);

  useEffect(() => {
    setFormulationError({ firstName: false, lastName: false })
    setIsValidateError(false);
  }, [success]);

  useEffect(() => {
    if (params?.operation == "update" && clientDetails) {
      allocate(clientDetails);
    }
  }, [clientDetails]);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      console.log("Errors: ", errors);
      const clientForm = JSON.parse(localStorage.getItem("clientForm"));
      setEmailError(errors["email"] || false);
      setCommentError(errors["comments"] || false);
      setStreetError(errors["street"] || false);
      setSignsError(errors["signs"] || false);
      setReferenceError(errors["reference"] || false);
      setInternalNumberError(errors["internalNumber"] || false);
      setExternalNumberError(errors["externalNumber"] || false);
      setFormulationError({ firstName: errors["firstName"] || false, lastName: errors["lastName"] || false });
      if (errors["cellNumber"]) {
        isValidPhone(clientForm?.cellNumber || false);
      }
      if (errors["firstName"] || errors["lastName"] || (errors["cellNumber"] && !clientForm?.cellNumber ) ) {
        const button = document.getElementById("errorsModalButon");
        button.click();
      }
    }
  }, [errors]);

  return (
    <>
      <WarningModal errors={errors} />
      <button
        id="errorsModalButon"
        className="invisible position-absolute"
        data-bs-toggle="modal"
        data-bs-target={`#modalFormErros`}
      ></button>
      <FormProvider {...methods}>
        <div className="container--padding bg-white">
          <div className=" container--padding--card-holder ">
            <div>
              <div className="w-100 mt-2 ">
                <form
                  style={{ width: "766px" }}
                  className="mt-5"
                  onSubmit={onSubmit}
                >
                  {loader ? (
                    <FormSkeleton />
                  ) : (
                    <>
                      <h3 className=" museo-sans-500 mb-3 s20">
                        <FormattedMessage id="client.details.card.title" />
                      </h3>
                      <hr className=" m-0" />
                      <PersonalData clientDetails={clientDetails} onChangeValidate={onChangeValidate} formulationError={formulationError} />
                      <h3 className=" museo-sans-500 s20">
                        <FormattedMessage id="invitation.modal.list.contact.data" />
                      </h3>
                      <hr className=" m-0" />
                      <ContactData emailError={emailError} clientDetails={clientDetails} onChangeValidate={onChangeValidate} isValidateError={isValidateError} register={register} />
                      <h3 className=" museo-sans-500 s20">
                        <FormattedMessage id="settings.user.data.address" />
                      </h3>
                      <hr className=" m-0" />
                      <div
                        style={{ gap: "24px" }}
                        className="d-flex flex-wrap my-4 "
                      >
                        {addressCountrySelector(
                          country,
                          register,
                          watch,
                          setValue,
                          control,
                          streetError,
                          signsError,
                          referenceError,
                          internalNumberError,
                          externalNumberError,
                          onChangeValidate,
                        )}
                      </div>
                      <hr className="m-0" />
                      <div className="d-flex flex-wrap mt-4 mb-4 pb-3">
                        <ClientListFormTextArea
                          onChangeValidate={onChangeValidate}
                          placeholder={"Escribí tus notas aquí"}
                          label={"Notas (opcional)"}
                          name="comments"
                          register={register}
                          isError={commentError}
                        />
                      </div>
                      <ButtonSubmit onClick={() => { }}>
                        {params?.operation == "update" ? <FormattedMessage id="save" /> : <FormattedMessage id="create" />}
                      </ButtonSubmit>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  );
}
