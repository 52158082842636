import {
  Argentina,
  Chile,
  Colombia,
  CostaRica,
  Mexico,
  Panama,
  Peru,
  Uruguay
} from "./countryOptions";

const countryComponents = {
  ar: Argentina,
  cl: Chile,
  co: Colombia,
  cr: CostaRica,
  mx: Mexico,
  pa: Panama,
  pe: Peru,
  uy: Uruguay
};

export default function addressCountrySelector(country, register, watch, setValue, control, streetError, signsError, referenceError, internalNumberError, externalNumberError, onChangeValidate) {
  const Component = countryComponents[country.toLowerCase()] || Chile;
  return <Component register={register} watch={watch} setValue={setValue} control={control} streetError={streetError} signsError={signsError} referenceError={referenceError} internalNumberError={internalNumberError} externalNumberError={externalNumberError} onChangeValidate={onChangeValidate} />;
}
