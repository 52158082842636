import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  fetchAuthLogin,
  fetchAuthRegister,
  fetchDirectorProfile,
  fetchPassRecovery,
  fetchUserProfile,
} from "../../Framework/redux/loginSideEffects";

import "./login.css";
import Loader from "../../../common/Share/Loader";

import { ReactComponent as EyeOpen } from "../../../common/svgs/password-eye-open.svg";
import { ReactComponent as EyeClosed } from "../../../common/svgs/password-eye-closed.svg";
import LoginErrorHandler from "../components/LoginErrorHandler";
import ModalWarning from "../../../common/Share/ModalWarning";
import ModalPassChange from "../../../common/Share/ModalPassChange";
import { checkedChanged } from "../../Framework/redux/loginAction";
import { defineStepNumber } from "../../../Dashboard/Framework/redux/dashboardAction";
import { multiLang } from "../../../common/Share/MultiLang";
import { modifyMultiLanguage } from "../../../common/redux/commonActions";

import { ReactComponent as SVGLogin } from "../img/login-icon.svg";
import LoginAnalytics from "../../Framework/ga/LoginAnalytics";
import ShareCheckEnvKey from "../../../common/Share/ShareCheckEnvKey";
import ShareFlagIcons from "../../../common/Share/ShareFlagIcons";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";

export function Login() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [number, setNumber] = useState({ value: "" });
  const [password, setPassword] = useState({ value: "" });
  const [country, setCountry] = useState({
    value: "",
    code: "",
    prevValue: "",
    prevCode: "",
  });
  const [errorShow, setErrorShow] = useState({ login: false, pass: false });
  const [showSelect, setShowSelect] = useState(false);
  const [typePassword, setTypePassword] = useState(true);
  const [clipModal, setClipModal] = useState({ show: false, data: true });
  const [directora, setDirectora] = useState(false);
  const [showDirector, setShowDirector] = useState(false);
  const [loaderTwo, setLoaderTwo] = useState(false);

  const clipModalPassword = useSelector(
    (state) => state.userInfo.changingPassword
  );

  const intl = useIntl();

  const countries = [
    { code: "ar", name: "Argentina", id: "argentinaFlag" },
    { code: "cl", name: "Chile", id: "chileFlag" },
    { code: "co", name: "Colombia", id: "colombiaFlag" },
    { code: "cr", name: "Costa Rica", id: "costaRicaFlag" },
    { code: "mx", name: "México", id: "mexicoFlag" },
    { code: "pa", name: "Panamá", id: "panamaFlag" },
    { code: "pe", name: "Perú", id: "peruFlag" },
    { code: "uy", name: "Uruguay", id: "uruguayFlag" },
  ];

  const switchCountry = (auxCountry) => {
    if (auxCountry) {
      const countryMap = {
        argentina: { code: "ar", name: "argentina" },
        mexico: { code: "mx", name: "mexico" },
        chile: { code: "cl", name: "chile" },
        "costa rica": { code: "cr", name: "costa rica" },
        panamá: { code: "pa", name: "panamá" },
        perú: { code: "pe", name: "perú" },
        uruguay: { code: "uy", name: "uruguay" },
      };

      const selectedCountry = countryMap[auxCountry.toLowerCase()];
      if (selectedCountry) {
        localStorage.setItem("country", selectedCountry.code);
        localStorage.setItem("countryLong", selectedCountry.name);
        setCountry({ value: selectedCountry.name, code: selectedCountry.code });
        const result = multiLang(selectedCountry.code);
        dispatch(modifyMultiLanguage(result));
      }
    }
  };

  const onDirectoraClick = () => {
    setDirectora(!directora);
  };

  const onCloseClip = () => {
    setClipModal({ ...clipModal, show: false });
  };

  const resolveLogin = async (evt) => {
    setLoaderTwo(true);
    setClipModal({ show: false, data: true });
    evt.preventDefault();

    let objectVideo = JSON.parse(localStorage.getItem("videoFour"));
    let objectStringify = null;

    if (!objectVideo?.status) {
      objectVideo = {
        seen: 0,
        status: "true",
      };
    }

    objectStringify = JSON.stringify(objectVideo);

    localStorage.clear();

    localStorage.setItem("videoFour", objectStringify);

    if (country.value.trim() == "") {
      setErrorShow({ ...errorShow, login: true });
      setLoaderTwo(false);
      return;
    }

    if (
      number.value.trim() === "" &&
      password.value.trim() === "" &&
      country.value.trim() == ""
    ) {
      setLoaderTwo(false);
      setClipModal({ show: true, data: false });
      return;
    }

    localStorage.setItem("country", country.code);
    localStorage.setItem("countryLong", country.value);
    localStorage.setItem("loginVideo", "true");

    LoginAnalytics("ingreso");

    const resultRegister = await dispatch(
      directora
        ? fetchDirectorProfile({
            number: number.value,
            password: password.value,
          })
        : fetchAuthRegister({ number: number.value, password: password.value })
    );

    if (resultRegister?.payload == 901) {
      setLoaderTwo(false);
      return;
    }

    if (directora && !resultRegister.payload?.status) {
      history("/directora/logging");
      setLoaderTwo(false);
      return;
    }
    if (resultRegister?.error?.message) {
      setLoaderTwo(false);
      return;
    }
    if (!resultRegister.payload?.status) {
      if (resultRegister.payload?.mobileRegistered) {
        const resultLogin = await dispatch(
          fetchAuthLogin({ number: number.value, password: password.value })
        );
        dispatch(defineStepNumber(30));
        const result = await dispatch(fetchUserProfile(number.value));

        localStorage.setItem(
          "invitationToggle",
          `${result?.payload?.isFormalized || "false"}`
        );
        sessionStorage.setItem("TabIdData", number.value);

        history("/dashboard");
        setLoaderTwo(false);
        return;
      } else {
        dispatch(defineStepNumber(-1));
        history("/phone");
        setLoaderTwo(false);
        return;
      }
    } else {
      if (resultRegister.payload == 905) {
        setLoaderTwo(false);
        history("/error");
      }
    }
    setLoaderTwo(false);
    if (
      number.value.trim() !== "" &&
      password.value.trim() !== "" &&
      country.value.trim() !== ""
    ) {
      setClipModal({ show: true, data: true });
    } else {
      setErrorShow({ ...errorShow, login: true });
    }
  };

  const removeChangePassword = () => {
    dispatch(checkedChanged());
  };

  const changeNumber = (evt) => {
    setErrorShow({ login: false, pass: false });
    setNumber({
      value: evt.target.value,
    });
  };

  const changePassword = (evt) => {
    setErrorShow({ login: false, pass: false });
    setPassword({
      value: evt.target.value,
    });
  };

  const toggleType = () => {
    setTypePassword(!typePassword);
  };

  const onCountrySelect = (evt) => {
    setErrorShow({ login: false, pass: false });

    evt.preventDefault();
    let string = evt.target.name;
    let auxArr = string.split("-");

    localStorage.setItem("country", auxArr[1]);
    localStorage.setItem("countryLong", auxArr[0].replace("_", " "));

    setCountry({ value: auxArr[0].replace("_", " "), code: auxArr[1] });

    let result = multiLang(auxArr[1]);

    dispatch(modifyMultiLanguage(result));

    // CHANGE THIS ONCE FIREBASE IS COMPLETED - "Delete it" ( Simple - Accept all that comes from Firebase that partakes with this! ) -- START
    const resultOf = ShareCheckEnvKey("SALE_DIRECTOR", auxArr[1].toUpperCase());
    console.log("ShareCheckEnvKey(SALE_DIRECTOR):", resultOf, " auxArr[1]: " , auxArr[1]);
    setShowDirector(resultOf);
    // CHANGE THIS ONCE FIREBASE IS COMPLETED - "Delete it" -- END

    setShowSelect(!showSelect);
  };

  const onPassRecovery = async (evt) => {
    evt.preventDefault();
    LoginAnalytics("forgot-pass");
    setErrorShow({ login: false, pass: false });
    if (country.value === "" || number.value.trim() === "") {
      setErrorShow({ ...errorShow, pass: true });
      return;
    }
    const result = await dispatch(fetchPassRecovery(number.value));
    if (
      typeof result.payload === "object" &&
      (result.payload?.data?.code != 918 && result.payload?.data?.code != 915 )
    ) {
      history("/recovery");
    } else {
      setErrorShow({ ...errorShow, pass: true });
    }
    setErrorShow({ ...errorShow, pass: true });
  };

  const toggleSelect = (evt) => {
    evt.preventDefault();
    if (showSelect) {
    } else {
      setCountry({ ...country, value: "", code: "" });
    }
    setShowSelect(!showSelect);
  };

  const onVoid = () => {};

  useEffect(() => {
    let countryCode = localStorage.getItem("country");
    let countryValue = localStorage.getItem("countryLong");
    if (countryValue) {
      setCountry({ value: countryValue, code: countryCode });
    }
    if (country.code == "" && countryCode == "" && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        const geocodeAPI = `https://geocode.xyz/${latitude},${longitude}?json=1`;

        fetch(geocodeAPI)
          .then((response) => response.json())
          .then((data) => {
            const auxCountry = data.country;

            switchCountry(auxCountry);
          })
          .catch((error) => {
            console.error("Error al obtener el país:", error);
          });
      });
    } else {
      console.error("Geolocalización no es compatible en este navegador");
    }
    if (country.code == "" && countryCode != "") {
    }
  }, []);

  return (
    <div id="login-start" className="">
    <ModalWarning
      data={clipModal.data}
      show={clipModal.show}
      close={onCloseClip}
    />
    {clipModalPassword && (
      <ModalPassChange
        show={clipModalPassword}
        close={removeChangePassword}
      />
    )}
    <div className=" position-relative text-center mb-3">
      <div
        style={{ width: "100px", height: "66px", marginBottom: "31px" }}
        className=" d-flex w-100 svg-force-size"
      >
        <SVGLogin />
      </div>
      <h1 className=" s40 museo-sans-700">
        <FormattedMessage id="login.welcome" defaultMessage="Welcome!" />
      </h1>
      <h2 className=" museo-sans-500 s16">
        {directora ? (
          <FormattedMessage id="sale.director.login.title" />
        ) : (
          <FormattedMessage
            id="login.comment"
            defaultMessage="Sign in to your Just account"
          />
        )}
      </h2>
    </div>
    <form id="login-form" className=" museo-sans-500 p-0">
      <div className="form-group">
        <label className=" museo-sans-700 s12">
          <FormattedMessage
            id="login.selector.one"
            defaultMessage="Country"
          />
        </label>
        <div
          onClick={directora ? onVoid : toggleSelect}
          className={` ${directora ? "" : ""} `}
        >
          <div
            id="login--select-container"
            className={` ${
              directora ? "brightGrayBgOpacity20 grayColor   " : "pointer"
            }  input-borders input-container py-2 my-2 s14 ${
              (clipModal.show && !clipModal.data) ||
              (country.value.trim() === "" &&
                (errorShow.login || errorShow.pass))
                ? "redBorder"
                : ""
            } `}
          >
            <div className="dropdown s14">
              <span className="d-flex justify-content-between">
                {country.value === "" ? (
                  <p className="grayColor s14">
                    <FormattedMessage
                      id="login.selector.default"
                      defaultMessage="Selecciona un país"
                    />
                  </p>
                ) : (
                  <div
                    style={{ alignItems: "center" }}
                    className="d-flex s14 "
                  >
                    {country.code && (
                      <div
                        style={{ top: -3 }}
                        className="px-2 position-relative  "
                      >
                        {" "}
                        <ShareFlagIcons code={country.code} />{" "}
                      </div>
                    )}

                    <p className=" text-capitalize">{country.value}</p>
                  </div>
                )}
                <>
                  {!directora && (
                    <>
                      {showSelect ? (
                        <i className="bi bi-chevron-up text-info px-3"></i>
                      ) : (
                        <i className="bi bi-chevron-down text-info px-3"></i>
                      )}
                    </>
                  )}
                </>
              </span>
              {showSelect && (
                <ul
                  className="dropdown-menu w-100 show mt-2 py-0 shadow s14"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {countries.map((country, index) => (
                    <>
                      <li className="pointer">
                        <a
                          onClick={onCountrySelect}
                          className="dropdown-item px-0 d-flex px-2 gap-2 blackColor"
                          name={`${country.name
                            .toLowerCase()
                            .replace(" ", "_")}-${country.code}`}
                          id={`${country.name
                            .toLowerCase()
                            .replace(" ", "_")}Flag`}
                        >
                          <ShareFlagIcons
                            key={ShareKeyGenerator()}
                            code={country.code}
                          />
                          {country.name}
                        </a>
                      </li>
                      {index < countries.length - 1 && (
                        <hr className="m-0"></hr>
                      )}
                    </>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        {errorShow.login && (
          <LoginErrorHandler string={country.code} type="country" />
        )}
        {errorShow.pass && (
          <LoginErrorHandler string={country.code} type="country" />
        )}
      </div>
      <div className="form-group my-2">
        <label className=" museo-sans-700 s12">
          <FormattedMessage
            id="login.label.one"
            defaultMessage="Consultant's number"
          />
        </label>
        <input
          onChange={changeNumber}
          type="text"
          autoComplete={false}
          id="account"
          className={`form-control input-borders blackColor py-2 my-2 s14 ${
            clipModal.show ||
            (number.value.trim() === "" &&
              (errorShow.login || errorShow.pass))
              ? "redBorder"
              : ""
          } `}
          placeholder={intl.formatMessage({
            id: "login.placeholder.label.one",
            defaultMessage: "Example: 24536",
          })}
        ></input>
        {errorShow.login && (
          <LoginErrorHandler string={number.value} type="user" />
        )}
        {errorShow.pass && (
          <LoginErrorHandler string={number.value} type="user" />
        )}
      </div>
      <div className="form-group">
        <label className=" museo-sans-700 s12">
          <FormattedMessage id="login.label.two" defaultMessage="Password" />
        </label>
        {typePassword ? (
          <div className=" mb-2 input-container">
            <input
              onChange={changePassword}
              className={`form-control input-borders blackColor py-2 my-1 s14 ${
                clipModal.show ||
                (password.value.trim() === "" && errorShow.login)
                  ? "redBorder"
                  : ""
              } `}
              id="password"
              placeholder={intl.formatMessage({
                id: "login.placeholder.label.two",
                defaultMessage: "Example: 24536",
              })}
              type="password"
              maxLength={20}
              minLength={6}
            ></input>
            <i onClick={toggleType}>
              <EyeOpen />
            </i>
          </div>
        ) : (
          <div className="mb-2 input-container">
            <input
              onChange={changePassword}
              className={`form-control input-borders blackColor py-2 my-1 s14 ${
                clipModal.show ||
                (password.value.trim() === "" && errorShow.login)
                  ? "redBorder"
                  : ""
              } `}
              id="password"
              placeholder="******"
              type="text"
              maxLength={12}
            ></input>
            <i onClick={toggleType} className="">
              <EyeClosed />
            </i>
          </div>
        )}
        {errorShow.login && (
          <>
            <LoginErrorHandler string={password.value} type="password" />{" "}
          </>
        )}
      </div>
      <div id="login--btn-holder" className="s14 text-center my-4">
        <button
          id="login--continue"
          className=" w-100 position-relative my-3 museo-sans-700 s14 btnLoginWidth p-2 text-white btnCustom skyBg"
          onClick={resolveLogin}
        >
          <Loader loading={loaderTwo} />{" "}
          <FormattedMessage id="login.button" defaultMessage="Login" />{" "}
        </button>
        <div style={{ minHeight: "21px" }}>
          {!directora && (
            <p
              onClick={onPassRecovery}
              className="pointer s14 museo-sans-700 skyColor"
              to={"/recovery"}
            >
              <FormattedMessage
                id="login.password.recovery"
                defaultMessage="Forgot your password?"
              />
            </p>
          )}
          {directora && (
            <p
              onClick={onDirectoraClick}
              className=" position-relative skyColor museo-sans-700 s14 pointer text-center"
            >
              <FormattedMessage id="buttons.back" />
            </p>
          )}
        </div>
      </div>

        <div
          style={{ minHeight: "21px" }}
          className="mt-3 w-100 d-flex justify-content-center"
        >
          {!directora && <LinkToDirector isCountryWithDirector={country} onDirectoraClick={onDirectoraClick} />}
        </div>
      </form>
    </div>
  );
}

export function LinkToDirector(params) {
  const [showDirector, setShowDirector] = useState(false);
  const location = useNavigate();

  useEffect(() => {
    // CHANGE THIS ONCE FIREBASE IS COMPLETED - "Delete it" ( Simple - Accept all that comes from Firebase that partakes with this! ) -- START
    const country = localStorage.getItem("country");
    const resultOf = ShareCheckEnvKey("SALE_DIRECTOR", country);
    setShowDirector(resultOf);
    // CHANGE THIS ONCE FIREBASE IS COMPLETED - "Delete it" ( Simple - Accept all that comes from Firebase that partakes with this! ) -- END
  }, [params.isCountryWithDirector, location]);

  return (
    <>
      {showDirector && (
        <p
          onClick={params.onDirectoraClick}
          className="skyColor museo-sans-700 s14 pointer text-center"
        >
          <FormattedMessage id="sale.director.login.link" />
        </p>
      )}
    </>
  );
}