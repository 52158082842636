import { useRef, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ImageCircleHandler from "../../../../common/Share/ImageCircleHandler";
import DashboardFirstStepsModalDetailGraph from "../DashboardFirstStepsModalDetailGraph";
import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";
import { ReactComponent as LogoNav } from "../../../../common/svgs/Logo-Just-Sidebar.svg";
import { ReactComponent as SVGPendingFilled } from "../../SVGs/Pending/ic-item-pending-sm-filled-yellow.svg";
import { ReactComponent as SVGCompletedFilled } from "../../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as SVGCIncompletedFilled } from "../../../../common/svgs/ic-x-circle-bg.svg";
import { ReactComponent as SVGCBloquedFilled } from "../../SVGs/Bloqueado/ic-item-desbloq-sm-filled-gray.svg";
import { ReactComponent as SVGCUnlockedFilled } from "../../SVGs/Bloqueado/ic-item-desbloq-sm-filled-yellow.svg";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import firstStepsAdjustDate from "../moments/firstStepsAdjustDate";
import { FirstStepsHelpMessage } from "../FirstStepsHelpMessage";
import {
  changePersonalGroupName,
  clearInsideArray,
  pushInsideArray,
} from "../../../../Descendants/Framework/redux/DescendantsAction";
import { getCMSDataOne } from "../../../Framework/redux/FirstStepsAllSideEffect";

import { ReactComponent as SVGRightArrow } from "../../../../common/svgs/ic-arrow-right-sm.svg";
import { replaceURLString } from "../FirstStepFetchCMSDataById";
import FirstStepsGuidelinesDate from "../FirstStepsGuidelinesDate";
import plus from "../../imgs/plus/plus.png";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import ProgressBarParams from "../../../../common/Share/ProgressBarParams";

export default function FirstStepsModalIncorporatedDetails() {
  const scrollbarRef = useRef(null);
  const modalOutSideClickRef = useRef(null);
  const modalOutSideClickRefFm = useRef(null);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [toggle, setToggle] = useState(true);
  const stepsData = useSelector(
    (state) => state?.firstStepsAllReducer?.firstStepsModalDetails?.data
  );
  const CMSData = useSelector(
    (state) => state.firstStepsAllReducer.CMSOne?.data
  );

  const CMSLoader = useSelector(
    (state) => state?.firstStepsAllReducer?.CMSLoader
  );

  const controlLegals = (legals) => {
    if (!legals) {
      return "";
    }

    const splitByN = legals.split("\n");

    return (
      <div className=" d-flex flex-column gap-3 mt-1">
        {splitByN.map((elm) => {
          return <p> {elm} </p>;
        })}
      </div>
    );
  };

  const controlTitle = (title) => {
    if (!title) {
      return "";
    }

    const splitByN = title.split("\n");

    return (
      <>
        {splitByN.map((elm) => {
          return <p>{elm}</p>;
        })}
      </>
    );
  };

  const onShowProfile = (id, data) => {
    dispatch(
      changePersonalGroupName({ name: data?.profileResume?.name, clientId: id })
    );
    history(`/dashboard/descendants/profile/${id}`);
  };

  const activateCMSExternal = async (clientId, planId) => {
    const clickModal = document.getElementById(`guidelines-${clientId}`);
    await dispatch(getCMSDataOne(planId));
    clickModal.click();
  };

  const showAsc = (uplineId, data) => {
    history(`/dashboard/descendants/profile/asc/${uplineId}`);
    const clickMe = document.getElementById("detail-modal-click-close");

    dispatch(clearInsideArray());
    dispatch(
      changePersonalGroupName({
        name: data?.profileResume.name,
        clientId: data.clientId ? data?.clientId : 0,
      })
    );
    dispatch(
      pushInsideArray({
        clientId: uplineId,
        name: data?.profileResume.uplineName,
      })
    );

    clickMe.click();
  };

  const handleScroll = () => {
    scrollbarRef.current.scrollTop = 0;
  };

  const fetchAchievementByStepId = (array, stepId) => {
    if (!array) {
      return undefined;
    }

    let aux = array.find((elm) => elm.stepId == stepId);

    return aux;
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return ''; // Maneja el caso en que no haya fecha
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0'); // Asegura 2 dígitos
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses empiezan en 0
    const year = String(date.getFullYear()).slice(2); // Solo últimos 2 dígitos del año
    return `${day}/${month}/${year}`;
};

  useEffect(() => {
    const closeProgressModal = document.getElementById(
      "detail-modal-click-close"
    );
    const closePautaModal = document.getElementById(
      `guidelines-${stepsData?.data?.clientId}`
    );

    closeProgressModal.addEventListener("click", handleScroll);
    closePautaModal.addEventListener("click", handleScroll);

    return () => {
      closePautaModal.removeEventListener("click", handleScroll, false);
      closePautaModal.removeEventListener("click", handleScroll, false);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalOutSideClickRef.current &&
        !modalOutSideClickRef.current.contains(event.target)
      ) {
        setToggle(true);
        handleScroll();
      }
      if (
        modalOutSideClickRefFm.current &&
        !modalOutSideClickRefFm.current.contains(event.target)
      ) {
        handleScroll();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalOutSideClickRef]);

  return (
      <div
        className="modal fade"
        id="modalFirstStepsLiderDirector"
        aria-hidden="true"
        aria-labelledby="modalFirstStepsLiderDirector"
        tabIndex="-1"
      >
        {toggle ? (
            <div
              className="modal-dialog modal-dialog-centered"
              style={{ maxWidth: "610px" }}
              ref={modalOutSideClickRefFm}
            >
              <div className="modal-content modal-radius">
                <div className=" modal-body p-0"></div>
                <div style={{ width: "608px", height: "608px" }}>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      background: "rgba(221, 221, 221, 0.2)",
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between w-100 align-items-center"
                      style={{ height: "112px", padding: "0px 40px" }}
                    >
                      <>
                        {stepsData?.data?.isPersonalIncorporated &&
                        stepsData?.incorp == "true" ? (
                            <div className="d-flex flex-row gap-1 align-items-center museo-sans-700 s14">
                              <div
                                style={{
                                  height: "64px",
                                  width: "64px",
                                  top: -0,
                                  border: "solid 1.5px #456ECE",
                                  marginTop: 1,
                                }}
                                className=" position-relative d-flex justify-content-center align-items-center rounded-circle "
                              >
                                <ImageCircleHandler
                                  size={"30px"}
                                  width={"59px"}
                                  height={"59px"}
                                  url={stepsData?.data?.profileResume?.image}
                                  firstName={
                                    stepsData?.data?.profileResume?.firstName
                                  }
                                  lastName={
                                    stepsData?.data?.profileResume?.lastName
                                  }
                                  name={stepsData?.data?.profileResume?.name}
                                  top={2}
                                />
                              </div>
                              <div style={{ marginLeft: "16px" }}>
                                <p className="museo-sans-500  s20">
                                  {stepsData?.data?.profileResume?.name}
                                </p>
                                {
                                  stepsData?.data?.currentWeek == stepsData?.data?.totalWeeks ?
                                  <p
                                  className="museo-sans-500 s14"
                                  style={{ color: "#908F9A" }}
                                >
                                  <FormattedMessage id="week.end.of.week.date" /> {formatDate(stepsData?.data?.roadmapEndDate)}
                                </p>
                                :
                                <p
                                  className="museo-sans-500 s14"
                                  style={{ color: "#908F9A" }}
                                >
                                  <FormattedMessage id="header.week" />{" "}
                                  {stepsData?.data?.currentWeek}{" "}
                                  <FormattedMessage id="of" />{" "}
                                  {stepsData?.data?.totalWeeks}
                                </p>

                                }
                              </div>
                            </div>
                        ) : (
                            <div className=" w-100 d-flex flex-row gap-1 align-items-center museo-sans-700 s14">
                              {stepsData?.data?.isPersonalIncorporated ? (
                                <>
                                  <div
                                    style={{
                                      height: "64px",
                                      width: "64px",
                                      top: -0,
                                      border: "solid 1.5px #456ECE",
                                      marginTop: 1,
                                    }}
                                    className=" position-relative d-flex justify-content-center align-items-center rounded-circle "
                                  >
                                    <ImageCircleHandler
                                      size={"30px"}
                                      width={"59px"}
                                      height={"59px"}
                                      url={
                                        stepsData?.data?.profileResume?.image
                                      }
                                      firstName={
                                        stepsData?.data?.profileResume
                                          ?.firstName
                                      }
                                      lastName={
                                        stepsData?.data?.profileResume?.lastName
                                      }
                                      name={
                                        stepsData?.data?.profileResume?.name
                                      }
                                      top={2}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <ImageCircleHandler
                                    size={"30px"}
                                    width={"59px"}
                                    height={"59px"}
                                    url={stepsData?.data?.profileResume?.image}
                                    firstName={
                                      stepsData?.data?.profileResume?.firstName
                                    }
                                    lastName={
                                      stepsData?.data?.profileResume?.lastName
                                    }
                                    name={stepsData?.data?.profileResume?.name}
                                    top={2}
                                  />
                                </>
                              )}
                              <div
                                className=""
                                style={{ width: "410px", marginLeft: "16px" }}
                              >
                                <p className="museo-sans-500  s20">
                                  {stepsData?.data?.profileResume?.name}
                                </p>
                                {
                                  stepsData?.data?.currentWeek == stepsData?.data?.totalWeeks ?
                                  <p
                                  className="museo-sans-500 s14"
                                  style={{ color: "#908F9A" }}
                                >
                                  <FormattedMessage id="week.end.of.week.date" /> {formatDate(stepsData?.data?.roadmapEndDate)}
                                </p>
                                :

                                <p
                                  className="museo-sans-500 s14"
                                  style={{ color: "#908F9A" }}
                                >
                                  <FormattedMessage id="header.week" />{" "}
                                  {stepsData?.data?.currentWeek}{" "}
                                  <FormattedMessage id="of" />{" "}
                                  {stepsData?.data?.totalWeeks}
                                </p>
                                }
                                <div>
                                  <hr className=" my-1"></hr>
                                </div>
                                <div
                                  style={{ color: "#908F9A", top: 1 }}
                                  className=" position-relative museo-sans-500 s14 d-flex gap-2"
                                >
                                  <FormattedMessage id="first.steps.details.modal.upline.incorp" />
                                  :{" "}
                                  <span
                                    onClick={() =>
                                      showAsc(
                                        stepsData?.data?.profileResume
                                          ?.uplineId,
                                        stepsData?.data
                                      )
                                    }
                                    className=" d-flex gap-1 pointer skyColor museo-sans-700 "
                                  >
                                    {" "}
                                    {
                                      stepsData?.data?.profileResume?.uplineName
                                    }{" "}
                                    <span className="mx-1" style={{ top: -2 }}>
                                      {" "}
                                      <SVGRightArrow />{" "}
                                    </span>{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                        )}
                      </>
                      <div
                        id="detail-modal-click-close"
                        className=" pointer "
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <SVGClose />
                      </div>
                    </div>
                  </div>
                  <div style={{ height: "495px" }}>
                    <div
                      ref={scrollbarRef}
                      style={{ height: "370px" }}
                      className="d-flex hidden-y-scroll justify-content-center align-items-center py-4"
                    >
                      <div style={{ height: "312px", width: "528px" }}>
                        <p className="museo-sans-700 s16">
                          <FormattedMessage id="first.steps.details.of.progress" />
                        </p>
                        <div>
                          <DashboardFirstStepsModalDetailGraph
                            infoStep={stepsData?.data}
                          />
                        </div>
                        <div>
                          <p className=" museo-sans-700 s16 ">
                            <FormattedMessage id="first.steps.details.advances" />
                          </p>
                        </div>
                        {fetchAchievementByStepId(
                          stepsData?.data?.steps,
                          1
                        ) && (
                          <>
                            <div className=" mb-3">
                              <ModalAchievement
                                data={fetchAchievementByStepId(
                                  stepsData?.data?.steps,
                                  1
                                )}
                                index={1}
                                redeemEndDate={stepsData?.data?.redeemEndDate}
                              />
                            </div>
                            <div>
                              <hr></hr>
                            </div>
                          </>
                        )}
                        {fetchAchievementByStepId(
                          stepsData?.data?.steps,
                          2
                        ) && (
                          <>
                            <div className=" my-3">
                              <ModalAchievement
                                data={fetchAchievementByStepId(
                                  stepsData?.data?.steps,
                                  2
                                )}
                                index={2}
                                redeemEndDate={stepsData?.data?.redeemEndDate}
                              />
                            </div>
                            <div>
                              <hr></hr>
                            </div>
                          </>
                        )}
                        {fetchAchievementByStepId(
                          stepsData?.data?.steps,
                          3
                        ) && (
                          <>
                            <div className=" mb-3">
                              <ModalAchievement
                                data={fetchAchievementByStepId(
                                  stepsData?.data?.steps,
                                  3
                                )}
                                extraData={
                                  stepsData.data.steps[2]?.stepDetail
                                    ?.stepDependency == 1
                                    ? fetchAchievementByStepId(
                                        stepsData?.data?.steps,
                                        1
                                      )
                                    : fetchAchievementByStepId(
                                        stepsData?.data?.steps,
                                        2
                                      )
                                }
                                index={3}
                                redeemEndDate={stepsData?.data?.redeemEndDate}
                              />
                            </div>
                          </>
                        )}
                        <div
                          style={{ height: "10px" }}
                          className="mb-3 w-100"
                        ></div>
                      </div>
                    </div>
                    <ModalWhiteShadow
                      left={"5%"}
                      width={"90%"}
                      bottom={125}
                      scrollbarRef={scrollbarRef}
                      aidHide={40}
                      height={23}
                    />
                    <div
                      style={{ zIndex: "10000" }}
                      className=" position-relative mt-0 d-flex flex-column justify-content-center align-items-center"
                    >
                      <div
                        id={`guidelines-${stepsData?.data?.clientId}`}
                        className=" invisible position-absolute"
                      ></div>
                      <button
                        type="button"
                        className="btnLoginWidth museo-sans-700 s14 whiteColor skyBorder skyBg button-hover-light-text button-bootstrap-radius button-padding"
                        onClick={() => {
                          activateCMSExternal(
                            stepsData?.data?.clientId,
                            stepsData?.data?.planId
                          );
                          setToggle(!toggle);
                        }}
                        style={{ width: "288px" }}
                      >
                        <FormattedMessage id="first.steps.guidelines.program" />
                      </button>
                      <button
                        onClick={() =>
                          onShowProfile(
                            stepsData?.data?.clientId,
                            stepsData?.data
                          )
                        }
                        type="button"
                        className="btnLoginWidth museo-sans-700 s14 whiteColor skyBorder bg-white button-hover-light-text button-bootstrap-radius button-padding"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        style={{
                          width: "288px",
                          marginTop: "16px",
                          color: "#5AAFF1",
                        }}
                      >
                        <FormattedMessage id="calendar.event.card.view.profile" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        ) : (
            <div
              ref={modalOutSideClickRef}
              className="modal-dialog modal-dialog-centered "
              style={{ maxWidth: "807px" }}
            >
              <div
                className="modal-content modal-radius"
                style={{ maxHeight: "640px" }}
              >
                <div
                  className="d-flex "
                  style={{
                    padding: "32px 56px 32px 32px",
                    height: "640px",
                    gap: "32px",
                  }}
                >
                  {CMSLoader ? (
                    <>
                      <div
                        style={{ borderRadius: "14px" }}
                        className=" lightui1-shimmer position-relative w-100 h-100"
                      ></div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          borderRadius: "15px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          width: "359px",
                          overflow: "hidden",
                          minWidth: "359px",
                        }}
                        className="position-relative"
                      >
                        <div className=" d-flex flex-column position-absolute justify-content-between align-items-center w-100 h-100 pt-4">
                          <div className="d-flex flex-column align-items-center">
                            <div
                              style={{ width: "62px", height: "36px" }}
                              className=" svg-force-size"
                            >
                              <LogoNav />
                            </div>
                            <div style={{ marginTop: "18px" }}>
                              <p
                                className=" s24 museo-sans-700 text-center"
                                style={{ color: "#CED2FF", width: "234px" }}
                              >
                                {controlTitle(CMSData?.title)}
                              </p>
                            </div>
                            <p className=" s16 museo-sans-500 text-white">
                              <FormattedMessage id="first.steps.new.consultnats" />
                            </p>
                          </div>
                          <div className="w-100">
                            <div
                              style={{
                                height: "32px",
                                background: "#CED2FF",
                                bottom: "32px",
                              }}
                              className=" d-flex  position-relative justify-content-center align-items-center s12 museo-sans-500"
                            >
                              <FirstStepsGuidelinesDate
                                initDate={stepsData?.data?.planStartDate}
                                endDate={stepsData?.data?.planEndDate}
                              />
                            </div>
                          </div>
                        </div>
                        <img
                          style={{
                            borderRadius: "15px",
                          }}
                          src={replaceURLString(
                            CMSData?.imageUrl,
                            CMSData?.legalsImage?.filename_download,
                            CMSData?.legalsImage?.id
                          )}
                        />
                      </div>
                    </>
                  )}
                  <div className=" position-absolute invisible">close</div>
                  <div className="w-100 d-flex flex-column position-relative">
                    <div
                      onClick={() => setToggle(!toggle)}
                      style={{ right: "-14px", top: "8px" }}
                      className="pointer position-absolute"
                    >
                      <SVGClose />
                    </div>
                    <div className="h-100 w-100" style={{ marginTop: "18px" }}>
                      <p className=" museo-sans-700 s20">
                        <FormattedMessage id="first.steps.guidelines.program.title" />
                      </p>

                      <div className=" mt-3">
                        <div
                          ref={scrollbarRef}
                          style={{ height: "440px" }}
                          className=" hidden-y-scroll d-flex flex-column gap-3 museo-sans-500 s16"
                        >
                          <div>{controlLegals(CMSData?.legals)}</div>
                          <p className="invisible" style={{ height: "20px" }}>
                            asdsad
                          </p>
                        </div>
                        <ModalWhiteShadow
                          left={"0%"}
                          width={"98%"}
                          bottom={"18px"}
                          scrollbarRef={scrollbarRef}
                          aidHide={40}
                          height={"70px"}
                        />
                        <div className=" d-flex w-100 justify-content-center">
                          <button
                            type="button"
                            className=" w-100 museo-sans-700 s14 whiteColor skyBorder skyBg button-hover-light-text button-bootstrap-radius button-padding"
                            onClick={() => setToggle(!toggle)}
                            style={{ height: "40px", zIndex: 9999999 }}
                          >
                            <FormattedMessage id="buttons.understood" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )}
      </div>
  );
}

export function ModalAchievement({ index, data, extraData, redeemEndDate }) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const prepareRequestForDate = (data, command) => {
    let result = firstStepsAdjustDate({
      reached: data?.stepDetail?.reachedDate,
      completed: data?.stepDetail?.completedDate,
      limit: data?.stepDetail?.limitDate,
      command: command,
    });

    return result;
  };

  const percentageCompleted = (actual, total) => {
    if (actual == 0) {
      return 0;
    }
    return (actual * 100) / total;
  };

  const statusChange = (status, index) => {
    if (index == 3) {
      if (status == "BLOCKED") {
        return "Bloqueado";
      }
      if (status == "PENDING") {
        return "Desbloqueado";
      }
      if (status == "UNLOCKED") {
        return "Desbloqueado";
      }
      if (status == "COMPLETED") {
        return "Alcanzado";
      }
      if (status == "REACHED") {
        return "Alcanzado";
      }
    }

    switch (status) {
      case "COMPLETED":
        return "Alcanzado";
      case "REACHED":
        return "Alcanzado";
      case "FAILED":
        return "No Alcanzado";
      case "PENDING":
        return "Pendiente";
      case "BLOCKED":
        return "Bloqueado";
    }
  };

  const statusIcon = (status, index) => {
    if (index == 3) {
      if (status == "BLOCKED") {
        return <SVGCBloquedFilled />;
      }
      if (status == "UNLOCKED") {
        return <SVGCUnlockedFilled />;
      }
      if (status == "PENDING") {
        return <SVGCUnlockedFilled />;
      }
      if (status == "REACHED") {
        return <SVGCompletedFilled />;
      }
      if (status == "COMPLETED") {
        return <SVGCompletedFilled />;
      }
    }

    switch (status) {
      case "COMPLETED":
        return <SVGCompletedFilled />;
      case "REACHED":
        return <SVGCompletedFilled />;
      case "FAILED":
        return <SVGCIncompletedFilled />;
      case "PENDING":
        return <SVGPendingFilled />;
      case "BLOCKED":
        return <SVGCBloquedFilled />;
    }
  };

  const changeStateForStatus = (state) => {
    switch (state) {
      case "FAILED":
        return "risk";
      case "PENDING":
        return "warning";
      case "UNLOCKED":
        return "warning";
      case "BLOCKED":
        return "gap";
      case "COMPLETED":
        return "completed";
      case "REACHED":
        return "completed";
    }
  };

  return (
    <div>
      <div className=" my-3 museo-sans-500 d-flex align-items-center gap-2">
        <p className=" museo-sans-700 s16">{data?.stepDescription}</p>
        {data?.stepDetail?.state == "FAILED" && (
            <p className=" s12 grayColor">
              <FormattedMessage id="close.since" />{" "}
              {prepareRequestForDate(data, "fetchLimitWithYear")}{" "}
            </p>
        )}
        {(data?.stepDetail?.state == "REACHED" ||
          data?.stepDetail?.state == "COMPLETED") && (
          
            <p className=" s12 grayColor">
              <FormattedMessage id="first.steps.message.reach.week.to" />
              {data?.stepDetail?.reachedWeek}
            </p>
          
        )}
        {data?.stepDetail?.state == "PENDING" && (
          
            <p className=" s12 grayColor">
              <FormattedMessage id="first.steps.message.available.until" />{" "}
              {prepareRequestForDate(data, "fetchLimitWithYear")}{" "}
            </p>
          
        )}
      </div>
      <div className=" mb-1 d-flex justify-content-between">
        <div className="d-flex gap-1 museo-sans-500 s14 ">
          <div
            style={{ height: "16px", width: "16px", top: -2 }}
            className=" position-relative svg-force-size"
          >
            {statusIcon(data?.stepDetail?.state, index)}
          </div>
          <p className=" text-uppercase">
            {statusChange(data?.stepDetail?.state, index)}
          </p>
        </div>
        <>
          {index != 3 && (
            <div
              className=" dm-sans-medium s14 d-flex gap-1"
              style={{ marginLeft: "20px" }}
            >
              <p style={{ color: "#171A22" }}>
                {formatAmount(
                  data?.stepDetail?.currentAmount,
                  cultureInfo?.languageCode,
                  cultureInfo?.defaultCurrencyCode
                )}
              </p>
              <p style={{ color: "#908F9A" }}>
                /{" "}
                {formatAmount(
                  data?.stepDetail?.stepAmount,
                  cultureInfo?.languageCode,
                  cultureInfo?.defaultCurrencyCode
                )}
              </p>
            </div>
          )}
        </>
      </div>
      {index != 3 ? (
          <div>
            <ProgressBarParams
              height={12}
              completed={percentageCompleted(
                data?.stepDetail?.currentAmount,
                data?.stepDetail?.stepAmount
              )}
              incompleted={
                  data?.stepDetail?.currentAmount <
                  data?.stepDetail?.stepAmount
              }
              status={changeStateForStatus(data?.stepDetail?.state)}
            />
          </div>
      ) : (
          <div className=" museo-sans-500 align-items-center p-3 gap-2 brightGrayBgOpacity20 s16 d-flex rounded-3 w-100 mb-2 ">
            <div className="d-flex s14 w-100 justify-content-between">
              <div>{extraData?.stepDescription}</div>
              {extraData?.stepDetail?.state == "PENDING" ? (
                <>
                  <div className=" museo-sans-700 goldBoldColor d-flex gap-1">
                    <p>
                      <FormattedMessage id="pending" />
                    </p>
                    <div
                      style={{ width: "16px", height: "16px", top: -2 }}
                      className=" position-relative svg-force-size"
                    >
                      <SVGPendingFilled />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {extraData?.stepDetail?.state == "FAILED" ? (
                      <div className=" museo-sans-700 lightRedColor d-flex gap-1">
                        <p>
                          <FormattedMessage id="first.steps.status.not.reached" />
                        </p>
                        <div
                          style={{ width: "16px", height: "16px", top: -2 }}
                          className=" lightRedColor position-relative svg-force-size"
                        >
                          <SVGCIncompletedFilled />
                        </div>
                      </div>
                  ) : (
                      <div className=" greenColor d-flex gap-1 museo-sans-700">
                        <p>
                          <FormattedMessage id="first.steps.state.reached" />
                        </p>
                        <div
                          style={{ width: "16px", height: "16px", top: -2 }}
                          className=" position-relative svg-force-size"
                        >
                          <SVGCompletedFilled />
                        </div>
                      </div>
                  )}
                </>
              )}
            </div>
            <div className=" d-flex align-items-center justify-content-center">
              <div
                style={{ width: "35px", height: "35px" }}
                className="bg-white rounded-circle d-flex align-items-center dm-sans-bold s26 justify-content-center  "
              >
                <img
                  style={{ left: 1 }}
                  className=" position-relative"
                  src={plus}
                ></img>
              </div>
            </div>
            <div className="d-flex aling-items-center w-100 s14 justify-content-between">
              <div className=" ">
                <FormattedMessage id="first.steps.edb.prize" />
              </div>
              {data?.stepDetail?.currentEDB >=
              data?.stepDetail?.requiredEDBs ? (
                  <div className="  museo-sans-700 greenColor align-items-center d-flex gap-1">
                    <p>
                      <FormattedMessage id="first.steps.status.reached.two" />
                    </p>
                    <div
                      style={{ width: "16px", height: "16px", top: -6 }}
                      className=" position-relative svg-force-size"
                    >
                      <SVGCompletedFilled />
                    </div>
                  </div>
              ) : (
                <>
                  {data?.stepDetail?.state == "NOTEXIST" ? (
                      <div className=" museo-sans-700 lightRedColor align-items-center d-flex gap-1">
                        <p>
                          <FormattedMessage id="first.steps.status.not.reached" />
                        </p>
                        <div
                          style={{ width: "16px", height: "16px", top: -6 }}
                          className=" position-relative svg-force-size"
                        >
                          <SVGCIncompletedFilled />
                        </div>
                      </div>
                  ) : (
                      <div className=" museo-sans-700 goldBoldColor align-items-center d-flex gap-1">
                        <p>
                          <FormattedMessage id="first.steps.status.pending" />
                        </p>
                        <div
                          style={{ width: "16px", height: "16px", top: -6 }}
                          className=" position-relative svg-force-size"
                        >
                          <SVGPendingFilled />
                        </div>
                      </div>
                  )}
                </>
              )}
            </div>
          </div>
      )}

      <>
        {data?.stepDetail?.state != "FAILED" && (
          <>
            {index == 1 && (
              <FirstStepsHelpMessage
                index={1}
                data={data}
                redeemEndDate={redeemEndDate}
                outsider={true}
              />
            )}
            {index == 2 && (
              <FirstStepsHelpMessage
                index={2}
                data={data}
                redeemEndDate={redeemEndDate}
                outsider={true}
              />
            )}
            {index == 3 && (
              <FirstStepsHelpMessage
                index={3}
                data={data}
                extraData={extraData}
                redeemEndDate={redeemEndDate}
                outsider={true}
              />
            )}
          </>
        )}
      </>
    </div>
  );
}
