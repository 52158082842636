export default function ShareCheckEnvKey(keyType, forceCountry) {
  const country = localStorage.getItem("country");

  const envURLs = {
    ar: process.env[`REACT_APP_API_URL_${keyType}_AR`],
    pe: process.env[`REACT_APP_API_URL_${keyType}_PE`],
    cl: process.env[`REACT_APP_API_URL_${keyType}_CL`],
    cr: process.env[`REACT_APP_API_URL_${keyType}_CR`],
    co: process.env[`REACT_APP_API_URL_${keyType}_CO`],
    mx: process.env[`REACT_APP_API_URL_${keyType}_MX`],
    pa: process.env[`REACT_APP_API_URL_${keyType}_PA`],
    uy: process.env[`REACT_APP_API_URL_${keyType}_UY`],
  };

  return envURLs[forceCountry || country]?.includes("true") || false;
}
